import { Component, OnInit } from '@angular/core';
import { UserPromotionVoucherService } from '../../../shared/services/user-promotion-voucher.service';
import { ToastService } from '../../../shared/services/toast.service';
import { PromotionRegistrationService } from '../../../shared/services/promotion-registration.service';
import { UserPromotionVoucherStatus } from '../../../models/user-promotion-voucher';

@Component({
    selector: 'app-all-active-campaigns',
    templateUrl: './all-active-campaigns.component.html',
    styleUrls: ['./all-active-campaigns.component.scss'],
})
export class AllActiveCampaignsComponent implements OnInit {
    activeCampaignRegistrations = [];

    constructor(
        private promotionRegistrationService: PromotionRegistrationService,
        private toastService: ToastService
    ) {}

    async ngOnInit() {
        try {
            this.activeCampaignRegistrations = (
                await this.promotionRegistrationService.getActiveCampaignsOfUser()
            ).registrations;
        } catch (error) {
            this.toastService.showError('Error loading campaigns');
        }
    }
}
