import {Injectable} from '@angular/core';
import {ToastrService} from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  defaultMessage = 'Service unavailable. Please try again.';

  constructor(private toastrService: ToastrService) {
  }

  showInfo(info: string): void {
    this.toastrService.info(info, 'Info', {positionClass: 'toast-bottom-left'});
  }

  showSuccess(success: string): void {
    this.toastrService.success(success, 'Success', {positionClass: 'toast-bottom-left'});
  }

  showWarning(warning: string): void {
    this.toastrService.warning(warning, 'Warning', {positionClass: 'toast-bottom-left'});
  }

  showError(error: any): void {
    let errorMessage;
    if (error == null) { return; }
    if (typeof error === 'string') {
      errorMessage = error;
    }
    if (typeof error === 'object') {
      errorMessage = (error.error || {}).message || error.message || this.defaultMessage;
      if (error.statusText === 'Unknown Error' || error.status === 0) {
        errorMessage = this.defaultMessage;
      }
    }
    this.toastrService.error(errorMessage, 'Error', {positionClass: 'toast-bottom-left'});
  }

}
