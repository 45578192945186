import { Component, OnInit } from '@angular/core';
import { ToolbarOption } from '../../shared/components/toolbar/toolbar.component';
import { AuthService } from '../../shared/services/auth.service';
import { User } from '../../models/user';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
    selectedOption = ToolbarOption.discover;

    constructor(public authService: AuthService) {}

    ngOnInit(): void {}
}
