import { Component, Input, OnInit } from '@angular/core';
import { PrizeStatus, Prize } from '../../../models/promotion';

@Component({
    selector: 'app-prize-entry-list',
    templateUrl: './prize-entry-list.component.html',
    styleUrls: ['./prize-entry-list.component.scss'],
})
export class PrizeEntryListComponent implements OnInit {
    @Input() prize: Prize;
    @Input() currentPoints: number;
    PrizeStatus = PrizeStatus;

    ngOnInit() {
        if (this.currentPoints > this.prize.requiredPoints) {
            this.currentPoints = this.prize.requiredPoints;
        }
    }
}
