import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Feedback} from '../../models/feedback';

@Injectable({
    providedIn: 'root'
})
export class FeedbackService {
    consumerEndpoint: string;

    constructor(private http: HttpClient) {
        this.consumerEndpoint = `${environment.apiBaseUrlForConsumer}/feedbacks`;
    }

    async addOne(feedbackEntity: Feedback): Promise<Feedback> {
        const {feedback} = await this.http.post<{feedback: Feedback}>(`${this.consumerEndpoint}`, feedbackEntity).toPromise();
        return feedback;
    }

    async getFeedbacksByProductId(productId: string): Promise<Feedback[]> {
        const {feedbacks} = await this.http.get<{feedbacks: Feedback[]}>(`${this.consumerEndpoint}/${productId}`).toPromise();
        return feedbacks;
    }
}
