// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: true,
    pwaUrl: 'https://stage.preview.engage.link',
    apiBaseUrl: 'https://stage.api.engage.link',
    apiBaseUrlForConsumer: 'api/v1/consumer',
    apiBaseUrlForAdmin: 'admin',
    apiProductScanner: 'https://d1dez4tb3m8xb3.cloudfront.net/api/v1/consumer',
    productId: '5fdbc9d3e11b383af9222560',
    name: 'staging',
    promotionId: '603fdf9319aa9c31373cb40a',
    sentry: {
        dns: 'https://35dd2b0c744941a1911391aa7c01eb93@o492183.ingest.sentry.io/5671408',
        environment: 'staging',
        showDialog: false,
    },
    // production: true,
    // pwaUrl: 'http://engage-web-develop.s3-website-eu-west-1.amazonaws.com',
    // apiBaseUrl: 'http://engage-api-dev.eba-mmpkvgp8.eu-west-1.elasticbeanstalk.com',
    // apiBaseUrlForConsumer: 'api/v1/consumer',
    // apiBaseUrlForAdmin: 'admin',
    // apiProductScanner: 'https://d31uq7zd02w9ae.cloudfront.net/api/v1/consumer',
    // productId: '5fe2029d786e6200041d7288',
    // name: 'dev',
    // promotionId: '61165c761c6ea0102b774a64',
    // sentry: {
    //   dns: 'https://35dd2b0c744941a1911391aa7c01eb93@o492183.ingest.sentry.io/5671408',
    //   environment: 'dev',
    //   showDialog: false,
    // },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related alert-error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an alert-error is thrown.
 */
// import 'zone.js/dist/zone-alert-error';  // Included with Angular CLI.
