<section id="login-section" class="flex-column background-grey">
    <div class="container p-0 full-height background-grey">
        <div class="row container justify-content-center p-0 m-0 full-height">
            <div class="top-bar p-0 ">
                        <div class="row top-bar m-0 w-100 bg-white">
                            <div class="col-12 top-bar">
                                <div class="row my-3 position-relative">
                                    <div class="col-2 text-center">
                                        <img src="assets/images/preview/left-arrow-black.png" alt="Close icon"
                                             class="cursor-pointer" appBackButton/>
                                    </div>
                                    <div class="col-10 header-image text-center">
                                        <img src="assets/images/Logo.png" height="24" alt="Engage">
                                    </div>
                                </div>
                            </div>
                        </div>

                        <form class="p-3 form background-grey" #registerForm="ngForm"
                              (ngSubmit)="login()">
                            <div class="login-account with-topbar mt-3 mb-2">
                                Log in to your Engage™️ account
                            </div>
                            <div class="d-flex-column-between full-height">
                                <div>
                                    <div class="row mt-4 label">
                                        <div class="col-12">
                                            {{'common.emailAddress' | translate}}
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-12">
                                            <input class="input" [(ngModel)]="user.email" type="email" id="email" name="email" required
                                                   [pattern]="emailRegex" placeholder="Enter email" email #email="ngModel">
                                            <app-error *ngIf="!email.pristine && email.errors?.required">
                                                {{'common.errorMsg.emailRequired' | translate}}</app-error>
                                            <app-error
                                                    *ngIf="!email.pristine && (email.errors?.email || (email.errors && !email.errors?.required))">
                                                {{'register.errorMsg.emailInvalid' | translate}}</app-error>
                                        </div>
                                    </div>

                                    <div class="row mt-4 label">
                                        <div class="col-12">
                                            {{'common.password' | translate}}
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-12">
                                            <input class="input" [(ngModel)]="user.password" [type]="hidePassword ? 'password' : 'text'"
                                                   id="password" name="password" #password="ngModel"
                                                   [placeholder]="'register.placeholder.password' | translate" required minlength="6">
                                            <fa-icon class="cursor-pointer position-absolute password-icon"
                                                     [icon]="hidePassword ? faSlashEye : faEye" (click)="hidePassword = !hidePassword">
                                            </fa-icon>
                                            <app-error *ngIf="!password.pristine && password.errors?.required">
                                                {{'common.errorMsg.passwordRequired' | translate}}</app-error>
                                            <app-error *ngIf="!password.pristine && password.errors?.minlength">
                                                {{'register.errorMsg.passwordMinLength' | translate}}</app-error>
                                        </div>
                                    </div>

                                    <div class="row my-3">
                                        <div class="col-12">
                                            <app-alert-error [error]="error"></app-alert-error>
                                        </div>
                                    </div>
                                </div>
                                <div class="row m-0 w-100 footer">
                                    <div class="col-12">
                                        <div class="row justify-content-center">
                                            <button mat-flat-button class="w-100 pink-button mb-3 text-center" type="submit" [buttonSpinner]="isLoading"
                                                    [disabled]="registerForm.invalid || registerForm.pristine">
                                                Login</button>
                                            <span routerLink="/register" class="register text-center fw-bold mb-5 cursor-pointer">I don't have an account</span>
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </form>
            </div>
        </div>
    </div>
</section>
