import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {defaultLocale, Locale} from '../../models/locale';
import {ProductTemplate} from '../../models/product-template';
import {AdminProductTemplateService} from '../../shared/services/admin-product-template.service';

@Component({
    selector: 'app-preview-product-template',
    templateUrl: './preview-product-template.component.html',
    styleUrls: ['./preview-product-template.component.scss'],
})
export class PreviewProductTemplateComponent implements OnInit {
    id: string;
    productTemplate: ProductTemplate;
    error: any;
    isLoading = false;

    constructor(private adminProductTemplateService: AdminProductTemplateService, private route: ActivatedRoute,
                private translateService: TranslateService) {
    }

    ngOnInit(): void {
        this.id = this.route.snapshot.paramMap.get('productTemplateId');
        const {authToken, brandId} = this.route.snapshot.queryParams;
        const locale = this.route.snapshot.queryParams.locale as Locale || defaultLocale;
        this.translateService.use(locale);
        this.loadData(authToken, brandId, locale).then();
    }

    private async loadData(authToken: string, brandId: string, locale: string): Promise<void> {
        try {
            this.error = null;
            this.isLoading = true;
            this.productTemplate = await this.adminProductTemplateService.getProductTemplate(this.id, authToken, brandId, locale);
        } catch (e) {
            this.error = e;
            this.error.error.message = this.translateService.instant('errorMessages.expiredSession');
        } finally {
            this.isLoading = false;
        }
    }
}
