import { Component, Input } from '@angular/core';
import { Campaign } from '../../../models/campaign';
import { Promotion, PromotionCardType } from '../../../models/promotion';

@Component({
    selector: 'app-campaign-entry-list',
    templateUrl: './campaign-entry-list.component.html',
    styleUrls: ['./campaign-entry-list.component.scss'],
})
export class CampaignEntryListComponent {
    @Input() campaign: Promotion;
    PromotionCardType = PromotionCardType;
}
