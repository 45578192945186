export interface ImageObject {
    file: File;
    picture: any;
}

export function createDefaultImageObject(): ImageObject {
    return {file: null, picture: null};
}

export const MaxImageSizes = {
    review: 1200,
    feedback: 1200,
}

export interface Upload {
    _id: string;
    key: string;
    url: string;
    mimetype: string;
    scope: string;
}

export interface UploadResponse {
    url: string;
    key: string;
    upload: Upload;
    [key: string]: any;
}

export interface UploadData {
    upload: {
        url: string;
        fields: {
            'Content-Type': string;
            key: string;
            success_action_redirect: string;
            bucket: string;
            'X-Amz-Algorithm': string;
            'X-Amz-Credential': string;
            'X-Amz-Date': string;
            Policy: string;
            'X-Amz-Signature': string;
        };
    };
}

export enum UploadScope {
    feedbackImage = 'feedbackImage',
    reviewImage = 'reviewImage'
}

export interface ImageData {
    name: string;
    scope: UploadScope;
    owner: string;
    file: File;
}
