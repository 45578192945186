import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {catchError, mergeMap, take} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import * as Sentry from '@sentry/angular-ivy';
import {SessionService} from './session.service';

@Injectable({
    providedIn: 'root'
})
export class JwtInterceptor implements HttpInterceptor {
    constructor(private session: SessionService) {
    }

    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {

        return this.session.token$.pipe(
            take(1),
            mergeMap(token => {
                let headers = req.headers;
                if (token && req.url.indexOf('admin') < 0) {
                    headers = req.headers.append(
                        'Authorization',
                        `Bearer ${token}`
                    );
                }
                if (
                    !req.url.startsWith('/assets') &&
                    !req.url.startsWith('https://') &&
                    !req.url.startsWith('http://') || req.url.startsWith(environment.apiProductScanner)
                ) {
                    const url = req.url.startsWith(environment.apiProductScanner) ? req.url : `${environment.apiBaseUrl}/${req.url}`;
                    const apiReq = req.clone({
                        url,
                        headers
                    });
                    return next.handle(apiReq);
                }
                return next.handle(req);
            }),
            catchError(err => {
                const {status} = err;
                if (status === 401) {
                    this.session.clearLocalStorage().then();
                }

                if (status >= 500) {
                    Sentry.captureException(err.message);
                }
                throw err;
            })
        );
    }
}
