<section id="home-section" class="flex-column background-grey overflow-auto">
    <ng-container *ngIf="!authService.isLoggedIn(); else loggedIn">
        <app-home></app-home>
    </ng-container>
    <ng-template #loggedIn>
        <div class="col justify-content-center main-container container p-0 ">
            <div class="row justify-content-center">
                <div class="bg-white z-index-99 top-bar flex-column text-center">
                    <img class="left-arrow cursor-pointer" src="assets/icons/left-arrow.svg" appBackButton />
                    <span class="brand-name">{{ voucher?.brand?.name }}</span>
                </div>
            </div>
            <div class="row h-auto m-0 justify-content-center">
                <img class="voucher-background" src="assets/images/voucher/voucher-frame.png" />
                <img class="profile-brand" src="assets/images/profile.png" />
                <span class="title">{{ voucher?.title }}</span>
                <span class="description">{{ voucher?.description }}</span>
                <span class="qr" *ngIf="!voucher?.isUsed && !voucher?.isExpired">
                    <qrcode margin="2" [qrdata]="voucherUrl" [width]="175" [errorCorrectionLevel]="'M'"></qrcode>
                    <span class="code">{{ voucher?.qrCode }}</span>
                </span>
                <span class="error" *ngIf="voucher?.isExpired && !voucher.isUsed">
                    <span
                        ><h3>Ne pare rau,<br />acest voucher a expirat</h3></span
                    >
                </span>
                <span class="error" *ngIf="voucher?.isUsed">
                    <span
                        ><h3>
                            Voucherul a fost aplicat<br />
                            cu succes
                        </h3></span
                    >
                </span>
                <div class="row justify-content-center footer mb-4">
                    Valabil până la {{ voucher?.endDate | date : "dd-MM-YYYY" }}
                </div>
            </div>

        </div>
    </ng-template>
</section>
