import {Component, OnInit} from '@angular/core';
import {Promotion} from '../../models/promotion';
import {ProductService} from '../../shared/services/product.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Product} from '../../models/product';
import {LanguageService} from '../../shared/services/language.service';
import {PromotionService} from '../../shared/services/promotion.service';
import {SessionService} from '../../shared/services/session.service';
import {ToastService} from '../../shared/services/toast.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-promotion-list',
    templateUrl: './promotion-list.component.html',
    styleUrls: ['./promotion-list.component.scss']
})
export class PromotionListComponent implements OnInit {
    promotions: Promotion[] = [];
    product: Product;
    error: any;
    isLoading = false;

    constructor(private promotionService: PromotionService,
                private languageService: LanguageService,
                private sessionService: SessionService,
                private toastService: ToastService,
                private translateService: TranslateService,
                private router: Router,
                private route: ActivatedRoute) {
    }

    ngOnInit(): void {
        const {productId} = this.route.snapshot.params;
        if (productId) {
            this.loadPromotions(productId).then();
        }
    }

    async registerToPromotion(promotion: Promotion, index: number): Promise<void> {
        await this.processData(async () => {
            if (!this.sessionService.token) {
                await this.router.navigate(['/login'], {queryParams: {returnUrl: this.router.url}});
                return;
            }
            const scanDetails = this.sessionService.scanCodeDetails;
            if (!scanDetails) {
                this.toastService.showError(this.translateService.instant('promotions.pleaseScanTheCodeAgain'));
                return;
            }
            const promotionRegistration = await this.promotionService.registerPromotion(promotion._id, scanDetails);
            this.toastService.showSuccess(this.translateService.instant('promotions.successRegisterToCampaign'));
            if (promotionRegistration.prizeWon) {
                const message = promotionRegistration.promotion?.previewWinningMessage ||
                    this.translateService.instant('promotions.youWonAPrize', {prizeName: promotionRegistration.prizeWon.name});
                this.toastService.showSuccess(message);
            } else {
                const message = promotionRegistration.promotion?.previewPrizeMessage ||
                    this.translateService.instant('promotions.prizeMessage');
                this.toastService.showInfo(message);
            }
            this.promotions[index] = {...this.promotions[index], disableButton: true};
            this.sessionService.deleteScanProductCodeDetails();
        });
    }

    async loadPromotions(productId: string): Promise<void> {
        await this.processData(async () => {
            this.promotions = await this.promotionService.getPromotionsForProduct(productId);
        });
    }

    async processData(callback: () => Promise<any>): Promise<void> {
        try {
            this.isLoading = true;
            this.error = null;
            await callback();
        } catch (e) {
            this.error = e;
            switch (e?.error?.message) {
                case 'Fields scanLocationId should be unique':
                    this.error.error.message = this.translateService.instant('promotions.pleaseScanTheCodeAgain');
                    break;
                case 'Fields productCodeId should be unique':
                    this.error.error.message = this.translateService.instant('promotions.warningMultipleScan');
                    break;
            }
        } finally {
            this.isLoading = false;
        }
    }

}
