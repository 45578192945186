<div *ngIf="offline">You are offline</div>
<ng-container *ngIf="!authService.isLoggedIn(); else loggedIn">
    <div class=" p-0 overflow-hidden">
        <router-outlet></router-outlet>
    </div>
</ng-container>
<ng-template #loggedIn>
    <div class="col justify-content-center container p-0 overflow-hidden">
        <div class="row justify-content-center m-0 w-100">
            <ng-container *ngIf="showTopBar">
                <div class="top-bar bg-white p-2">
                    <ng-container *ngIf="currentUser?.profilePictureUrl; else noProfilePicture">
                        <img
                                class="profile-image ms-2 rounded-circle mt-1"
                                [src]="currentUser?.profilePictureUrl"
                                alt="Profile picture"
                        />
                    </ng-container>
                    <ng-template #noProfilePicture>
                        <div
                                class="rounded-circle background-purple profile-image mt-1 d-flex-center ms-2 cursor-pointer"
                                routerLink="/account"
                        >
                            <span>{{ authService.getInitials() }}</span>
                        </div>
                    </ng-template>
                </div>
            </ng-container>
            <router-outlet></router-outlet>
        </div>
    </div>
</ng-template>

<app-install-message-banner [show]="showInstallMessage" [applicationType]="applicationType">
</app-install-message-banner>
