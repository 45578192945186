import {Component, Input, OnInit} from '@angular/core';

export enum ToolbarOption {
  discover = 'discover',
  account = 'account',
  promotions = 'promotions'
}

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent implements OnInit {
  @Input() selectedOption: ToolbarOption;
  ToolbarOption = ToolbarOption;

  constructor() { }

  ngOnInit(): void {
  }

}
