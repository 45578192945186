import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';

@Component({
  selector: 'app-alert-error',
  templateUrl: './alert-error.component.html',
  styleUrls: ['./alert-error.component.scss']
})
export class AlertErrorComponent implements OnChanges {
  @Input() error: any;
  @Input() routeToHomepage = false;
  @Input() showAppLogo = false;
  errorMessage: string;

  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    this.handleError(changes.error.currentValue);
  }

  handleError(error: any): void {
    if (error == null) { return; }
    if (typeof error === 'string') {
      this.errorMessage = error;
    }
    if (typeof error === 'object') {
      this.errorMessage = (error.error || {}).message || error.message;
    }
  }

}
