import {Component, OnInit} from '@angular/core';
import {AuthService} from '../../../shared/services/auth.service';
import {faEye, faEyeSlash} from '@fortawesome/free-solid-svg-icons';
import {ActivatedRoute} from '@angular/router';

@Component({
    selector: 'app-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
    user = {newPassword: '', confirmPassword: ''};
    token: string;
    error: any = null;
    isLoading = false;
    hideNewPassword = true;
    hideConfirmPassword = true;
    faEye = faEye;
    faSlashEye = faEyeSlash;

    constructor(private authService: AuthService,
                private route: ActivatedRoute) {
    }

    ngOnInit(): void {
        this.token = this.route.snapshot.paramMap.get('token');
    }

    async resetPassword(): Promise<void> {
        try {
            this.error = null;
            this.isLoading = true;
            await this.authService.resetPassword(this.token, this.user.newPassword);
        } catch (e) {
            this.error = e;
        } finally {
            this.isLoading = false;
        }
    }

}
