import { Brand } from './brand';
import { Tag } from './tag';
import { Category } from './category';
import { Promotion } from './promotion';
import { LoyaltyPointsInformation } from './loyalty-points-info';

export enum CodeVisibility {
    visible = 'visible',
    hidden = 'hidden',
    visibleAndHidden = 'visibleAndHidden',
}

export enum ProductStatus {
    draft = 'draft',
    enabled = 'enabled',
    disabled = 'disabled',
}

export enum CodeType {
    hiddenCode = 'hidden code',
    visibleCode = 'visible code',
    retailCode = 'retail code',
}

export interface ProductResponse extends LoyaltyPointsInformation {
    product: Product;
    type: CodeType;
}

export interface CampaignProduct {
    _id?: string;
    productId: string;
    noPoints: number;

    product?: Product;
}

export interface Product {
    _id?: string;
    title: string;
    description: string;
    status: ProductStatus;
    internalPartNumber?: string;
    retailCode?: string;
    imageUrl?: string;
    imageCoverUrl?: string;
    tagIds?: string[];
    tags?: Tag[];
    promotionIds?: string[];
    promotions: Promotion[];
    brandId?: string;
    brand?: Brand;
    brandControllerInfoCard?: RichTextCard;
    categoryId?: string;
    category?: Category;
    productInfo?: ProductInfo;
    cards: Card[];
    image?: string;
    imageCover?: string;
    hasVisibleCode?: boolean;
    hasHiddenCode?: boolean;
    productButtonTitle?: string;
    productButtonUrl?: string;

    reviews: {
        count: number;
        totalStars: number;
        rating?: number;
    };
}

export interface ProductInfo {
    properties: { name: string; value?: string }[];
}

export enum CardType {
    multimedia = 'multimedia',
    carousel = 'carousel',
    singleImage = 'singleImage',
    richText = 'richText',
    feedback = 'feedback',
}

export const CardIcons = {
    people: 'assets/images/preview/icons/winemaker.png',
    products: 'assets/images/preview/icons/products.png',
    food: 'assets/images/preview/icons/asocieri culinare.png',
};

export interface Card {
    _id?: string;
    icon?: string;
    title?: string;
    cardType: CardType;

    multimedia?: MultimediaCard;
    carousel?: ImageCarouselCard;
    singleImage?: SingleImageCard;
    richText?: RichTextCard;
    feedback?: FeedbackCard;
}

export interface FeedbackCard {
    question: string;
    uploadImage: boolean;

    file?: File;
    picture?: any;
    imageUrl?: string;
    answer?: string;
    alreadyAnswered?: boolean;
}

export interface MultimediaCard {
    videoUrl?: string;
    video?: string;
    coverImage?: string; // extracted from video
    coverImageUrl?: string;
    playVideo?: boolean;
    title: string;
}

export class ImageCarousel {
    image?: string;
    imageUrl?: string;
    title: string;
    routing?: string;
}

export class SingleImageCard {
    imageUrl?: string;
    image?: string;
}

export class RichTextCard {
    text?: string;
}

export interface ImageCarouselCard {
    images: ImageCarousel[];
}
