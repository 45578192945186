<div
        class="voucher-wrapper clickable d-flex align-items-center justify-content-around"
        routerLink="/voucher/{{ voucher._id }}"
>
    <div class="voucher-image position-relative">
        <img class="" [src]="voucher.brand.logoUrl ?? 'assets/images/voucher/placeholder72x72.png'" alt="" />
        <ng-container *ngIf="voucher.isExpired || voucher.isUsed">
            <div class="voucher-check position-absolute">
                <img class="w-100"  src="assets/images/voucher/voucher-check.svg">
                <div class="image-shadow"></div>
            </div>
        </ng-container>
    </div>
    <div class="voucher-info d-flex flex-column">
        <div class="voucher-title">{{ voucher.brand.name }}</div>
        <div class="voucher-text">
            {{ voucher.title }}
        </div>
    </div>
    <div class="voucher-decor-round"></div>
</div>
