import { Component, Inject, OnInit } from '@angular/core';
import { ProductService } from '../../shared/services/product.service';
import { CodeType, ImageCarousel, Product, ProductResponse } from '../../models/product';
import { ActivatedRoute, Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { RedirectUrl } from '../../models/redirect-url';
import { TranslateService } from '@ngx-translate/core';
import { AppComponent } from '../../app.component';

@Component({
    selector: 'app-view-product',
    templateUrl: './view-product.component.html',
    styleUrls: ['./view-product.component.scss'],
})
export class ViewProductComponent implements OnInit {
    id: string;
    product: Product;
    similarProducts: ImageCarousel[];
    interestedInProducts: ImageCarousel[];
    error: any;
    isLoading = false;

    constructor(
        private productService: ProductService,
        private route: ActivatedRoute,
        private translateService: TranslateService,
        @Inject(DOCUMENT) private document: Document,
        private router: Router,
        private appComponent: AppComponent
    ) {
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.appComponent.showTopBar = false;
    }

    ngOnInit(): void {
        this.loadData().then();
    }

    private async loadData(): Promise<void> {
        try {
            this.error = null;
            this.isLoading = true;
            const productId = this.route.snapshot.paramMap.get('productId');
            const locale = this.route.snapshot.queryParamMap.get('locale');
            if (productId) {
                this.product = await this.productService.getOne(productId, locale);
            } else {
                const id = this.route.snapshot.queryParamMap.get('id');
                const a = this.route.snapshot.queryParamMap.get('a');
                if (id || a) {
                    const slashSplits = this.router.url.split('/');
                    const engageUrl = slashSplits[slashSplits.length - 1];
                    try {
                        const { redirectUrl, appRelativeUrl, product, type } =
                            await this.productService.scanVisibleOrHiddenCode(this.router.url);
                        if (redirectUrl) {
                            this.document.location.href = redirectUrl;
                        } else if ((product && type === CodeType.visibleCode) || product.promotions.length === 0) {
                            this.product = product;
                        } else {
                            await this.router.navigate([appRelativeUrl], { queryParams: { locale } });
                        }
                    } catch (e) {
                        const { url, queryParams } = this.productService.handleScanError(e, engageUrl);
                        if (url && queryParams) {
                            await this.router.navigate([url], { queryParams });
                        } else {
                            this.error = e;
                        }
                    }
                } else {
                    this.error = this.translateService.instant('errorMessages.brandOrProductNotFound');
                }
            }
            if (!this.product) {
                return;
            }
            this.similarProducts = await this.productService.getSimilarProducts(this.product);
            this.interestedInProducts = await this.productService.getInterestedInProducts(this.product);
        } catch (e) {
            this.error = e;
        } finally {
            this.isLoading = false;
        }
    }
}
