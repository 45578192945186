import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { PrizeStatus, Promotion, PromotionRegistration, PromotionStatus } from '../../models/promotion';
import { ScanCodeDetails } from './session.service';

@Injectable({
    providedIn: 'root',
})
export class PromotionService {
    endpoint: string;

    constructor(private http: HttpClient) {
        this.endpoint = `${environment.apiBaseUrlForConsumer}/promotions`;
    }

    async getOne(id: string): Promise<{ promotion; currentPoints }> {
        const { promotion, currentPoints } = await this.http
            .get<{ promotion: Promotion; currentPoints: number }>(`${this.endpoint}/${id}`)
            .toPromise();
        let inProgress = false;
        promotion.prizes.map((prize) => {
            if (prize.requiredPoints <= currentPoints) {
                prize.prizeStatus = PrizeStatus.completed;
            } else if (prize.requiredPoints > currentPoints && !inProgress) {
                prize.prizeStatus = PrizeStatus.inProgress;
                inProgress = true;
            } else {
                prize.prizeStatus = PrizeStatus.notStarted;
            }
        });
        return { promotion, currentPoints };
    }

    async getPromotionsForProduct(productId: string): Promise<Promotion[]> {
        const { promotions } = await this.http
            .get<{ promotions: Promotion[] }>(`${this.endpoint}`, {
                params: {
                    status: PromotionStatus.active,
                    productId,
                },
            })
            .toPromise();
        return promotions;
    }

    async registerPromotion(promotionId, scanDetails: ScanCodeDetails): Promise<PromotionRegistration> {
        const { promotionRegistration } = await this.http
            .post<{ promotionRegistration: PromotionRegistration }>(
                `${environment.apiBaseUrlForConsumer}/promotion-registration`,
                { promotionId, ...scanDetails }
            )
            .toPromise();
        return promotionRegistration;
    }

    async getAllCampaigns(): Promise<Promotion[]> {
        const { promotions } = await this.http
            .get<{ promotions: Promotion[] }>(`${this.endpoint}/campaigns`)
            .toPromise();
        return promotions;
    }
}
