import {Injectable} from '@angular/core';
import {Observable, ReplaySubject, Subject} from 'rxjs';
import {Router} from '@angular/router';
import storageAvailable from 'storage-available';

export interface ScanCodeDetails {
    scannedCode: string;
    brandAlias: string;
}

@Injectable({
    providedIn: 'root'
})
export class SessionService {
    private tokenSubject: Subject<string> = new ReplaySubject(1);
    private readonly localStorage = null;

    constructor(private router: Router) {
        this.localStorage = storageAvailable('localStorage') ? localStorage : null;
        this.getDataFromStorage();
    }

    get token(): string {
        return this.localStorage ? this.localStorage.getItem('authToken') : null;
    }

    set token(token: string) {
        this.tokenSubject.next(token || null);
    }

    get token$(): Observable<string> {
        return this.tokenSubject.asObservable();
    }

    get showInstallMessage(): boolean {
        return this.localStorage ? this.localStorage.getItem('showInstallMessage') : null;
    }

    set showInstallMessage(show: boolean) {
        if (this.localStorage) { this.localStorage.setItem('showInstallMessage', show); }
    }

    saveRegistrationEmailToStorage(email: string): void {
        this.localStorage?.setItem('registrationEmail', email);
    }

    get registrationEmail(): string {
        return this.localStorage?.getItem('registrationEmail') || null;
    }

    saveScanProductCodeDetails(scanCodeDetails: ScanCodeDetails): void {
        this.localStorage?.setItem('scan', JSON.stringify(scanCodeDetails));
    }

    saveScanBrandCodeDetails(scanCodeDetails: ScanCodeDetails): void {
        this.localStorage?.setItem('scan', JSON.stringify(scanCodeDetails));
    }

    deleteScanProductCodeDetails(): void {
        this.localStorage?.removeItem('scan');
    }

    get scanCodeDetails(): ScanCodeDetails {
        const stringObj = this.localStorage?.getItem('scan') || null;
        return stringObj ? JSON.parse(stringObj) : null;
    }

    getDataFromStorage(): void {
        const token = this.localStorage ? this.localStorage.getItem('authToken') : null;
        this.tokenSubject.next(token || null);
    }

    async clearLocalStorage(): Promise<void> {
        this.localStorage?.clear();
        this.tokenSubject.next(null);
        if (this.router.url.indexOf('preview') >= 0) {
            // admin product preview shouldn't be redirected to login if his session expires
            return;
        }
        await this.router.navigate(['/login']);
    }
}
