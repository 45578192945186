<section id="promotion-section" *ngIf="promotion">
    <div class="container p-0">
        <div class="row m-0 justify-content-center">
            <div class="col-12 col-md-5 col-lg-4 p-0 min-vh-100 background-grey">
                <div class="row m-0 mt-4 mb-3 header">
                    <div class="col-3 text-center">
                        <img class="cursor-pointer" src="assets/images/preview/left-arrow-black.png"
                             alt="Back arrow" appBackButton>
                    </div>
                    <div class="col-9 text-left">
                        {{'promotions.campaignInfo' | translate}}
                    </div>
                </div>
                <div class="row m-0">
                    <div class="col-12 p-0 d-flex-center position-absolute container-img">
                        <img class="w-100 h-100" [src]="promotion.imageCoverUrl" [alt]="promotion.title">
                    </div>
                    <div class="col-12 d-flex-center flex-column container-info">
                        <div class="row mb-3 d-flex-center w-100 product-img">
                            <div class="position-relative">
                                <img class="w-100 position-absolute m-auto" [src]="promotion.products[0]?.imageUrl"
                                     [alt]="promotion.products[0]?.title">
                            </div>
                        </div>
                        <div class="row mb-4 w-100 d-flex-center">
                            <div class="col-8 border-white"></div>
                        </div>
                        <div class="row mb-4 d-flex-center promotion-title">
                            <div class="col-12 text-center">
                                {{promotion.title | uppercase}}
                            </div>
                        </div>
                        <div class="row w-100 d-flex-center">
                            <div class="col-8 border-white"></div>
                        </div>
                    </div>
                </div>
                <div class="row m-0 mt-4">
                    <div class="col-12">
                        <div class="row justify-content-center">
                            {{'promotions.bigPrize' | translate | uppercase}}
                        </div>
                        <div class="row mt-3 mx-3">
                            <div class="col border-right">
                                <div class="row value d-flex-center">
                                    49
                                </div>
                                <div class="row label d-flex-center">
                                    {{'common.days' | translate |uppercase}}
                                </div>
                            </div>
                            <div class="col border-right">
                                <div class="row value d-flex-center">
                                    10
                                </div>
                                <div class="row label d-flex-center">
                                    {{'common.hours' | translate |uppercase}}
                                </div>
                            </div>
                            <div class="col">
                                <div class="row value d-flex-center">
                                    27
                                </div>
                                <div class="row label d-flex-center">
                                    {{'common.minutes' | translate |uppercase}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!--                <div class="row m-0 mt-4">-->
                <!--                    <div class="col-12">-->
                <!--                        <div class="row mb-2 header">-->
                <!--                            <div class="col-12">-->
                <!--                                {{'promotions.howCanYouParticipate' | translate}}-->
                <!--                            </div>-->
                <!--                        </div>-->
                <!--                        <div class="row pb-3 flex-nowrap m-0 justify-content-between card-carousel">-->
                <!--                            <div class="col card" *ngFor="let step of steps; index as stepIndex">-->
                <!--                                <div class="row mt-3 justify-content-between">-->
                <!--                                    <div class="mx-3 position-relative d-flex-center step-index">-->
                <!--                                        <span class="position-absolute">{{stepIndex + 1}}</span></div>-->
                <!--                                    <div class="col-5 d-flex-center">-->
                <!--                                        <img [src]="step.imageUrl" [alt]="step.text"></div>-->
                <!--                                </div>-->
                <!--                                <div class="row mt-2 mb-3 step-text">-->
                <!--                                    <div class="col-12">-->
                <!--                                        {{step.text | translate}}-->
                <!--                                    </div>-->
                <!--                                </div>-->
                <!--                            </div>-->
                <!--                        </div>-->
                <!--                    </div>-->
                <!--                </div>-->
                <div class="row m-0 mt-4 header">
                    <div class="col-12">
                        <a [href]="promotion.termsConditionsUrl">{{'promotions.campaignTermsAndConditions' | translate}}</a>
                    </div>
                </div>

                <div class="row m-0 my-4">
                    <div class="col-12">
                        <div class="row header">
                            <div class="col-12">
                                {{ 'promotions.prizes' | translate | uppercase}}
                            </div>
                        </div>
                        <div class="row mt-3 prize" *ngFor="let prize of promotion.prizes">
                            <div class="col-12">{{prize.name}}
                                - {{prize.quantity}} {{(prize.quantity === 1 ? 'promotions.prize' : 'promotions.prizes') | translate}}</div>
                        </div>
                    </div>
                </div>

                <!--                       ------------------- CARDS---------------->
                <div class="row m-0 my-3 card" *ngFor="let card of promotion.cards; index as cardIndex">
                    <div class="col-12">
                        <div class="row m-0 pt-3">
                            <div class="col-12 p-0 pb-3 d-flex align-items-center" *ngIf="card.title && card.title !== ''">
                                <span class="title text-overflow">{{card.title}}</span>
                            </div>
                        </div>

                        <div class="row m-0 pb-3 card-multimedia" *ngIf="card.cardType === PromotionCardType.multimedia">
                            <div class="col-12 p-0 position-relative">
                                <video [id]="'video' + cardIndex" class="w-100 d-block"
                                       [poster]="card.multimedia.coverImageUrl"
                                       preload="metadata" playsinline muted>
                                    <source [src]="card.multimedia.videoUrl" type="video/mp4">
                                </video>
                                <div [id]="'overlay' + cardIndex"
                                     class="video-overlay w-100 h-100 position-absolute"
                                     (click)="playPauseVideo(card, cardIndex)">
                                </div>
                                <img *ngIf="!card.multimedia.playVideo"
                                     src="assets/images/preview/Play%20button.png"
                                     class="position-absolute m-auto"
                                     alt="Video play button" (click)="playPauseVideo(card, cardIndex)">
                            </div>
                        </div>

                        <div class="row m-0 pb-3 card-single-image"
                             *ngIf="card.cardType === PromotionCardType.singleImage">
                            <div class="col-12 p-0">
                                <img [src]="card.singleImage.imageUrl" class="w-100 d-block">
                            </div>
                        </div>

                        <div class="row m-0 pb-3 card-rich-text" *ngIf="card.cardType === PromotionCardType.richText">
                            <quill-editor class="col-12 p-0" [(ngModel)]="card.richText.text"
                                          [readOnly]="true"
                                          [modules]="{toolbar:false}"></quill-editor>
                        </div>

                        <app-carousel *ngIf="card.cardType === PromotionCardType.carousel"
                                      [carouselImages]="card.carousel.images"></app-carousel>
                    </div>

                </div>

                <div class="row m-0 py-3 position-sticky justify-content-center promotions-btn">
                    <div class="col-12">
                        <button type="button" class="w-100 p-3 red-btn" (click)="participate()"
                                [disabled]="isAdmin || promotion.isConsumerRegistered || promotion.disableButton">
                            {{'promotions.participate' | translate}}</button>
                    </div>
                    <div class="col-12 text-center color-red" *ngIf="promotion.isConsumerRegistered && !isAdmin">
                        {{'promotions.alreadyRegisteredForThisCampaign' | translate}}
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<app-alert-error [error]="error"></app-alert-error>

<app-loading-spinner *ngIf="isLoading"></app-loading-spinner>
