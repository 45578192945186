import {Component, Input, OnInit} from '@angular/core';
import {Card, CardIcons, CardType, ImageCarousel, Product} from '../../models/product';
import {FeedbackService} from '../../shared/services/feedback.service';
import {Feedback} from '../../models/feedback';
import {ToastService} from '../../shared/services/toast.service';
import {TranslateService} from '@ngx-translate/core';
import {AuthService} from '../../shared/services/auth.service';
import {UploadService} from '../../shared/services/upload.service';
import {LanguageService} from '../../shared/services/language.service';
import {DomSanitizer} from '@angular/platform-browser';
import {NgxPicaService} from '@digitalascetic/ngx-pica';

@Component({
    selector: 'app-product',
    templateUrl: './product.component.html',
    styleUrls: ['./product.component.scss'],
})
export class ProductComponent implements OnInit {

    @Input() disableRouting = false;
    @Input() product: Product;
    @Input() similarProducts: ImageCarousel[];
    @Input() interestedInProducts: ImageCarousel[];
    @Input() isAdmin = false;
    isLoggedIn = false;
    CardType = CardType;
    CardIcons = CardIcons;
    feedbacks: Feedback[];

    constructor(private auth: AuthService,
                private feedbackService: FeedbackService,
                private uploadService: UploadService,
                private sanitizer: DomSanitizer,
                private ngxPicaService: NgxPicaService,
                private languageService: LanguageService,
                private translateService: TranslateService,
                private toastService: ToastService) {
    }

    ngOnInit(): void {
        this.isLoggedIn = this.auth.isLoggedIn();
        if (!this.isLoggedIn) {
            return;
        }
        this.processData(async () => {
            await this.loadFeedbacks();
        }).then();
    }

    private async loadFeedbacks(): Promise<void> {
        this.feedbacks = await this.feedbackService.getFeedbacksByProductId(this.product._id);
        this.product.cards.forEach((card) => {
            if (card.cardType === CardType.feedback) {
                const feedbackFound = this.feedbacks.find((feedbackCard) => feedbackCard.cardId === card._id);
                if (feedbackFound) {
                    card.feedback.imageUrl = feedbackFound.imageUrl;
                    card.feedback.answer = feedbackFound.answer;
                    card.feedback.alreadyAnswered = true;
                    card.feedback.question = feedbackFound.question;
                }
            }
        });
    }

    async processData(callback: () => Promise<any>): Promise<void> {
        try {
            await callback();
        } catch (e) {
            this.toastService.showError(e);
        }
    }

    async playPauseVideo(card: Card, cardIndex: number): Promise<void> {
        const video = document?.getElementById(`video${cardIndex}`) as HTMLVideoElement;
        if (!video) {
            return;
        }
        const videoOverlayDiv = document?.getElementById(`overlay${cardIndex}`) as HTMLDivElement;
        if (!videoOverlayDiv) {
            return;
        }
        card.multimedia.playVideo = !card.multimedia.playVideo;
        if (card.multimedia.playVideo) {
            videoOverlayDiv.style.opacity = '0.0';
            await video.play();
        } else {
            videoOverlayDiv.style.opacity = '0.5';
            video.pause();
        }
    }

    goToLink(url: string): void {
        window?.open(url, '_blank');
    }
}
