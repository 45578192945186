import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Promotion } from '../../models/promotion';
import { PromotionService } from '../../shared/services/promotion.service';

@Component({
    selector: 'app-view-promotion',
    templateUrl: './view-promotion.component.html',
    styleUrls: ['./view-promotion.component.scss'],
})
export class ViewPromotionComponent implements OnInit {
    id: string;
    promotion: Promotion = null;
    isLoading = false;
    error: any;

    constructor(private promotionService: PromotionService, private route: ActivatedRoute) {}

    ngOnInit(): void {
        this.loadPromotion().then();
    }

    async loadPromotion(): Promise<void> {
        await this.processData(async () => {
            const promotionId = this.route.snapshot.paramMap.get('promotionId');
            if (promotionId) {
                const { promotion } = await this.promotionService.getOne(promotionId);
                this.promotion = promotion;
            }
        });
    }

    async processData(callback: () => Promise<any>): Promise<void> {
        try {
            this.error = null;
            this.isLoading = true;
            await callback();
        } catch (e) {
            this.error = e;
        } finally {
            this.isLoading = false;
        }
    }
}
