import { Component, Input } from '@angular/core';
import { UserPromotionVoucher } from '../../../models/user-promotion-voucher';

@Component({
    selector: 'app-voucher',
    templateUrl: './voucher.component.html',
    styleUrls: ['./voucher.component.scss'],
})
export class VoucherComponent {
    @Input() voucher: UserPromotionVoucher;
}
