<div class="container toolbar">
    <div class="row m-0 h-100 d-flex-center">
        <div class="col-4 toolbar-item" (click)="selectedOption = ToolbarOption.discover" routerLink="/home">
            <div class="row justify-content-center">
                <img [src]="selectedOption === ToolbarOption.discover ? 'assets/images/core/discover-red.svg' : 'assets/images/core/discover.svg'"
                     alt="Discover">
            </div>
            <div class="row justify-content-center"
                 [ngClass]="selectedOption === ToolbarOption.discover ? 'color-red' : ''">
                {{'toolbar.discover' | translate}}
            </div>
        </div>
        <div class="col-4 toolbar-item" (click)="selectedOption = ToolbarOption.promotions">
            <div class="row justify-content-center">
                <img [src]="selectedOption === ToolbarOption.promotions ? 'assets/images/core/promotions-red.svg' : 'assets/images/core/promotions.svg'"
                     alt="Promotions">
            </div>
            <div class="row justify-content-center"
                 [ngClass]="selectedOption === ToolbarOption.promotions ? 'color-red' : ''">
                {{'toolbar.promotions' | translate}}
            </div>
        </div>
        <div class="col-4 toolbar-item" (click)="selectedOption = ToolbarOption.account" routerLink="/account">
            <div class="row justify-content-center">
                <img [src]="selectedOption === ToolbarOption.account ? 'assets/images/core/account-red.svg' : 'assets/images/core/account.svg'"
                     alt="Account">
            </div>
            <div class="row justify-content-center"
                 [ngClass]="selectedOption === ToolbarOption.account ? 'color-red' : ''">
                {{'toolbar.account' | translate}}
            </div>
        </div>
    </div>
</div>