import { CampaignProduct, Product } from './product';
import { Brand } from './brand';
import { Locale } from './locale';
import { User } from './user';

export enum PromotionStatus {
    draft = 'draft',
    active = 'active',
    finished = 'finished',
}

export enum PrizeType {
    voucher = 'voucher',
    periodic_wins = 'periodic_wins',
    continuous_wins = 'continuous_wins',
}

export enum PeriodicityUnit {
    minute = 'minute',
    hour = 'hour',
    day = 'day',
}

export enum FrequencyUnit {
    percent = 'percent', // 10% change for winning
    counter = 'counter', // every 10th user can win
}

export interface PrizeVoucher extends Prize {
    //    TODO
}

export interface PrizePeriodic extends Prize {
    // time_based_with_periodic_wins
    periodicity?: number; // x hour/day
    periodicityUnit?: PeriodicityUnit;
    periodicCap?: number; // max quantity per periodicityUnit
}

export interface PrizeContinuous extends Prize {
    // time_based_with_continuous_wins
    frequency?: number;
    frequencyUnit?: FrequencyUnit;
    consumerCounter?: number; // used for PrizeContinuous for frequencyUnit.counter
}

export enum PrizeStatus {
    completed = 'completed',
    inProgress = 'inProgress',
    notStarted = 'notStarted',
}

export interface Prize {
    _id?: string;
    name: string;
    quantity?: number;
    type?: PrizeType;
    noPrizesWon: number;
    prizeStatus?: PrizeStatus;

    requiredPoints?: number;
    image?: string;
    imageUrl?: string;
}

export enum PromotionCardType {
    multimedia = 'multimedia',
    carousel = 'carousel',
    singleImage = 'singleImage',
    richText = 'richText',
}

export interface PromotionCard {
    _id?: string;
    title?: string;
    cardType: PromotionCardType;

    multimedia?: MultimediaPromotionCard;
    carousel?: ImageCarouselPromotionCard;
    richText?: RichTextPromotionCard;
    singleImage?: SingleImagePromotionCard;
}

export interface MultimediaPromotionCard {
    video: string; //s3 key,
    videoUrl?: string;
    coverImage: string; // extracted from video
    coverImageUrl?: string;
    playVideo?: boolean;
    videoDescription?: string;
}

export interface ImageCarouselPromotionCardItem {
    image: string;
    imageUrl?: string;
    title: string;
}

export interface ImageCarouselPromotionCard {
    images: ImageCarouselPromotionCardItem[];
}

export interface SingleImagePromotionCard {
    image: string;
    imageUrl?: string;
}

export interface RichTextPromotionCard {
    text: string;
}

export interface PrizeWonDetails {
    _id?: string;
    name: string;
    type: PrizeType;
}

export enum PromotionType {
    promotion = 'promotion', // old version of promotion
    campaign = 'campaign', // new version
}

export interface Promotion {
    _id?: string;
    title: string;
    description?: string;
    imageCover?: string;
    imageCoverUrl?: string;
    termsConditionsUrl: string;
    startDate: Date;
    endDate?: Date;
    productIds?: string[];
    brandId: string;
    status: PromotionStatus;
    prizes: (PrizePeriodic | PrizeContinuous | PrizeVoucher)[];
    cards: PromotionCard[];

    type: PromotionType;
    isConsumerRegistered?: boolean; // used just for consumers endpoints to mark if the authenticated consumer is registered in the promotion

    campaignProducts?: CampaignProduct[];
    brand?: Brand;
    previewWinningMessage?: string;
    previewPrizeMessage?: string;
    disableButton?: boolean; // field used just for UI
    language?: Locale;
    instantPrizeWonMessage?: string;
    instantPrizeMessage?: string;
    products?: Product[];

    noRegisteredUsers?: number;
    noPrizesWon?: number;
    noTotalPrizes?: number;
    noRemainingPrizes?: number;
}

export interface PromotionRegistration {
    consumerId: string;
    promotionId: string;
    productCodeId: string; // the scanned code is always hidden
    prizeWon?: PrizeWonDetails;

    consumer?: User;
    promotion?: Promotion;
}

export interface Step {
    imageUrl: string;
    text: string;
}

export const defaultSteps: Step[] = [
    {
        imageUrl: 'assets/images/promotion/documents.png',
        text: 'buyTheProductYouScanned',
    },
    {
        imageUrl: 'assets/images/promotion/wines.svg',
        text: 'openBottleAndLookForTheSeal',
    },
    {
        imageUrl: 'assets/images/promotion/wines.svg',
        text: 'openBottleAndLookForTheSeal',
    },
];
