import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { PromotionRegistration } from '../../models/promotion';

@Injectable({
    providedIn: 'root',
})
export class PromotionRegistrationService {
    endpoint: string;

    constructor(private http: HttpClient) {
        this.endpoint = `${environment.apiBaseUrlForConsumer}/promotion-registration`;
    }

    async getActiveCampaignsOfUser(): Promise<{ registrations }> {
        const { registrations } = await this.http
            .get<{ registrations: PromotionRegistration[] }>(`${this.endpoint}`)
            .toPromise();
        return { registrations };
    }
}
