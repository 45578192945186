import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthService} from '../../../shared/services/auth.service';
import {emailRegex} from '../../../models/user';
import {ToastService} from '../../../shared/services/toast.service';
import {TranslateService} from '@ngx-translate/core';
import {SessionService} from '../../../shared/services/session.service';

@Component({
    selector: 'app-setup-account',
    templateUrl: './setup-account.component.html',
    styleUrls: ['./setup-account.component.scss']
})
export class SetupAccountComponent implements OnInit {
    token: string;
    error: any;
    email: string;
    emailRegex = emailRegex;
    isLoading = false;

    constructor(private route: ActivatedRoute,
                private authService: AuthService,
                private router: Router,
                private toastService: ToastService,
                private sessionService: SessionService,
                private translateService: TranslateService,
    ) {
    }

    ngOnInit(): void {
        this.token = this.route.snapshot.paramMap.get('token');
        this.email = this.sessionService.registrationEmail || undefined;
        if (this.token && this.token !== '') {
            this.confirmEmail().then();
        }
    }

    async confirmEmail(): Promise<void> {
        await this.processData(async () => {
            const user = await this.authService.confirmEmail(this.token);
            this.toastService.showSuccess(this.translateService.instant('setupAccount.emailConfirmed'));
            await this.router.navigateByUrl('/login');
        });
    }

    async resendConfirmationEmail(): Promise<void> {
        await this.processData(async () => {
            await this.authService.resendConfirmationEmail(this.email);
            this.toastService.showSuccess(this.translateService.instant('setupAccount.sendSuccessfullyPleaseCheckEmail'));
        });
    }
    
    async processData(callback: () => Promise<any>): Promise<void> {
        try {
            this.isLoading = true;
            this.error = null;
            await callback();
        } catch (e) {
            this.error = e;
            console.log(this.error);
        } finally {
            this.isLoading = false;
        }
    }
}
