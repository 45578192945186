/**
 * This Service handles how the date is represented in scripts i.e. ngModel.
 */
import {Injectable} from '@angular/core';
import {NgbDateAdapter, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';

@Injectable({providedIn: 'root'})
export class CustomAdapter extends NgbDateAdapter<Date> {

    isValidDate(value: any): value is Date {
        return value instanceof Date && !isNaN(value as any);
    }


    public fromModel(date: Date | null): NgbDateStruct | null {
        return this.isValidDate(date) ?
            { year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate() }
            : null;
    }

    public toModel(date: NgbDateStruct | null): Date | null {
        return date !== null && date.year !== null && date.month !== null
            ? new Date(Date.UTC(date.year, date.month - 1, date.day))
            : null;
    }
}
