import { Component, OnInit } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from './shared/services/language.service';
import { Locale } from './models/locale';
import { en } from '../assets/i18n/en';
import { ro } from '../assets/i18n/ro';
import { ApplicationType } from './shared/utils/utils';
import { InstalledAppList } from './models/installed-app-list';
import { AuthService } from './shared/services/auth.service';
import { User } from './models/user';

@UntilDestroy()
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
    title = 'engage-pwa';
    offline = true;
    applicationType: ApplicationType = ApplicationType.web;
    isInstalled = false;
    currentUser: User = null;
    showTopBar: boolean = true;

    constructor(
        private updates: SwUpdate,
        private translateService: TranslateService,
        private languageService: LanguageService,
        public authService: AuthService
    ) {}

    ngOnInit(): void {
        this.checkIfPWAIsInstalled().then();
        this.setVHProperty();
        this.translateService.setTranslation(Locale.EN, en);
        this.translateService.setTranslation(Locale.RO, ro);
        this.translateService.use(this.languageService.currentLanguage || Locale.EN);
        this.updates.available.pipe(untilDestroyed(this)).subscribe(async (event) => {
            await this.updates.activateUpdate();
            document?.location.reload();
        });
        this.onNetworkStatusChange();
        window?.addEventListener('online', () => this.onNetworkStatusChange());
        window?.addEventListener('offline', () => this.onNetworkStatusChange());
        this.currentUser = this.authService.user || null;
    }

    async checkIfPWAIsInstalled(): Promise<void> {
        if (navigator && (navigator as InstalledAppList)) {
            try {
                const installedApps = await (navigator as InstalledAppList)?.getInstalledRelatedApps();
                this.isInstalled = installedApps.length !== 0;
            } catch (e) {
                this.isInstalled = false;
            }
        } else {
            this.isInstalled = false;
        }
    }

    setVHProperty(): void {
        // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
        const vh = window.innerHeight * 0.01;
        // Then we set the value in the --vh custom property to the root of the document
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    }

    onNetworkStatusChange(): void {
        this.offline = !navigator?.onLine;
    }

    get isStandalone(): boolean {
        this.applicationType = 'standalone' in window?.navigator ? ApplicationType.ios : ApplicationType.android;
        const iOSIsInstalled = !!window?.navigator?.['standalone'] === true;

        // Check for iOS
        if (iOSIsInstalled) {
            this.applicationType = ApplicationType.ios;
            return true;
        }
        // For Android
        if (window.matchMedia('(display-mode: standalone)').matches) {
            this.applicationType = ApplicationType.android;
            return true;
        }
        return false;
    }

    get showInstallMessage(): boolean {
        return !this.isStandalone && !this.isInstalled;
    }
}
