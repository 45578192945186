import {Locale} from './locale';
import {Brand} from './brand';

export enum ProductTemplateType {
    codeNotFound = 'codeNotFound',
    codeRetired = 'codeRetired',
    multipleScans = 'multipleScans'
}

export interface ProductTemplate {
    _id?: string;
    title: string;
    type: ProductTemplateType;
    description?: string;
    buttonTitle?: string;
    buttonUrl?: string;
    locale: Locale;
    brandId: string;
    imageCover: string;
    imageCoverUrl?: string;
    cards: ProductTemplateCard[];
    brand?: Brand;
}

export interface ProductTemplateCard {
    _id?: string;
    title: string;
    cardType: ProductTemplateCardType;

    multimedia?: MultimediaProductTemplateCard;
    carousel?: ImageCarouselProductTemplateCard;
    richText?: RichTextProductTemplateCard;
    singleImage?: SingleImageProductTemplateCard;
}

export enum ProductTemplateCardType {
    multimedia = 'multimedia',
    carousel = 'carousel',
    singleImage = 'singleImage',
    richText = 'richText',
}

export interface MultimediaProductTemplateCard {
    video: string; //s3 key,
    videoUrl?: string;
    coverImage: string; // extracted from video
    coverImageUrl?: string;
    playVideo?: boolean;
}

export interface ImageCarouselProductTemplateCardItem {
    image: string;
    imageUrl?: string;
    title: string;
}

export interface ImageCarouselProductTemplateCard {
    images: ImageCarouselProductTemplateCardItem[];
}

export interface SingleImageProductTemplateCard {
    image: string;
    imageUrl?: string;
}

export interface RichTextProductTemplateCard {
    text: string;
}