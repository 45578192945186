<section id="forgot-pass-section" class="auth-section background-grey">
    <div class="container p-0">
        <div class="row m-0 justify-content-center">
            <form class="col-12 col-md-5 col-lg-4 h-100 position-relative overflow-auto"
                  #forgotPasswordForm="ngForm"
                  (ngSubmit)="forgotPassword()">
                <div class="row mt-4 mb-3 header">
                    <div class="col-3 text-left position-absolute z-index-1">
                        <img class="cursor-pointer" src="assets/images/core/close_black.png"
                             alt="Close" appBackButton>
                    </div>
                    <div class="col-12 text-center">
                        {{'forgotPassword.title' | translate}}
                    </div>
                </div>
                <div class="row mt-4 justify-content-center label">
                    <div class="col-11 text-center">
                        {{'forgotPassword.headlineMessage' | translate}}
                    </div>
                </div>

                <div class="row mt-4 label">
                    <div class="col-12">
                        {{'common.emailAddress' | translate}}
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <input class="input" [(ngModel)]="email" type="email" id="email" name="email" required
                               email #userEmail="ngModel" [pattern]="emailRegex">
                        <app-error *ngIf="!userEmail.pristine && userEmail.errors">
                            {{'common.errorMsg.emailRequired' | translate}}</app-error>
                    </div>
                </div>

                <div class="row my-3">
                    <div class="col-12">
                        <app-alert-error [error]="error"></app-alert-error>
                    </div>
                </div>

                <div class="row m-0 my-3 w-100 footer">
                    <div class="col-12">
                        <div class="row justify-content-center">
                            <div class="col-12">
                                <button class="w-100 red-btn text-center" type="submit" [buttonSpinner]="isLoading"
                                        [disabled]="forgotPasswordForm.invalid || forgotPasswordForm.pristine">
                                    {{'forgotPassword.sendEmail' | translate}}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</section>
