import {Component, OnInit} from '@angular/core';
import {emailRegex, User} from '../../../models/user';
import {AuthService} from '../../../shared/services/auth.service';
import {ActivatedRoute, Router} from '@angular/router';
import {faEye, faEyeSlash} from '@fortawesome/free-solid-svg-icons';
import {SessionService} from '../../../shared/services/session.service';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
    user: User = {
        password: '',
        email: ''
    };
    error: any;
    isLoading = false;
    emailRegex = emailRegex;
    hidePassword = true;
    faEye = faEye;
    faSlashEye = faEyeSlash;

    constructor(private authService: AuthService,
                private sessionService: SessionService,
                private route: ActivatedRoute,
                private router: Router) {
    }

    ngOnInit(): void {
    }

    async login(): Promise<void> {
        await this.processData(async () => {
            await this.authService.login(this.user.email, this.user.password);
            const {returnUrl} = this.route.snapshot.queryParams;
            await this.router.navigate([returnUrl ?? '/home' ]);
        });
    }

    async processData(callback: () => Promise<any>): Promise<void> {
        try {
            this.isLoading = true;
            this.error = null;
            await callback();
        } catch (e) {
            this.error = e;
            console.log(this.error);
        } finally {
            this.isLoading = false;
        }
    }

}
