<section id="home-section" class="flex-column background-grey justify-content-center row">
    <div class="col justify-content-center main-container container p-0 ">
        <div class="row justify-content-center">
            <div class="bg-white z-index-99 top-bar flex-column text-center">
                <img class="left-arrow cursor-pointer" src="assets/icons/left-arrow.svg" appBackButton />
                <span class="brand-name">{{ voucher?.brand?.name }}</span>
            </div>
        </div>
        <div class="row h-auto m-0 justify-content-center">
            <img class="voucher-background" src="assets/images/voucher/voucher-frame.png" />
            <img class="profile-brand" src="assets/images/profile.png" />
            <span class="title">{{ voucher?.title }}</span>
            <span class="description">{{ voucher?.description }}</span>
            <button (click)="applyVoucher()" class="pink-button mt-3 position-absolute">Aplica Voucher</button>
            <div class="row justify-content-center footer mb-4">
                Valabil până la {{ voucher?.endDate | date : "dd-MM-YYYY" }}
            </div>
        </div>

    </div>
</section>
