import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import {Observable} from 'rxjs';
import {AuthService} from '../services/auth.service';
import {map, take, tap} from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class AuthGuard  {
    constructor(private auth: AuthService, private router: Router) {}

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ):
        | Observable<boolean | UrlTree>
        | Promise<boolean | UrlTree>
        | boolean
        | UrlTree {
        return this.auth.token$.pipe(
            take(1),
            map((token) => !!token),
            tap((authenticated) => {
                if (!authenticated) {
                    console.error('Access denied - not authenticated.');
                    this.router.navigate(['/login'], {
                        queryParams: { returnUrl: state.url },
                    });
                }
            })
        );
    }
}
