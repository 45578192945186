<section id="discover-page" class="background-grey pb-4">
    <div class="col justify-content-center container with-topbar">
        <div class="row justify-content-center max-width500 m-auto p-0">
            <div class="row justify-content-center p-0">
                <div class="section-title d-flex justify-content-between ps-0">
                    {{ "vouchers.activeVouchers" | translate }} ({{ activeVouchers.length }})
                    <span class="link clickable" routerLink="/all-vouchers">{{ "discover.seeAll" | translate }}</span>
                </div>
                <div class="row justify-content-center p-0">
                    <div class="swipe row d-flex flex-nowrap p-0" *ngIf="activeVouchers.length; else noVouchers">
                        <ng-container *ngFor="let voucher of activeVouchersCarousel">
                            <app-voucher [voucher]="voucher"></app-voucher>
                        </ng-container>
                    </div>
                    <ng-template #noVouchers class="mt-2">
                        Momentan nu ai vouchere active. Participă în campanii pentru a câștiga vouchere”.
                    </ng-template>
                </div>
            </div>
        </div>

        <div class="row justify-content-center max-width500 m-auto ">
            <div class="row justify-content-center p-0">
                <div class="section-title d-flex justify-content-between ps-0">
                    {{ "campaigns.activeCampaigns" | translate }} ({{activeCampaignRegistrations.length}})
                    <span class="link clickable" routerLink="/all-active-campaigns">{{ "discover.seeAll" | translate }}</span>
                </div>
            </div>
            <div class="row justify-content-center p-0">
                <ng-container *ngIf="activeCampaignRegistrations.length > 0">
                    <app-active-campaign class="clickable ps-0" routerLink="/campaignPage/{{activeCampaignRegistrations[0].promotion._id}}" [campaign]="activeCampaignRegistrations[0]?.promotion"></app-active-campaign>
                </ng-container>
                <ng-container *ngIf="activeCampaignRegistrations.length > 1">
                    <app-active-campaign class="clickable ps-0" routerLink="/campaignPage/{{activeCampaignRegistrations[1].promotion._id}}" [campaign]="activeCampaignRegistrations[1]?.promotion"></app-active-campaign>
                </ng-container>
            </div>
        </div>

        <div class="row justify-content-center max-width500 m-auto ">
            <div class="row justify-content-center p-0">
                <div class="section-title d-flex justify-content-between ps-0">
                    {{ "campaigns.allCampaigns" | translate }} ({{allCampaigns.length}})
                    <span class="link clickable" routerLink="/all-campaigns">{{ "discover.seeAll" | translate }}</span>
                </div>
            </div>
            <div class="row justify-content-center p-0">
                <ng-container *ngIf="allCampaigns.length > 0">
                    <app-campaign class="clickable ps-0" routerLink="/campaignPage/{{allCampaigns[0]?._id}}" [campaign]="allCampaigns[0]"></app-campaign>
                </ng-container>
                <ng-container *ngIf="allCampaigns.length > 1">
                    <app-campaign class="clickable ps-0" routerLink="/campaignPage/{{allCampaigns[1]?._id}}" [campaign]="allCampaigns[1]"></app-campaign>
                </ng-container>
                <ng-container *ngIf="allCampaigns.length > 2">
                    <app-campaign class="clickable ps-0" routerLink="/campaignPage/{{allCampaigns[2]?._id}}" [campaign]="allCampaigns[2]"></app-campaign>
                </ng-container>
            </div>
        </div>

    </div>
</section>
