import {Component, OnInit} from '@angular/core';
import {ProductTemplate} from '../../../models/product-template';
import {ProductTemplateService} from '../../services/product-template.service';
import {ActivatedRoute} from '@angular/router';
import {ToastService} from '../../services/toast.service';

@Component({
  selector: 'app-invalid-code-page',
  templateUrl: './invalid-code-page.component.html',
  styleUrls: ['./invalid-code-page.component.scss']
})
export class InvalidCodePageComponent implements OnInit {
  productTemplate: ProductTemplate = null;
  error: any = null;

  constructor(private productTemplateService: ProductTemplateService,
              private toast: ToastService,
              private route: ActivatedRoute) { }

  async ngOnInit(): Promise<void> {
    const alias = this.route.snapshot.paramMap.get('brandAlias');
    const {type} = this.route.snapshot.queryParams;
    if (alias && type) {
      try {
        this.productTemplate = await this.productTemplateService.getOne(alias, type);
      } catch (e) {
        this.toast.showError(e);
      }
    } else {

    }
  }

}
