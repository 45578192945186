import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../shared/services/auth.service';
import { ToastService } from '../../../shared/services/toast.service';
import { TranslateService } from '@ngx-translate/core';
import { faEyeSlash, faEye } from '@fortawesome/free-solid-svg-icons';
import { Router } from '@angular/router';

export interface ChangePassword {
    oldPassword: string;
    newPassword: string;
}

@Component({
    selector: 'app-change-password',
    templateUrl: './change-password.component.html',
    styleUrls: ['./change-password.component.scss'],
})
export class ChangePasswordComponent implements OnInit {
    error: any = null;
    user: ChangePassword = { oldPassword: null, newPassword: null };
    hideOldPassword = true;
    hideNewPassword = true;
    isLoading = false;
    faEye = faEye;
    faSlashEye = faEyeSlash;
    step = 1;

    constructor(
        private authService: AuthService,
        private toastService: ToastService,
        private translateService: TranslateService,
        private router: Router
    ) {}

    ngOnInit(): void {}

    async changePassword(): Promise<void> {
        try {
            const passwordSet = await this.authService.changePassword({
                oldPassword: this.user.oldPassword,
                newPassword: this.user.newPassword,
            });
            if (passwordSet) {
                this.toastService.showSuccess(
                    this.translateService.instant('account.toastMsg.passwordChangedSuccessfully')
                );
            }
            await this.router.navigate(['account/edit']);
        } catch (e) {
            this.error = e;
        }
    }

    continue(): void {
        this.step = 2;
    }
}
