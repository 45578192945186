import { Component, OnInit } from '@angular/core';
import { Brand } from '../../models/brand';
import { PromotionService } from '../../shared/services/promotion.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BrandService } from '../../shared/services/brand.service';
import { ToastService } from '../../shared/services/toast.service';
import { SessionService } from '../../shared/services/session.service';
import { CampaignService } from '../../shared/services/campaign.service';

@Component({
    selector: 'app-brand-page',
    templateUrl: './brand-page.component.html',
    styleUrls: ['./brand-page.component.scss'],
})
export class BrandPageComponent implements OnInit {
    brand: Brand;
    error: any;
    isLoading = false;
    participated = false;
    hiddenCode: string;

    constructor(
        private promotionService: PromotionService,
        private brandService: BrandService,
        private router: Router,
        private route: ActivatedRoute,
        private translateService: TranslateService,
        private toastService: ToastService,
        private sessionService: SessionService,
        private campaignService: CampaignService
    ) {}

    async ngOnInit(): Promise<void> {
        this.loadData().then();
    }

    private async loadData(): Promise<void> {
        try {
            this.error = null;
            this.isLoading = true;
            const brandId = this.route.snapshot.paramMap.get('brandId');
            if (brandId) {
                this.brand = await this.brandService.getOne(brandId);
            } else {
                const id = this.route.snapshot.queryParamMap.get('id');
                const a = this.route.snapshot.queryParamMap.get('a');

                if (id || a) {
                    try {
                        const { brand } = await this.brandService.scanVisibleOrHiddenCode(this.router.url);
                        this.brand = brand;
                        if (a) {
                            this.hiddenCode = a;
                        }
                    } catch (e) {
                        this.error = e;
                    }
                }
            }
            if (!this.brand) {
                return;
            }
        } catch (e) {
            this.error = e;
        } finally {
            this.isLoading = false;
        }
    }

    async processData(callback: () => Promise<any>): Promise<void> {
        try {
            this.error = null;
            this.isLoading = true;
            await callback();
        } catch (e) {
            this.error = e;
            console.log(e);
            this.toastService.showError(e);
        } finally {
            this.isLoading = false;
        }
    }

    async participateAll() {
        await this.processData(async () => {
            if (!this.sessionService.token) {
                await this.router.navigate(['/login'], { queryParams: { returnUrl: this.router.url } });
                return;
            }

            if (this.participated) {
                this.toastService.showError(this.translateService.instant('promotions.warningMultipleScan'));
                return;
            }

            let brandId = this.route.snapshot.paramMap.get('brandId');
            if (!brandId) {
                brandId = this.brand.promotions[0].brandId;
            }

            await this.campaignService.registerToAllCampaignsOfBrand(this.brand.alias, this.hiddenCode);
            this.participated = true;
            this.toastService.showSuccess(this.translateService.instant('promotions.successRegisterToAllCampaigns'));
            // TODO redirect to success page instead of campaigns
        });
    }
}
