<section class="full-height background-grey">
    <div class="col justify-content-center main-container container p-0 ">
        <div class="row justify-content-center">
            <div class="bg-white z-index-99 top-bar flex-column text-center">
                <img class="left-arrow cursor-pointer" src="assets/icons/left-arrow.svg" appBackButton />
                <span class="title">{{"campaigns.allCampaigns" | translate}}</span>
            </div>
        </div>
        <div class="row justify-content-center ps-0 max-width500 m-auto with-topbar">
            <ng-container *ngFor="let campaign of allCampaigns">
                <app-campaign class="clickable ps-0" routerLink="/campaignPage/{{campaign._id}}" [campaign]="campaign"></app-campaign>
            </ng-container>
        </div>
    </div>
</section>
