<ng-template #t let-fill="fill">
  <span class="star" [class.full]="fill === 100">
      <span class="half" [style.width.%]="fill">
        <img src="assets/images/preview/star-rating/full.png" alt="Star">
      </span>
      <img src="assets/images/preview/star-rating/empty.png" alt="Star">
  </span>
</ng-template>

<ngb-rating [(rate)]="rating" [starTemplate]="t" [readonly]="true" [max]="5"></ngb-rating>
