import {Component, Input, OnInit} from '@angular/core';
import {SessionService} from '../../services/session.service';
import {ApplicationType} from '../../utils/utils';

@Component({
    selector: 'app-install-message-banner',
    templateUrl: './install-message-banner.component.html',
    styleUrls: ['./install-message-banner.component.scss']
})
export class InstallMessageBannerComponent implements OnInit {
    @Input() show: boolean;
    @Input() applicationType: ApplicationType = ApplicationType.web;
    ApplicationType = ApplicationType;
    deferredPrompt: any;

    constructor(private sessionService: SessionService) {
    }

    ngOnInit(): void {
        this.show = this.show && this.sessionService.showInstallMessage === null;
        window?.addEventListener('beforeinstallprompt', (e) => {
            e.preventDefault();
            this.deferredPrompt = e;
            this.show = true;
        });
        window?.addEventListener('appinstalled', () => {
            this.hideInstallationPrompt();
            this.deferredPrompt = null;
        });
    }


    async addToHomeClicked(): Promise<void> {
        this.hideInstallationPrompt();
        if (!this.deferredPrompt) {
            return;
        }
        this.deferredPrompt.prompt();
        await this.deferredPrompt.userChoice;
        this.deferredPrompt = null;
    }

    hideInstallationPrompt(): void {
        this.show = false;
        this.sessionService.showInstallMessage = false;
    }
}
