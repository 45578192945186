export const emailRegex =
    '^(([^<>()\\[\\]\\\\.,;:\\s@ ]+(\\.[^<>()\\[\\]\\\\.,;:\\s@]+)*)|(.+))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$';
export const phoneRegex = '^(\\+\\d{1,2}\\s?)?1?\\-?\\.?\\s?\\(?\\d{3}\\)?[\\s.-]?\\d{3}[\\s.-]?\\d{4}$';

export enum UserRole {
    consumer = 'consumer',
    brandController = 'brandController',
}

export interface User {
    email: string;
    password: string;
    firstName?: string;
    lastName?: string;
    phone?: string;
    birthday?: Date | string;
    profilePicture?: string;
    profilePictureUrl?: string;
    emailConfirmation?: {
        confirmed: boolean;
    };
    role?: UserRole;
}
