import {Component, Input, OnInit} from '@angular/core';
import {Card, CardType} from '../../../models/product';
import {DomSanitizer} from '@angular/platform-browser';
import {Feedback} from '../../../models/feedback';
import {MaxImageSizes, UploadScope} from '../../../models/upload';
import {Locale} from '../../../models/locale';
import {ToastService} from '../../services/toast.service';
import {UploadService} from '../../services/upload.service';
import {TranslateService} from '@ngx-translate/core';
import {FeedbackService} from '../../services/feedback.service';
import {LanguageService} from '../../services/language.service';
import {NgxPicaService} from '@digitalascetic/ngx-pica';
import {AuthService} from '../../services/auth.service';
import {Router} from '@angular/router';

@Component({
    selector: 'app-feedback-card',
    templateUrl: './feedback-card.component.html',
    styleUrls: ['./feedback-card.component.scss']
})
export class FeedbackCardComponent implements OnInit {
    @Input() card: Card;
    @Input() productId: string;
    @Input() isAdmin = false;
    isLoggedIn = false;
    isLoading = false;
    CardType = CardType;
    locale: Locale = this.languageService.currentLanguage as Locale;

    constructor(public sanitizer: DomSanitizer,
                public router: Router,
                private uploadService: UploadService,
                private auth: AuthService,
                private feedbackService: FeedbackService,
                private translateService: TranslateService,
                private languageService: LanguageService,
                private ngxPicaService: NgxPicaService,
                private toastService: ToastService) {
    }

    ngOnInit(): void {
        this.isLoggedIn = this.auth.isLoggedIn();
    }

    uploadFromFile(event: any): void {
        this.card.feedback.file = event.target.files[0];
        const reader = new FileReader();
        reader.onload = e => {
            this.card.feedback.picture = reader.result;
        };
        reader.readAsDataURL(this.card.feedback.file);
    }

    async submitAnswer(card: Card): Promise<void> {
        this.isLoading = true;
        const feedback: Feedback = {
            productId: this.productId,
            answer: card.feedback.answer,
            cardId: card._id,
            question: card.feedback.question
        };
        await this.processData(async () => {
            const feedbackAdded = await this.feedbackService.addOne(feedback);
            if (card.feedback.file) {
                const file = await this.rescaleImage(card.feedback.file);
                const imageData = {
                    name: 'image',
                    scope: UploadScope.feedbackImage,
                    owner: feedbackAdded._id,
                    file
                };
                card.feedback.imageUrl = (await this.uploadService.uploadFile(imageData, this.locale)).url;
            }
            this.toastService.showSuccess(this.translateService.instant('product.answerSubmitted'));
            card.feedback.alreadyAnswered = true;
            card.feedback.question = feedback.question;
            this.isLoading = false;
        });
    }

    private async rescaleImage(file: File): Promise<File> {
        return await this.ngxPicaService.resizeImages([file], MaxImageSizes.feedback, MaxImageSizes.feedback, {
            exifOptions: {forceExifOrientation: true},
            aspectRatio: {keepAspectRatio: true},
            alpha: true
        }).toPromise();
    }

    async processData(callback: () => Promise<any>): Promise<void> {
        try {
            await callback();
        } catch (e) {
            this.toastService.showError(e);
        }
    }
}
