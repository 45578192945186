import {Component, Input, OnInit} from '@angular/core';
import {ProductTemplate, ProductTemplateCard, ProductTemplateCardType} from '../../../models/product-template';

@Component({
  selector: 'app-product-template',
  templateUrl: './product-template.component.html',
  styleUrls: ['./product-template.component.scss']
})
export class ProductTemplateComponent implements OnInit {
  @Input() productTemplate: ProductTemplate;
  ProductTemplateCardType = ProductTemplateCardType;

  constructor() { }

  ngOnInit(): void {
  }

  async playPauseVideo(card: ProductTemplateCard, cardIndex: number): Promise<void> {
    const video = document?.getElementById(`video${cardIndex}`) as HTMLVideoElement;
    if (!video) {
      return;
    }
    const videoOverlayDiv = document?.getElementById(`overlay${cardIndex}`) as HTMLDivElement;
    if (!videoOverlayDiv) {
      return;
    }
    card.multimedia.playVideo = !card.multimedia.playVideo;
    if (card.multimedia.playVideo) {
      videoOverlayDiv.style.opacity = '0.0';
      await video.play();
    } else {
      videoOverlayDiv.style.opacity = '0.5';
      video.pause();
    }
  }

  goToLink(url: string): void {
    window?.open(url, '_blank');
  }

}
