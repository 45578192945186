import { Component, Input, OnInit } from '@angular/core';
import { User } from '../../../models/user';
import { UserPromotionVoucher } from '../../../models/user-promotion-voucher';
import { AuthService } from '../../../shared/services/auth.service';
import { UserPromotionVoucherService } from '../../../shared/services/user-promotion-voucher.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { PromotionService } from '../../../shared/services/promotion.service';

@Component({
    selector: 'app-scan-voucher',
    templateUrl: './scan-voucher.component.html',
    styleUrls: ['./scan-voucher.component.scss'],
})
export class ScanVoucherComponent implements OnInit {
    voucherId: string;
    voucher: UserPromotionVoucher = null;
    error: any;
    isLoading = false;

    constructor(
        private userPromotionVoucherService: UserPromotionVoucherService,
        private route: ActivatedRoute,
        private router: Router
    ) {}

    async ngOnInit(): Promise<void> {
        await this.loadVoucherData();
    }

    private async loadVoucherData(): Promise<void> {
        try {
            this.isLoading = true;
            this.voucherId = this.route.snapshot.paramMap.get('voucherId');
            const result = await this.userPromotionVoucherService.getUserPromotionVoucherById(this.voucherId);
            this.voucher = result.userPromotionVoucher;
        } catch (e) {
            this.error = e;
        } finally {
            this.isLoading = false;
        }
    }

    async applyVoucher() {
        localStorage.setItem('brandName', this.voucher.brand.name);
        if (!this.voucher.isUsed && !this.voucher.isExpired) {
            await this.userPromotionVoucherService.updateOne(this.voucherId, { isUsed: true });
            this.router.navigateByUrl(`v/success`);
        } else this.router.navigateByUrl(`v/error`);
    }
}
