import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {ProductTemplate, ProductTemplateType} from '../../models/product-template';

@Injectable({
    providedIn: 'root'
})
export class ProductTemplateService {
    endpoint: string;


    constructor(private http: HttpClient) {
        this.endpoint = `${environment.apiBaseUrlForConsumer}/product-templates`;
    }

    async getOne(brandAlias: string, type: ProductTemplateType): Promise<ProductTemplate> {
        const {productTemplate} = await this.http.get<{ productTemplate: ProductTemplate }>
        (`${this.endpoint}/${brandAlias}`, {params: {type}}).toPromise();
        return productTemplate;
    }
}
