<section id="product-template-section" *ngIf="productTemplate">
    <div class="container p-0">
        <div class="row justify-content-center m-0">
            <div class="col-12 col-md-5 col-lg-4 p-0 d-flex flex-column justify-content-between background-grey">
                <div class="row justify-content-center m-0">
                    <div class="col-12 p-0 ">
                        <div class="row header m-0">
                            <div class="col-12 position-relative p-0" *ngIf="productTemplate.imageCoverUrl">
                                <img class="w-100 img-cover" [src]="productTemplate.imageCoverUrl"
                                     alt="Product template cover image">
                                <div class="overlay position-absolute"></div>
                            </div>
                        </div>
                        <div class="row m-0"
                             [ngClass]="productTemplate.imageCoverUrl ? 'margin-top-with-cover' : 'margin-top-without-cover'">
                            <div class="col-12 content w-100 h-auto">
                                <div class="row mt-3">
                                    <div class="col-12 product-template-title">
                                        {{productTemplate.title}}
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-12 product-template-brand">
                                        {{productTemplate.brand.name}}
                                    </div>
                                </div>

                                <div class="row description">
                                    <div class="col-12 pt-5">
                                        {{productTemplate.description}}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row m-0 my-3 py-3 promotions-btn card"
                             *ngIf="productTemplate.buttonTitle && productTemplate.buttonUrl">
                            <div class="col-12">
                                <button type="button" class="w-100 p-3 red-btn color-blue"
                                        (click)="goToLink(productTemplate.buttonUrl)">
                                    {{productTemplate.buttonTitle}}</button>
                            </div>
                        </div>

                        <!--                       ------------------- CARDS---------------->
                        <div class="row m-0 my-3 card" *ngFor="let card of productTemplate.cards; index as cardIndex">
                            <div class="col-12">
                                <div class="row m-0 pt-3">
                                    <div class="col-12 pl-0 pb-3 d-flex align-items-center" *ngIf="card.title && card.title !== ''">
                                        <span class="title text-overflow">{{card.title}}</span>
                                    </div>
                                </div>
                                <div class="row m-0 pb-3 card-multimedia" *ngIf="card.cardType === ProductTemplateCardType.multimedia">
                                    <div class="col-12 p-0 position-relative">
                                        <video [id]="'video' + cardIndex" class="w-100 d-block"
                                               [poster]="card.multimedia.coverImageUrl"
                                               preload="metadata" playsinline muted>
                                            <source [src]="card.multimedia.videoUrl" type="video/mp4">
                                        </video>
                                        <div [id]="'overlay' + cardIndex"
                                             class="video-overlay w-100 h-100 position-absolute"
                                             (click)="playPauseVideo(card, cardIndex)">
                                        </div>
                                        <img *ngIf="!card.multimedia.playVideo"
                                             src="assets/images/preview/Play%20button.png"
                                             class="position-absolute m-auto"
                                             alt="Video play button" (click)="playPauseVideo(card, cardIndex)">
                                    </div>
                                </div>

                                <div class="row m-0 pb-3 card-single-image"
                                     *ngIf="card.cardType === ProductTemplateCardType.singleImage">
                                    <div class="col-12 p-0">
                                        <img [src]="card.singleImage.imageUrl" class="w-100 d-block">
                                    </div>
                                </div>

                                <div class="row m-0 pb-3 card-rich-text" *ngIf="card.cardType === ProductTemplateCardType.richText">
                                    <quill-editor class="col-12 p-0" [(ngModel)]="card.richText.text"
                                                  [readOnly]="true"
                                                  [modules]="{toolbar:false}"></quill-editor>
                                </div>

                                <app-carousel *ngIf="card.cardType === ProductTemplateCardType.carousel"
                                              [carouselImages]="card.carousel.images"></app-carousel>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
