<section id="reset-pass-section" class="auth-section background-grey">
    <div class="container p-0">
        <div class="row m-0 justify-content-center">
            <form class="col-12 col-md-5 col-lg-4 position-relative overflow-auto"
                  #resetPasswordForm="ngForm"
                  (ngSubmit)="resetPassword()">
                <div class="row mt-4 mb-3 header">
                    <div class="col-3 text-left position-absolute z-index-1">
                        <img class="cursor-pointer" src="assets/images/core/close_black.png"
                             alt="Close" appBackButton>
                    </div>
                    <div class="col-12 text-center">
                        {{'resetPassword.title' | translate}}
                    </div>
                </div>

                <div class="row mt-4 label">
                    <div class="col-12">
                        {{'resetPassword.newPassword' | translate}}
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <input class="input" [(ngModel)]="user.newPassword" [type]="hideNewPassword ? 'password' : 'text'"
                               id="newPassword" name="newPassword" #newPassword="ngModel"
                               [placeholder]="'register.atLeast6Characters' | translate" required minlength="6">
                        <fa-icon class="cursor-pointer position-absolute password-icon"
                                 [icon]="hideNewPassword ? faSlashEye : faEye" (click)="hideNewPassword = !hideNewPassword">
                        </fa-icon>
                        <app-error *ngIf="!newPassword.pristine && newPassword.errors?.required">
                            {{'common.errorMsg.passwordRequired' | translate}}</app-error>
                        <app-error *ngIf="!newPassword.pristine && newPassword.errors?.minlength">
                            {{'register.errorMsg.passwordMinLength' | translate}}</app-error>
                    </div>
                </div>

                <div class="row mt-4 label">
                    <div class="col-12">
                        {{'resetPassword.confirmPassword' | translate}}
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <input class="input" [(ngModel)]="user.confirmPassword" [type]="hideConfirmPassword ? 'password' : 'text'"
                               id="confirmPassword" name="confirmPassword" #confirmPassword="ngModel"
                               [pattern]="newPassword.value"
                               [placeholder]="'register.atLeast6Characters' | translate" required minlength="6">
                        <fa-icon class="cursor-pointer position-absolute password-icon"
                                 [icon]="hideConfirmPassword ? faSlashEye : faEye" (click)="hideConfirmPassword = !hideConfirmPassword">
                        </fa-icon>
                        <app-error *ngIf="!confirmPassword.pristine && confirmPassword.errors?.required">
                            {{'common.errorMsg.passwordRequired' | translate}}</app-error>
                        <app-error *ngIf="!confirmPassword.pristine && confirmPassword.errors?.minlength">
                            {{'register.errorMsg.passwordMinLength' | translate}}</app-error>
                        <app-error *ngIf="!confirmPassword.pristine && confirmPassword.errors?.pattern">
                            {{'resetPassword.errorMsg.passwordPattern' | translate}}</app-error>
                    </div>
                </div>

                <div class="row my-3">
                    <div class="col-12">
                        <app-alert-error [error]="error"></app-alert-error>
                    </div>
                </div>

                <div class="row m-0 my-3 w-100 footer">
                    <div class="col-12">
                        <div class="row justify-content-center">
                            <div class="col-12">
                                <button class="w-100 red-btn text-center" type="submit" [buttonSpinner]="isLoading"
                                        [disabled]="resetPasswordForm.invalid || resetPasswordForm.pristine">
                                    {{'resetPassword.title' | translate}}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</section>
