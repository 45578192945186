<div class="row d-flex flex-row m-0 pb-3 card-carousel">
    <div class="col-12 p-0">
        <div class="row m-0 scrolling-wrapper d-flex flex-nowrap">
            <div class="cell-carousel col m-0 p-0" *ngFor="let image of carouselImages" (click)="navigateToProduct(image)"
            [ngClass]="image.routing ? 'cursor-pointer' : ''">
                <div class="row cell-content m-0 w-100 overflow-hidden position-relative">
                    <div class="img-content position-absolute d-flex align-items-center justify-content-center p-0">
                        <img class="w-100 h-100" [ngClass]="!image.imageUrl ? 'no-image' : ''" [alt]="image.title"
                             [src]="image.imageUrl ? image.imageUrl : 'assets/images/preview/no-image.svg'">
                    </div>
                </div>
                <div class="row p-0 image-title text-overflow m-0 mt-3">
                    {{image.title}}
                </div>
            </div>
        </div>
    </div>
</div>
