<section id="home-section" class="flex-column background-grey">
    <ng-container *ngIf="!authService.isLoggedIn(); else loggedIn">
        <div class="row full-height">
            <div class="col-12 p-0 d-flex-center full-height">
                <div class="flex-column d-flex-center full-height justify-content-between">
                    <div class="flex-column d-flex-center">
                        <img src="assets/images/core/engage-home.png" />
                        <div>
                            <div class="title">
                                {{ "register.firstPage.title" | translate }}
                            </div>
                            <div class="subtitle">
                                {{ "register.firstPage.subtitle" | translate }}
                            </div>
                            <div class="list-checks">
                                <ng-container *ngFor="let check of 'register.firstPage.checks' | translate">
                                    <div class="d-flex mb-3">
                                        <img class="me-3" src="assets/images/core/icon-check.svg" />
                                        <span class="check">{{ check }}</span>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                    <div class="row justify-content-center">
                        <button routerLink="/register" class="pink-button mb-3">
                            {{ "register.createAccount" | translate }}
                        </button>
                        <span routerLink="/login" class="login text-center fw-bold mb-4 cursor-pointer"
                            >I already have an account</span
                        >
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
    <ng-template #loggedIn>
        <app-discover></app-discover>
    </ng-template>
</section>
