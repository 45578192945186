import {Component, OnInit} from '@angular/core';
import {emailRegex} from '../../../models/user';
import {AuthService} from '../../../shared/services/auth.service';
import {ToastrService} from 'ngx-toastr';
import {ToastService} from '../../../shared/services/toast.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-forgot-password',
    templateUrl: './forgot-password.component.html',
    styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
    email: string;
    emailRegex = emailRegex;
    error: any = null;
    isLoading = false;

    constructor(private authService: AuthService,
                private translateService: TranslateService,
                private toastService: ToastService) {
    }

    ngOnInit(): void {
    }

    async forgotPassword(): Promise<void> {
        try {
            this.error = null;
            this.isLoading = true;
            const resp = await this.authService.forgotPassword(this.email);
            if (resp) {
                this.toastService.showSuccess(this.translateService.instant('resetPassword.toastMsg.emailSent'));
            }
        } catch (e) {
            this.error = e;
        } finally {
            this.isLoading = false;
        }
    }

}
