import {Observable, Subject} from 'rxjs';
import {HttpClient} from '@angular/common/http';

export abstract class CrudService<T> {
    private dataChangedSubject: Subject<boolean> = new Subject<boolean>();

    public dataChanged$(): Observable<boolean> {
        return this.dataChangedSubject.asObservable();
    }

    protected constructor(protected http: HttpClient, protected endpoint: string) {
    }

    getOne(id: string): Promise<T> {
        return this.http.get<T>(`${this.endpoint}/${id}`).toPromise();
    }

    async deleteOne(id: string): Promise<void> {
        await this.http.delete(`${this.endpoint}/${id}`).toPromise();
        this.dataChangedSubject.next(true);
    }

    async updateOne(id: string, data: Partial<T>): Promise<T> {
        const updatedData = await this.http.put<T>(`${this.endpoint}/${id}`, data).toPromise();
        this.dataChangedSubject.next(true);
        return updatedData;
    }

    async addOne(data: T): Promise<T> {
        const addedData = await this.http.post<T>(this.endpoint, data).toPromise();
        this.dataChangedSubject.next(true);
        return addedData;
    }
}
