import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AuthService } from '../../shared/services/auth.service';
import { Promotion, PromotionCardType } from '../../models/promotion';
import { ActivatedRoute, Router } from '@angular/router';
import { PromotionService } from '../../shared/services/promotion.service';
import { ToastService } from '../../shared/services/toast.service';
import { SessionService } from '../../shared/services/session.service';
import { TranslateService } from '@ngx-translate/core';
import { CampaignService } from '../../shared/services/campaign.service';

@Component({
    selector: 'app-campaign-page',
    templateUrl: './campaign-page.component.html',
    styleUrls: ['./campaign-page.component.scss'],
})
export class CampaignPageComponent implements OnInit {
    PromotionCardType = PromotionCardType;
    promotion: Promotion;
    currentPoints: number;
    isLoading = false;
    error: any;
    participated = false;

    @ViewChild('template') template!: TemplateRef<any>;
    modalRef!: BsModalRef;

    constructor(
        private modalService: BsModalService,
        public authService: AuthService,
        private route: ActivatedRoute,
        private promotionService: PromotionService,
        private router: Router,
        private toastService: ToastService,
        private sessionService: SessionService,
        private translateService: TranslateService,
        private campaignService: CampaignService
    ) {}

    async ngOnInit(): Promise<void> {
        const id = this.route.snapshot.paramMap.get('campaignId');
        const { promotion, currentPoints } = await this.promotionService.getOne(id);
        this.currentPoints = currentPoints;
        this.promotion = promotion;
    }

    async processData(callback: () => Promise<any>): Promise<void> {
        try {
            this.error = null;
            this.isLoading = true;
            await callback();
        } catch (e) {
            this.error = e;
            switch (e?.error?.message) {
                case 'Fields scanLocationId should be unique':
                    const errMsgScanAgain = this.translateService.instant('promotions.pleaseScanTheCodeAgain');
                    this.error.error.message = errMsgScanAgain;
                    this.toastService.showError(errMsgScanAgain);
                    break;
                case 'Fields productCodeId should be unique':
                    const errMsgMultipleScans = this.translateService.instant('promotions.warningMultipleScan');
                    this.error.error.message = errMsgMultipleScans;
                    this.toastService.showError(errMsgMultipleScans);
                    break;
                default:
                    this.toastService.showError(e);
            }
            console.log(e);
        } finally {
            this.isLoading = false;
        }
    }

    async participate(): Promise<void> {
        await this.processData(async () => {
            if (!this.sessionService.token) {
                await this.router.navigate(['/login'], { queryParams: { returnUrl: this.router.url } });
                return;
            }

            if (this.participated) {
                this.toastService.showError(this.translateService.instant('promotions.warningMultipleScan'));
                return;
            }

            const scanCodeDetails = this.sessionService.scanCodeDetails;
            if (!scanCodeDetails?.scannedCode || !scanCodeDetails?.brandAlias) {
                this.toastService.showError(this.translateService.instant('promotions.pleaseScanTheCodeAgain'));
                return;
            }

            const { promotionRegistration, loyaltyPoints } = await this.campaignService.registerPromotion(
                this.promotion._id,
                scanCodeDetails.scannedCode,
                scanCodeDetails.brandAlias
            );

            this.currentPoints = loyaltyPoints.pointsCount;
            this.participated = true;
            this.toastService.showSuccess(this.translateService.instant('promotions.successRegisterToCampaign'));
        });
    }

    openModal(template: TemplateRef<any>) {
        this.modalRef = this.modalService.show(template);
    }
}
