<div class="container install-banner" *ngIf="show">
    <div class="row justify-content-center">
        <div class="col-12 col-md-5 col-lg-4 d-flex-center banner-content">
            <div class="row p-3 d-flex-center">
                <div class="col-1 d-flex-center">
                    <img src="engage.png" alt="Engage logo">
                </div>
                <div class="col-9 d-flex-center"
                     *ngIf="applicationType === ApplicationType.web || applicationType === ApplicationType.android">
                    <a (click)="addToHomeClicked()">{{'common.addAppToHomeScreen' | translate}}</a>
                </div>
                <div class="col-9 d-flex-center" *ngIf="applicationType === ApplicationType.ios">
                    <span>{{'common.addAppToHomeScreenOnIOS' | translate}}
                        <img src="assets/images/core/share_ios.png" alt="Menu IOS" class="ios-icon ml-2">  >
                        <img src="assets/images/core/add_ios.png" alt="Add to Home Screen" class="ios-icon"></span>
                </div>
                <div class="col-1 d-flex-center">
                    <img src="/assets/images/core/close_black.png" alt="Close" (click)="hideInstallationPrompt()">
                </div>
            </div>
        </div>
    </div>
</div>

