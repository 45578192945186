import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CarouselComponent } from './components/carousel/carousel.component';
import { AlertErrorComponent } from './components/alert-error/alert-error.component';
import { LoadingSpinnerComponent } from './components/loading-spinner/loading-spinner.component';
import { RatingComponent } from './components/rating/rating.component';
import { FormsModule } from '@angular/forms';
import { NgbDateAdapter, NgbDateParserFormatter, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HttpClientModule } from '@angular/common/http';
import { BackButtonDirective } from './directives/back-button.directive';
import { ButtonSpinnerDirective } from './directives/button-spinner.directive';
import { ErrorComponent } from './components/input/error/error.component';
import { PatternValidatorDirective } from './directives/pattern-validator.directive';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ToastrModule, ToastrService } from 'ngx-toastr';
import { InvalidCodePageComponent } from './components/invalid-code-page/invalid-code-page.component';
import { TranslateModule } from '@ngx-translate/core';
import { ToolbarComponent } from './components/toolbar/toolbar.component';
import { RouterModule } from '@angular/router';
import { CustomAdapter } from './utils/custom-adapter';
import { CustomDateParserFormatter } from './utils/custom-date-parser-formatter';
import { ProductTemplateComponent } from './components/product-template/product-template.component';
import { QuillModule } from 'ngx-quill';
import { NgxPicaModule } from '@digitalascetic/ngx-pica';
import { FeedbackCardComponent } from './components/feedback-card/feedback-card.component';
import { InstallMessageBannerComponent } from './components/install-message/install-message-banner.component';

const componentList = [
    InvalidCodePageComponent,
    CarouselComponent,
    FeedbackCardComponent,
    ErrorComponent,
    AlertErrorComponent,
    LoadingSpinnerComponent,
    RatingComponent,
    ToolbarComponent,
    ProductTemplateComponent,
    BackButtonDirective,
    ButtonSpinnerDirective,
    PatternValidatorDirective,
];

const usedModules = [
    FormsModule,
    NgbModule,
    HttpClientModule,
    FontAwesomeModule,
    TranslateModule,
    RouterModule,
    QuillModule,
    NgxPicaModule,
];

@NgModule({
    declarations: [...componentList, InstallMessageBannerComponent],
    imports: [
        ToastrModule.forRoot({
            preventDuplicates: true,
        }),
        ...usedModules,
        CommonModule,
    ],
    exports: [...usedModules, ...componentList, InstallMessageBannerComponent],
    providers: [
        ToastrService,
        {
            provide: NgbDateParserFormatter,
            useClass: CustomDateParserFormatter,
        },
        {
            provide: NgbDateAdapter,
            useClass: CustomAdapter,
        },
    ],
})
export class SharedModule {}
