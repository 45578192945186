import { Component, OnInit } from '@angular/core';
import { emailRegex, phoneRegex, User } from '../../../models/user';
import { AuthService } from '../../../shared/services/auth.service';
import { Router } from '@angular/router';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { ToastService } from '../../../shared/services/toast.service';
import { TranslateService } from '@ngx-translate/core';
import { SessionService } from '../../../shared/services/session.service';

@Component({
    selector: 'app-register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.scss'],
})
export class RegisterComponent implements OnInit {
    user: User = {
        firstName: '',
        lastName: '',
        password: '',
        email: '',
        phone: '',
    };
    agreeWithTermsAndConds = false;
    error: any;
    isLoading = false;
    hidePassword = true;
    faEye = faEye;
    faSlashEye = faEyeSlash;
    emailRegex = emailRegex;
    phoneRegex = phoneRegex;
    step = 1;

    constructor(
        private authService: AuthService,
        private toastService: ToastService,
        private translateService: TranslateService,
        private sessionService: SessionService,
        private router: Router
    ) {}

    ngOnInit(): void {}

    continue(): void {
        this.step = 2;
    }

    async processData(callback: () => Promise<any>): Promise<void> {
        try {
            this.isLoading = true;
            this.error = null;
            await callback();
        } catch (e) {
            this.error = e;
        } finally {
            this.isLoading = false;
        }
    }

    async register(): Promise<void> {
        await this.processData(async () => {
            await this.authService.register(
                this.user.email,
                this.user.firstName,
                this.user.lastName,
                this.user.phone,
                this.user.password
            );
            // this.sessionService.saveRegistrationEmailToStorage(this.user.email);
            // this.toastService.showWarning(this.translateService.instant('register.toastMsg.confirmEmail'));
            await this.router.navigateByUrl('/home');
        });
    }
}
