import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Promotion} from '../../models/promotion';

@Injectable({
    providedIn: 'root'
})
export class AdminPromotionService {
    endpoint: string;

    constructor(private http: HttpClient) {
        this.endpoint = `${environment.apiBaseUrlForAdmin}/promotions`;
    }

    async getPromotion(id: string, authToken: string, brandId: string, locale: string): Promise<Promotion> {
        const promotion = await this.http.get<Promotion>(`${this.endpoint}/${id}`,
            {
                headers: new HttpHeaders({
                    Authorization: `Bearer ${authToken}`,
                    'X-Locale': locale
                }),
                params: {brandId}
            }).toPromise();
        return promotion;
    }
}
