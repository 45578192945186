import { Component, Input, OnInit } from '@angular/core';
import { PrizeStatus, Promotion } from '../../../models/promotion';

@Component({
    selector: 'app-prizes-vertical-progress-bar',
    templateUrl: './prizes-vertical-progress-bar.component.html',
    styleUrls: ['./prizes-vertical-progress-bar.component.scss'],
})
export class PrizesVerticalProgressBarComponent {
    @Input() promotion: Promotion;
    @Input() currentPoints: number;
    inProgress = false;
    PrizeStatus = PrizeStatus;
}
