<div class="p-0 mb-3">
    <div class="mx-3 max-width500 d-flex border rounded-3 bg-white campaign">
        <ng-container *ngFor="let card of campaign.cards">
            <ng-container *ngIf="card.cardType === PromotionCardType.carousel">
                <img class="campaign-image" src="{{campaign.imageCoverUrl}}">
            </ng-container>
        </ng-container>
        <div class="d-flex flex-column ps-3 pt-2">
            <div class="campaign-title pe-3">
                {{campaign.title}}
            </div>
            <div class="truncate description mt-1 pe-3">
                {{campaign.description}}
            </div>
        </div>
    </div>
</div>

