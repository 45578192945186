<div class="container text-center p-3" *ngIf="error && errorMessage" [ngClass]="showAppLogo ? 'bg-app-logo' : ''">
    <div class="row p-3">
        <div class="col-12">
            {{ errorMessage }}
            <ng-container *ngIf="routeToHomepage">
                {{'common.clickHereToGoTo' | translate}} <a routerLink="/home"> {{'common.homepage' | translate}}.</a>
            </ng-container>
        </div>
    </div>
</div>

