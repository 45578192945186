<section id="register-section" class="flex-column background-grey">
    <div class="container p-0 full-height">
        <div class="row container justify-content-center p-0 m-0 full-height">
            <div class="top-bar p-0 ">
                        <div class="row top-bar m-0  bg-white">
                            <div class="col-12 top-bar">
                                <div class="row my-3 position-relative">
                                    <div class="col-2 text-center">
                                        <ng-container *ngIf="step===1">
                                            <img src="assets/images/preview/left-arrow-black.png" alt="Close icon"
                                                 class="cursor-pointer" appBackButton/>
                                        </ng-container>
                                        <ng-container *ngIf="step===2">
                                            <img src="assets/images/preview/left-arrow-black.png" alt="Close icon"
                                                 class="cursor-pointer" (click)="step=step-1"/>
                                        </ng-container>
                                    </div>
                                    <div class="col-10 header-image text-center">
                                        <img src="assets/images/Logo.png" height="24" alt="Engage">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <ng-container *ngIf="step === 1">
                            <form class="h-100 pe-3 form background-grey" #registerForm="ngForm"
                                  (ngSubmit)="continue()">
                                <div class="create-account with-topbar mt-3 mb-2">
                                    Create your Engage™️ account
                                </div>
                                <div class="d-flex-column-between justify-content-between full-height">
                                    <div>
                                        <div class="row mt-4 label">
                                            <div class="col-12">
                                                {{'common.emailAddress' | translate}}
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-12">
                                                <input class="input" [(ngModel)]="user.email" type="email" id="email" name="email" required
                                                       [pattern]="emailRegex" placeholder="Enter email" email #email="ngModel">
                                                <app-error *ngIf="!email.pristine && email.errors?.required">
                                                    {{'common.errorMsg.emailRequired' | translate}}</app-error>
                                                <app-error
                                                        *ngIf="!email.pristine && (email.errors?.email || (email.errors && !email.errors?.required))">
                                                    {{'register.errorMsg.emailInvalid' | translate}}</app-error>
                                            </div>
                                        </div>

                                        <div class="row mt-4 label">
                                            <div class="col-12">
                                                {{'common.password' | translate}}
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-12">
                                                <input class="input" [(ngModel)]="user.password" [type]="hidePassword ? 'password' : 'text'"
                                                       id="password" name="password" #password="ngModel"
                                                       [placeholder]="'register.placeholder.password' | translate" required minlength="6">
                                                <fa-icon class="cursor-pointer position-absolute password-icon"
                                                         [icon]="hidePassword ? faSlashEye : faEye" (click)="hidePassword = !hidePassword">
                                                </fa-icon>
                                                <app-error *ngIf="!password.pristine && password.errors?.required">
                                                    {{'common.errorMsg.passwordRequired' | translate}}</app-error>
                                                <app-error *ngIf="!password.pristine && password.errors?.minlength">
                                                    {{'register.errorMsg.passwordMinLength' | translate}}</app-error>
                                            </div>
                                        </div>

                                        <div class="row my-3">
                                            <div class="col-12">
                                                <app-alert-error [error]="error"></app-alert-error>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row m-0 w-100 footer">
                                        <div class="col-12">
                                            <div class="row justify-content-center">
                                                <button class="w-100 pink-button mb-5 text-center" type="submit" [buttonSpinner]="isLoading"
                                                        [disabled]="registerForm.invalid">
                                                    {{'register.continue' | translate}}</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </form>
                        </ng-container>
                        <ng-container *ngIf="step === 2">
                            <form class="h-100 pe-3 form background-grey" #registerForm="ngForm"
                                  (ngSubmit)="register()">
                                <div class="d-flex-column-between with-topbar justify-content-between full-height">
                                    <div>
                                        <div class="row mt-4 label">
                                            <div class="col-12">
                                                {{'register.firstName' | translate}}
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-12">
                                                <input class="input" [(ngModel)]="user.firstName" type="text" id="firstName" name="firstName" required
                                                       [placeholder]="'register.placeholder.firstName' | translate" #firstName="ngModel" maxlength="80">
                                                <app-error *ngIf="!firstName.pristine && firstName.errors?.maxlength">
                                                    {{'register.errorMsg.firstNameMaxlength' | translate}}</app-error>
                                                <app-error *ngIf="!firstName.pristine && firstName.errors?.required">
                                                    {{'register.errorMsg.firstNameRequired' | translate}}</app-error>
                                            </div>
                                        </div>

                                        <div class="row mt-4 label">
                                            <div class="col-12">
                                                {{'register.lastName' | translate}}
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-12">
                                                <input class="input" [(ngModel)]="user.lastName" type="text" id="lastName" name="lastName" required
                                                       [placeholder]="'register.placeholder.lastName' | translate" #lastName="ngModel" maxlength="80">
                                                <app-error *ngIf="!lastName.pristine && lastName.errors?.maxlength">
                                                    {{'register.errorMsg.lastNameMaxlength' | translate}}</app-error>
                                                <app-error *ngIf="!lastName.pristine && lastName.errors?.required">
                                                    {{'register.errorMsg.lastNameRequired' | translate}}</app-error>
                                            </div>
                                        </div>

                                        <div class="row mt-4 label">
                                            <div class="col-12">
                                                {{'register.phone' | translate}}
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-12">
                                                <input class="input" type="tel" [(ngModel)]="user.phone" [pattern]="phoneRegex"
                                                       [placeholder]="'register.placeholder.phone' | translate" name="phoneNumber" id="phoneNumber" #phoneNumber="ngModel">
                                                <app-error *ngIf="!phoneNumber.pristine && phoneNumber.errors">
                                                    {{'account.errorMsg.phoneNumberInvalid' | translate}}</app-error>
                                            </div>
                                        </div>
                                        <div class="message">
                                            We will use your phone number to send you relevant updates and we will never spam you.
                                        </div>

                                        <div class="row my-3">
                                            <div class="col-12">
                                                <app-alert-error [error]="error"></app-alert-error>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row m-0 w-100 footer">
                                        <div class="col-12">
                                            <div class="row justify-content-center">
                                                <button mat-flat-button (click)="register()" class="w-100 pink-button text-center" type="submit" [buttonSpinner]="isLoading"
                                                        [disabled]="registerForm.invalid || registerForm.pristine">
                                                    {{'register.title' | translate}}</button>
                                            </div>
                                        </div>
                                        <div class="terms">
                                            By Registering, you agree to Engage’s <a href="https://engage.link/">Terms & Conditions</a> & <a href="https://engage.link/">Privacy Policy</a>.
                                        </div>
                                    </div>
                                </div>

                            </form>
                        </ng-container>

            </div>
        </div>
    </div>
</section>
