import { Component, OnInit } from '@angular/core';
import { PromotionRegistrationService } from '../../../shared/services/promotion-registration.service';
import { ToastService } from '../../../shared/services/toast.service';
import { PromotionService } from '../../../shared/services/promotion.service';

@Component({
    selector: 'app-all-campaigns',
    templateUrl: './all-campaigns.component.html',
    styleUrls: ['./all-campaigns.component.scss'],
})
export class AllCampaignsComponent implements OnInit {
    allCampaigns = [];

    constructor(private promotionService: PromotionService, private toastService: ToastService) {}

    async ngOnInit() {
        try {
            this.allCampaigns = await this.promotionService.getAllCampaigns();
        } catch (error) {
            this.toastService.showError('Error loading campaigns');
        }
    }
}
