<section id="promotion-list-section">
    <div class="container p-0">
        <div class="row m-0 justify-content-center">
            <div class="col-12 col-md-5 col-lg-4 p-0 min-vh-100 background-grey">
                <div class="row m-0 mt-4 mb-3 header">
                    <div class="col-3 text-center">
                        <img class="cursor-pointer" src="assets/images/preview/left-arrow-black.png"
                             alt="Back arrow" appBackButton>
                    </div>
                    <div class="col-9 text-left">
                        {{'promotions.campaignsInfo' | translate}}
                    </div>
                </div>
                <div class="row m-3 white-card">
                    <div class="col-12 p-0 pt-5">
                        <div class="row m-0 my-3" *ngIf="promotions.length > 1">
                            <div class="col-12 text-center" *ngIf="promotions.length > 1">
                                {{'promotions.messageOneCampaign' | translate}}</div>
                        </div>
                        <div class="row m-0 my-5">
                            <div class="col-12 d-flex-center">
                                <img src="assets/images/promotion/loyalty.png" alt="Campaigns">
                            </div>
                        </div>
                        <div class="row m-3">
                            <app-alert-error class="w-100" [error]="error"></app-alert-error>
                        </div>
                        <div class="row m-3 justify-content-center">
                            <app-loading-spinner *ngIf="isLoading"></app-loading-spinner>
                        </div>

                        <div class="row m-0 my-3" *ngIf="promotions.length === 0 && !isLoading">
                            <div class="col-12 text-center color-red">
                                {{'promotions.noPromotionsForThisProduct' | translate}}
                            </div>
                        </div>
                        <ng-container *ngIf="promotions.length > 0">
                            <div class="row m-0 promotion py-3 m-3"
                                 *ngFor="let promotion of promotions; let promoIndex = index">
                                <div class="col-12">
                                    <div class="row">
                                        <div class="col-4 d-flex-center">
                                            <img class="w-100" [alt]="promotion.title"
                                                 [src]="promotion.imageCoverUrl ? promotion.imageCoverUrl : 'assets/images/Logo.png'">
                                        </div>
                                        <div class="col-8">
                                            <div class="row">
                                                <div class="col-12 title">{{ (promotion.title.length > 25) ?
                                                    (promotion.title | slice:0:25) + '...' : (promotion.title) }}</div>
                                            </div>
                                            <div class="row">
                                                <div class="col-12"><a [routerLink]="'/promotions/' + promotion._id"
                                                                       class="cursor-pointer">{{'promotions.seeDetails' | translate}}</a>
                                                </div>
                                            </div>
                                            <div class="row mt-3">
                                                <div class="col-12">
                                                    <button class="red-btn" type="button"
                                                            [disabled]="promotion.isConsumerRegistered || promotion.disableButton"
                                                            (click)="registerToPromotion(promotion, promoIndex)">
                                                        {{'promotions.participate' | translate}}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mt-3" *ngIf="promotion.isConsumerRegistered">
                                        <div class="col-12 text-center color-red">
                                            {{'promotions.alreadyRegisteredForThisCampaign' | translate}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
