<section id="change-password-section" class="flex-column background-grey">
    <div class="col justify-content-center container p-0 overflow-hidden">
        <div class="row justify-content-center">
            <div class="bg-white top-bar flex-column text-center">
                <ng-container *ngIf="step===1">
                    <img src="assets/icons/left-arrow.svg" alt="Close icon"
                         class="cursor-pointer left-arrow" appBackButton/>
                </ng-container>
                <ng-container *ngIf="step===2">
                    <img src="assets/icons/left-arrow.svg" alt="Close icon"
                         class="cursor-pointer left-arrow" (click)="step=step-1"/>
                </ng-container>
                <span class="title">{{ "changePassword.title" | translate }}</span>
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="max-width500 with-topbar">
            <ng-container *ngIf="step === 1">
                <form class="h-100 max-width500 ps-2 form background-grey" #registerForm="ngForm"
                      (ngSubmit)="continue()">
                    <div class="change-password pt-3">
                       {{'changePassword.firstStepMessage' | translate}}
                    </div>
                    <div class="d-flex-column-between justify-content-between full-height">
                        <div>
                            <div class="row mt-4 label">
                                <div class="col-12">
                                    {{'changePassword.currentPassword' | translate}}
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-11">
                                    <input class="input" [(ngModel)]="user.oldPassword" [type]="hideOldPassword ? 'password' : 'text'"
                                           id="password" name="password" #password="ngModel"
                                           [placeholder]="'register.placeholder.password' | translate" required minlength="6">
                                    <fa-icon class="cursor-pointer position-absolute password-icon"
                                             [icon]="hideOldPassword ? faSlashEye : faEye" (click)="hideOldPassword = !hideOldPassword">
                                    </fa-icon>
                                    <app-error *ngIf="!password.pristine && password.errors?.required">
                                        {{'common.errorMsg.passwordRequired' | translate}}</app-error>
                                    <app-error *ngIf="!password.pristine && password.errors?.minlength">
                                        {{'register.errorMsg.passwordMinLength' | translate}}</app-error>
                                </div>
                            </div>

                            <div class="row my-3">
                                <div class="col-12">
                                    <app-alert-error [error]="error"></app-alert-error>
                                </div>
                            </div>
                        </div>
                        <div class="row pe-4 justify-content-center">
                            <button class="pink-button mb-5 text-center" type="submit" [buttonSpinner]="isLoading"
                                    [disabled]="registerForm.invalid">
                                {{'register.continue' | translate}}</button>
                        </div>
                    </div>

                </form>
            </ng-container>
            <ng-container *ngIf="step === 2">
                <form class="h-100 overflow-hidden ps-2 max-width500 form background-grey" #registerForm="ngForm"
                      (ngSubmit)="changePassword()">
                    <div class="change-password me-2 mt-3">
                        {{'changePassword.secondStepMessage' | translate}}
                    </div>
                    <div class="d-flex-column-between justify-content-between full-height">
                        <div>
                            <div class="row mt-4 label">
                                <div class="col-12">
                                    {{'changePassword.newPassword' | translate}}
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-11">
                                    <input class="input" [(ngModel)]="user.newPassword" [type]="hideNewPassword ? 'password' : 'text'"
                                           id="password" name="password" #password="ngModel"
                                           [placeholder]="'register.placeholder.password' | translate" required minlength="6">
                                    <fa-icon class="cursor-pointer position-absolute password-icon"
                                             [icon]="hideNewPassword ? faSlashEye : faEye" (click)="hideNewPassword = !hideNewPassword">
                                    </fa-icon>
                                    <app-error *ngIf="!password.pristine && password.errors?.required">
                                        {{'common.errorMsg.passwordRequired' | translate}}</app-error>
                                    <app-error *ngIf="!password.pristine && password.errors?.minlength">
                                        {{'register.errorMsg.passwordMinLength' | translate}}</app-error>
                                </div>
                            </div>
                            <div class="row my-3">
                                <div class="col-12">
                                    <app-alert-error [error]="error"></app-alert-error>
                                </div>
                            </div>
                        </div>
                        <div class="row pe-4 justify-content-center">
                            <button (click)="changePassword()" class="pink-button mb-5 text-center" type="submit" [buttonSpinner]="isLoading"
                                    [disabled]="registerForm.invalid || registerForm.pristine">
                                {{'changePassword.title' | translate}}</button>
                        </div>
                    </div>
                </form>
            </ng-container>
            </div>
        </div>
    </div>
</section>
