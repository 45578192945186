import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-progress-bar',
    templateUrl: './progress-bar.component.html',
    styleUrls: ['./progress-bar.component.scss'],
})
export class ProgressBarComponent {
    @Input() progress: number;

    generateDots(): string {
        const totalSteps = 10;
        const completedSteps = Math.floor(this.progress * totalSteps);
        const remainingSteps = totalSteps - completedSteps;
        const dots = '.'.repeat(completedSteps);
        const spaces = ' '.repeat(remainingSteps);

        return dots + spaces;
    }
}
