import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import jsQR from 'jsqr';
import {
    getBrandAliasFromEngageURL,
    getScannedCodeFromEngageURL,
    validateEngageURL,
    validateURL,
} from '../../shared/utils/utils';
import { ToastService } from '../../shared/services/toast.service';
import { ProductService } from '../../shared/services/product.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { CodeType, ProductResponse } from '../../models/product';
import { RedirectUrl } from '../../models/redirect-url';
import { query } from '@angular/animations';

@Component({
    selector: 'app-qr-scan',
    templateUrl: './qr-scan.component.html',
    styleUrls: ['./qr-scan.component.scss'],
})
export class QrScanComponent implements OnInit, OnDestroy {
    triggerImage: Subject<void> = new Subject<void>(); // webcam snapshot trigger
    interval: any;
    tabOpened = false;
    error: any = null;
    locale: string;

    videoOptions: MediaTrackConstraints = {
        facingMode: ['back', 'rear', 'environment'],
        noiseSuppression: true,
        autoGainControl: true,
        focusMode: 'continuous',
        exposureMode: 'continuous',
        width: 1920,
        height: 1080,
    };

    constructor(
        private toastService: ToastService,
        private productService: ProductService,
        @Inject(DOCUMENT) private document: Document,
        private router: Router,
        private route: ActivatedRoute
    ) {}

    async ngOnInit(): Promise<void> {
        if (this.interval) {
            clearInterval(this.interval);
        }
        this.interval = setInterval(() => {
            this.triggerImage.next();
        }, 400);
        this.locale = this.route.snapshot.queryParamMap.get('locale');
    }

    ngOnDestroy(): void {
        clearInterval(this.interval);
    }

    captureImage(event): void {
        const imageData = event._imageData;
        this.checkImage(imageData).then();
    }

    async checkImage(imageData: ImageData): Promise<void> {
        const code = jsQR(imageData.data, imageData.width, imageData.height, { inversionAttempts: 'dontInvert' });

        if (code) {
            clearInterval(this.interval);
            // await this.downloadImageFromUrl(imageData);
            if (validateEngageURL(code.data)) {
                // engage URL
                await this.processData(async () => {
                    const { redirectUrl, appRelativeUrl } = await this.productService.scanVisibleOrHiddenCode(
                        code.data
                    );
                    if (redirectUrl) {
                        this.document.location.href = redirectUrl;
                    } else {
                        await this.router.navigate([appRelativeUrl], { queryParams: { locale: this.locale } });
                    }
                }, code.data);
            } else {
                if (validateURL(code.data) && this.tabOpened === false) {
                    this.tabOpened = true;
                    window?.open(code.data, '_blank')?.focus();
                } else {
                    this.toastService.showInfo(code.data);
                }
            }
        }
    }

    async downloadImageFromUrl(imageData): Promise<void> {
        const blob = await this.imageDataToBlob(imageData);
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.setAttribute('href', url);
        link.setAttribute('download', `qr-code-${new Date().getTime().toString()}.png`);
        link.innerText = 'click';
        link.click();
    }

    imageDataToBlob(imageData): Promise<Blob> {
        const w = imageData.width;
        const h = imageData.height;
        const canvas = document.createElement('canvas');
        canvas.width = w;
        canvas.height = h;
        const ctx = canvas.getContext('2d');
        ctx.putImageData(imageData, 0, 0, 0, 0, w, h); // synchronous

        return new Promise((resolve, reject) => {
            canvas.toBlob(resolve); // image/png format
        });
    }

    async processData(callback: () => Promise<any>, engageUrl: string): Promise<void> {
        try {
            await callback();
        } catch (e) {
            const { url, queryParams } = this.productService.handleScanError(e, engageUrl);
            if (url && queryParams) {
                await this.router.navigate([url], { queryParams });
            } else {
                this.error = e;
            }
        }
    }
}
