import { Component, OnInit } from '@angular/core';
import { UserPromotionVoucherService } from '../../shared/services/user-promotion-voucher.service';
import { UserPromotionVoucherStatus } from '../../models/user-promotion-voucher';
import { ToastService } from '../../shared/services/toast.service';
import { PromotionRegistrationService } from '../../shared/services/promotion-registration.service';
import { Promotion } from '../../models/promotion';
import { PromotionService } from '../../shared/services/promotion.service';

@Component({
    selector: 'app-discover',
    templateUrl: './discover.component.html',
    styleUrls: ['./discover.component.scss'],
})
export class DiscoverComponent implements OnInit {
    activeVouchers = [];
    activeVouchersCarousel = [];
    activeCampaignRegistrations = [];
    allCampaigns = [];

    constructor(
        public userPromotionVoucherService: UserPromotionVoucherService,
        private toastService: ToastService,
        private promotionRegistrationService: PromotionRegistrationService,
        private promotionService: PromotionService
    ) {}

    async ngOnInit() {
        try {
            this.activeVouchers = await this.userPromotionVoucherService.getAll(UserPromotionVoucherStatus.active);
            this.activeVouchersCarousel = this.activeVouchers.slice(0, 10);
            this.activeCampaignRegistrations = (
                await this.promotionRegistrationService.getActiveCampaignsOfUser()
            ).registrations;
            this.allCampaigns = await this.promotionService.getAllCampaigns();
        } catch (error) {
            this.toastService.showError('Error loading vouchers');
        }
    }
}
