<section id="product-section" *ngIf="product">
    <div class="container p-0">
        <div class="row justify-content-center m-0">
            <div class="col-12 col-md-5 col-lg-4 p-0 min-vh-100 d-flex flex-column justify-content-between background-grey">
                <div class="row justify-content-center m-0">
                    <div class="col-12 p-0 ">
                        <div class="row header m-0">
                            <div class="col-12 position-relative p-0" *ngIf="product.imageCoverUrl">
                                <img class="w-100 img-cover" [src]="product.imageCoverUrl"
                                     [alt]="'product.productCover' | translate">
                                <div class="overlay position-absolute"></div>
                                <div class="row justify-content-center position-absolute info-product m-0"
                                     *ngIf="false">
                                    <div class="col-3 text-center p-0">
                                        <img class="cursor-pointer" src="assets/images/preview/left-arrow-white.png"
                                             alt="Back arrow">
                                    </div>
                                    <div class="col-7 text-left p-0">
                                        {{ 'product.productInfo' | translate}}
                                    </div>
                                    <div class="col-2 text-left p-0">
                                        <img class="cursor-pointer" src="assets/images/preview/share-icon-white.png"
                                             [alt]="'product.shareProduct' | translate">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row m-0"
                             [ngClass]="product.imageCoverUrl ? 'margin-top-with-cover' : 'margin-top-without-cover'">
                            <div class="col-12 content w-100 h-auto">
                                <div class="row position-relative">
                                    <div class="product-img text-center position-relative">
                                        <div class="img-container d-flex-center h-100 position-absolute p-0">
                                            <img class="w-100 h-100" [alt]="product.title"
                                                 [ngClass]="!product.imageUrl ? 'no-image' : ''"
                                                 [src]="product.imageUrl ? product.imageUrl : 'assets/images/preview/no-image.svg'">
                                        </div>
                                    </div>
                                    <div class="col-7">
                                        <div class="row">
                                            <div class="col-12 p-3">
                                                <button type="button" class="pl-3 pr-3 w-100">
                                                    <ng-container *ngIf="product.reviews.count <= 0">
                                                        {{'product.leaveFirstReview' | translate}}
                                                    </ng-container>
                                                    <ng-container *ngIf="product.reviews.count === 1">
                                                        {{'product.seeReview' | translate}}
                                                    </ng-container>
                                                    <ng-container *ngIf="product.reviews.count > 1">
                                                        {{'product.seeReviews' | translate}}
                                                    </ng-container>
                                                </button>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-7 pr-0 d-flex align-items-center justify-content-center pt-2">
                                                <app-rating
                                                        [rating]="product.reviews.count <= 0 ? 0 : product.reviews.rating">
                                                </app-rating>
                                            </div>
                                            <div class="col-3 p-0 rating" *ngIf="product.reviews.count > 0">
                                                {{product.reviews.rating | number: '1.0-1'}}
                                            </div>
                                            <div class="col p-0 no-reviews d-flex align-items-center pt-2">
                                                <ng-container *ngIf="product.reviews.count > 0">
                                                    ({{product.reviews.count}})
                                                </ng-container>
                                                <ng-container *ngIf="product.reviews.count <= 0">
                                                    {{'product.notReviewed' | translate}}
                                                </ng-container>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mt-3">
                                    <div class="col-12 product-title">
                                        {{product.title}}
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-12 product-brand">
                                        {{product.brand.name}}
                                    </div>
                                </div>

                                <div class="row description">
                                    <div class="col-12 pt-5">
                                        {{product.description}}
                                    </div>
                                </div>

                                <div class="row tags pt-2">
                                    <div class="col-12 d-flex justify-content-start flex-wrap">
                                <span *ngFor="let tag of product.tags" class="p-1 my-1 mr-1 text-center">
                                    {{tag.name}}
                                </span>
                                    </div>
                                </div>

                                <div class="row pt-3">
                                    <div class="col-6 py-2" *ngFor="let property of product.productInfo.properties">
                                        <div class="row py-2">
                                            <div class="col-12 label">{{property.name}}</div>
                                        </div>
                                        <div class="row">
                                            <div class="col-12 value">{{property.value}}</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row py-3" *ngIf="false">
                                    <div class="col-12 drawer text-center">
                                        {{'product.findOutMore' | translate}}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row m-0 my-3 py-3 promotions-btn card"
                             *ngIf="product.productButtonTitle && product.productButtonUrl">
                            <div class="col-12">
                                <button type="button" class="w-100 p-3 red-btn color-blue"
                                        (click)="goToLink(product.productButtonUrl)">
                                    {{product.productButtonTitle}}</button>
                            </div>
                        </div>

                        <!--                       ------------------ BRAND CONTROLLER INFO CARD-->
                        <div class="row m-0 my-3 py-3 card"
                             *ngIf="product.brandControllerInfoCard?.text; let brandControllerCardText">
                            <div class="col-12">
                                <div class="row m-0 pb-3">
                                    <div class="col-12 p-0 title text-overflow">
                                        {{'product.brandControllerInformation' | translate}}
                                    </div>
                                </div>
                                <div class="row m-0 pb-3 card-rich-text">
                                    <div class="col-12 p-0 pt-3" [innerHTML]="brandControllerCardText">
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!--                       ------------------- CARDS---------------->
                        <div class="row m-0 my-3 card" *ngFor="let card of product.cards; index as cardIndex">
                            <div class="col-12">
                                <div class="row m-0 pt-3">
                                    <div class="col-12 p-0 pb-3 d-flex align-items-center" *ngIf="(card.title && card.title !== '') ||( card.icon && card.icon !== '')">
                                        <img *ngIf="card.icon && card.icon !== ''" [src]="CardIcons[card.icon]"
                                             [alt]="card.icon">
                                        <span class="title text-overflow" *ngIf="card.title && card.title !== ''"
                                              [ngClass]="(card.icon && card.icon !== '') ? 'pl-3' : ''">{{card.title}}</span>
                                    </div>
                                </div>
                                <div class="row m-0 pb-3 card-multimedia" *ngIf="card.cardType === CardType.multimedia">
                                    <div class="col-12 p-0 position-relative">
                                        <video [id]="'video' + cardIndex" class="w-100 d-block"
                                               [poster]="card.multimedia.coverImageUrl"
                                               preload="metadata" playsinline muted>
                                            <source [src]="card.multimedia.videoUrl" type="video/mp4">
                                        </video>
                                        <div [id]="'overlay' + cardIndex"
                                             class="video-overlay w-100 h-100 position-absolute"
                                             (click)="playPauseVideo(card, cardIndex)">
                                        </div>
                                        <img *ngIf="!card.multimedia.playVideo"
                                             src="assets/images/preview/Play%20button.png"
                                             class="position-absolute m-auto"
                                             alt="Video play button" (click)="playPauseVideo(card, cardIndex)">
                                    </div>
                                </div>

                                <div class="row m-0 pb-3 card-single-image"
                                     *ngIf="card.cardType === CardType.singleImage">
                                    <div class="col-12 p-0">
                                        <img [src]="card.singleImage.imageUrl" class="w-100 d-block">
                                    </div>
                                </div>

                                <div class="row m-0 pb-3 card-rich-text" *ngIf="card.cardType === CardType.richText">
                                    <quill-editor class="col-12 p-0" [(ngModel)]="card.richText.text"
                                                  [readOnly]="true"
                                                  [modules]="{toolbar:false}"></quill-editor>
                                </div>

                                <app-carousel *ngIf="card.cardType === CardType.carousel"
                                              [carouselImages]="card.carousel.images"></app-carousel>

                                <app-feedback-card [card]="card" [isAdmin]="isAdmin" [productId]="product._id"></app-feedback-card>
                            </div>
                        </div>

                        <!--                         CARD: Produse similare - Similar products-->
                        <div class="row m-0 my-3 card" *ngIf="similarProducts && similarProducts.length > 0">
                            <div class="col-12">
                                <div class="row m-0 py-3">
                                    <div class="col-12 p-0 d-flex align-items-center">
                                        <img [src]="CardIcons.products" [alt]="'product.similarProducts' | translate">
                                        <span class="pl-3 title text-overflow"> {{'product.similarProducts' | translate}}</span>
                                    </div>
                                </div>
                                <app-carousel [disableRouting]="disableRouting"
                                              [carouselImages]="similarProducts"></app-carousel>
                            </div>
                        </div>

                        <!--                        CARD: Te-ar mai putea interesa si - You might also be interested in-->
                        <div class="row m-0 my-3 card" *ngIf="interestedInProducts && interestedInProducts.length > 0">
                            <div class="col-12">
                                <div class="row m-0 py-3">
                                    <div class="col-12 p-0 d-flex align-items-center">
                                        <img [src]="CardIcons.products"
                                             [alt]="'product.interestedInProducts' | translate">
                                        <span class="pl-3 title text-overflow">{{'product.interestedInProducts' | translate}}</span>
                                    </div>
                                </div>
                                <app-carousel [disableRouting]="disableRouting"
                                              [carouselImages]="interestedInProducts"></app-carousel>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row m-0 py-3 position-sticky justify-content-center promotions-btn"
                     *ngIf="product.promotions && product.promotions[0]; let promotion">
                    <div class="col-12">
                        <button type="button" class="w-100 p-3 red-btn"
                                [routerLink]="disableRouting ? null : '/campaignPage/' + promotion._id">
                            {{promotion.title}}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
