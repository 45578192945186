<div class="p-0 mt-3">
    <div class="container component pt-1 ps-0 rounded-3 bg-white min-width345">
        <div class="max-width500 m-3 d-flex bg-white campaign">
            <img class="campaign-image" [src]="campaign?.imageCoverUrl">
            <div class="d-flex flex-column ps-3 pt-2">
                <div class="brand mt-1 pe-3">
                    {{campaign?.brand.name}}
                </div>
                <div class="campaign-title pe-3">
                    {{campaign?.title}}
                </div>
            </div>
        </div>
        <div class="expires text-end w-100 pe-3 pb-3">
            {{expirationMessage}}
        </div>
    </div>
</div>

