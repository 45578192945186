import {NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {CodeType} from '../../models/product';
import {environment} from '../../../environments/environment';

export enum ApplicationType {
    'ios' = 'ios',
    'android' = 'android',
    'web' = 'web'
}

export function validateURL(str: string): boolean {
    const pattern = new RegExp('^((ft|htt)ps?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name and extension
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?' + // port
        '(\\/[-a-z\\d%@_.~+&:]*)*' + // path
        '(\\?[;&a-z\\d%@_.,~+&:=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
    return !!pattern.test(str);
}

export function validateEngageURL(str: string): boolean {
    let pwaUrl = environment.pwaUrl;
    pwaUrl = pwaUrl.replace(/[.*+?^${}()|[\]\\/]/g, '\\$&');
    const pattern = new RegExp(`^${pwaUrl}\\/[a-zA-Z0-9]+\\?(id|a)=[a-zA-Z0-9]+$`);
    return !!pattern.test(str);
}

export function getBrandAliasFromEngageURL(str: string): string {
    const urlWithoutQueryString = str.split('?')[0];
    const urlFields = urlWithoutQueryString.split('/');
    return urlFields[urlFields.length - 1];
}

export function getScannedCodeFromEngageURL(str: string): string {
    let queryStrings = str.split('id=');
    if (queryStrings.length === 1) {
        queryStrings = str.split('a=');
    }
    const engageCodeQuery = queryStrings[1];
    return engageCodeQuery.split('&')[0];
}

export function getVisibilityFromEngageURL(str: string): CodeType {
    return str.split('id=').length > 1 ? CodeType.visibleCode : CodeType.hiddenCode;
}

export function getScanCodeDetailsFromUrl(url: string): {brandAlias: string, code: string, visibility: CodeType} {
    const slashSplits = url.split('/');
    const queryString = slashSplits[slashSplits.length - 1];

    const brandAlias = getBrandAliasFromEngageURL(queryString);
    const code = getScannedCodeFromEngageURL(queryString);
    const visibility = getVisibilityFromEngageURL(queryString);
    return {brandAlias, code, visibility};
}

export function getToday(): NgbDateStruct {
    const date = new Date();
    return {year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate()};
}
