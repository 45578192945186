import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, ReplaySubject, Subject } from 'rxjs';
import { User } from '../../models/user';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { SessionService } from './session.service';
import storageAvailable from 'storage-available';

export interface AuthResponse {
    user: User;
    authToken: string;
}

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    private tokenSubject: Subject<string> = new ReplaySubject(1);
    private endpoint = environment.apiBaseUrlForConsumer;
    private readonly localStorage = null;

    get token$(): Observable<string> {
        return this.tokenSubject.asObservable();
    }

    private userSubject: BehaviorSubject<User> = new BehaviorSubject(null);

    get user$(): Observable<User> {
        return this.userSubject.asObservable();
    }

    get user(): User {
        const user = this.userSubject.value;
        if (user?.birthday) {
            user.birthday = new Date(user.birthday);
        }
        return user;
    }

    private token: string;

  constructor(
    private router: Router,
    private http: HttpClient,
    private sessionService: SessionService
  ) {
    this.localStorage = storageAvailable("localStorage") ? localStorage : null;
    this.getDataFromStorage();
  }

    getInitials(): string {
        return this.user.firstName.substring(0, 1).toUpperCase() + this.user.lastName.substring(0, 1).toUpperCase();
    }

    getDataFromStorage(): void {
        const token = this.localStorage?.getItem('authToken') || null;
        const userString = this.localStorage?.getItem('user') || null;
        const user = JSON.parse(userString) || null;
        this.tokenSubject.next(token || null);
        this.sessionService.token = token;
        this.token = token;
        this.userSubject.next(user);
    }

  async register(email: string, firstName: string, lastName: string, phone: string, password: string): Promise<void> {
    if (!email || !password || !firstName || !lastName || !phone) {
      throw new Error('Invalid email, first name, last name or password');
    }
    const resp = await this.http
      .post<AuthResponse>(`${this.endpoint}/auth/register`, {
        email,
        firstName,
        lastName,
        phone,
        password,
      })
      .toPromise();
    this.handleLoginResponse(resp);
  }

    async login(email: string, password: string): Promise<void> {
        if (!email || !password) {
            throw new Error('Invalid email or password');
        }

        const resp = await this.http.post<AuthResponse>(`${this.endpoint}/auth/login`, { email, password }).toPromise();
        this.handleLoginResponse(resp);
    }

    private handleLoginResponse(resp: AuthResponse): void {
        const { user, authToken } = resp;
        this.localStorage?.setItem('authToken', authToken);
        this.localStorage?.setItem('user', JSON.stringify(user));
        this.userSubject.next(user);
        this.tokenSubject.next(authToken);
        this.sessionService.token = authToken;
        this.token = authToken;
    }

    async logout(): Promise<void> {
        await this.http.post(`${this.endpoint}/auth/logout`, {}, { responseType: 'text' }).toPromise();
        await this.clearLocalStorage();
    }

  async clearLocalStorage(): Promise<void> {
    this.localStorage?.clear();
    this.tokenSubject.next(null);
    this.sessionService.token = null;
    this.userSubject.next(null);
    this.token = null;
    await this.router.navigate(['/home']);
  }

    isLoggedIn(): boolean {
        return !!this.token;
    }

    async changePassword(passObj: { oldPassword: string, newPassword: string }): Promise<boolean> {
        const response = await this.http.post<{ passwordSet: boolean }>(`${this.endpoint}/auth/change-password`, passObj).toPromise();
        if (!response.passwordSet) {
            throw new Error('Password wasn\'t set');
        }
        return response.passwordSet;
    }

    async forgotPassword(email: string): Promise<boolean> {
        const response = await this.http.post<{ emailSent: boolean }>(`${this.endpoint}/auth/forgot-password`, {email}).toPromise();
        if (!response.emailSent) {
            throw new Error(`Email not found.`);
        }
        return response.emailSent;
    }

    async resetPassword(token: string, newPassword: string): Promise<void> {
        const response = await this.http.post<void>(`${this.endpoint}/auth/reset-password`,
            {newPassword, token}).toPromise();
        await this.router.navigate(['/login']);
    }

    async confirmEmail(token: string): Promise<User> {
        const {user} = await this.http.get<{ user: User }>(`${this.endpoint}/auth/confirm-email/${token}`)
            .toPromise();
        return user;
    }

    async resendConfirmationEmail(email): Promise<boolean> {
        const {emailSent} = await this.http.post<{ emailSent: boolean }>
        (`${this.endpoint}/auth/resend-confirm-email`, {email})
            .toPromise();
        return emailSent;
    }
}
