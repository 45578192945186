import { Directive, Input } from '@angular/core';
import { NG_VALIDATORS, Validator, UntypedFormControl } from '@angular/forms';

@Directive({
    // tslint:disable-next-line:directive-selector
    selector: '[pattern][formControlName],[pattern][formControl],[pattern][ngModel]',
    providers: [{provide: NG_VALIDATORS, useExisting: PatternValidatorDirective, multi: true}]
})
export class PatternValidatorDirective implements Validator {
    @Input() pattern: RegExp;

    validate(c: UntypedFormControl): {[key: string]: any} {
        return ( c.value === null || (c.value && !c.value.toString().match(this.pattern))) ? {pattern: true} : null;
    }
}