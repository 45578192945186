import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Locale} from '../../models/locale';
import {UploadData, UploadResponse, ImageData} from '../../models/upload';
import {environment} from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class UploadService {
    constructor(private http: HttpClient) {
    }

    public async uploadFile(data: ImageData, locale: Locale = Locale.EN): Promise<UploadResponse> {
        const requestObj = {...data, mimetype: data.file.type};
        delete requestObj.file;

        const uploadData = await this.http
            .post<UploadData>(`${environment.apiBaseUrlForConsumer}/uploads`, requestObj, {
                headers: {
                    'X-Locale': locale
                }
            })
            .toPromise();
        const formData = new FormData();

        Object.entries(uploadData.upload.fields).forEach(([key, value]) => {
            formData.append(key, value);
        });
        formData.append('file', data.file);

        return this.http
            .post<UploadResponse>(uploadData.upload.url, formData, {
                headers: {
                    'X-Locale': locale
                }
            })
            .toPromise();
    }
}
