import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PreviewProductComponent } from './components/preview-product/preview-product.component';
import { ViewProductComponent } from './components/view-product/view-product.component';
import { PromotionComponent } from './components/promotion/promotion.component';
import { RegisterComponent } from './core/auth/register/register.component';
import { LoginComponent } from './core/auth/login/login.component';
import { ScanComponent } from './components/scan/scan.component';
import { HomeComponent } from './components/home/home.component';
import { InvalidCodePageComponent } from './shared/components/invalid-code-page/invalid-code-page.component';
import { ForgotPasswordComponent } from './core/auth/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './core/auth/reset-password/reset-password.component';
import { SetupAccountComponent } from './core/auth/setup-account/setup-account.component';
import { PreviewProductTemplateComponent } from './components/preview-product-template/preview-product-template.component';
import { PromotionListComponent } from './components/promotion-list/promotion-list.component';
import { AuthRedirectGuard } from './shared/guards/auth-redirect.guard';
import { ViewPromotionComponent } from './components/view-promotion/view-promotion.component';
import { PreviewPromotionComponent } from './components/preview-promotion/preview-promotion.component';
import { AuthGuard } from './shared/guards/auth.guard';
import { CampaignPageComponent } from './components/campaign-page/campaign-page.component';
import { BrandPageComponent } from './components/brand-page/brand-page.component';
import { DiscoverComponent } from './components/discover/discover.component';
import { VoucherPageComponent } from './components/voucher-page/voucher-page.component';
import { ScanVoucherComponent } from './components/voucher-page/scan-voucher/scan-voucher.component';
import { VoucherSuccessComponent } from './components/voucher-page/scan-voucher/voucher-success/voucher-success.component';
import { VoucherErrorComponent } from './components/voucher-page/scan-voucher/voucher-error/voucher-error.component';
import { AllActiveCampaignsComponent } from './components/discover/all-active-campaigns/all-active-campaigns.component';
import { AllCampaignsComponent } from './components/discover/all-campaigns/all-campaigns.component';
import { ChangePasswordComponent } from './components/profile-page/change-password/change-password.component';
import { AllVouchersComponent } from './components/discover/all-vouchers/all-vouchers.component';

const routes: Routes = [
    {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full',
    },
    {
        path: 'home',
        component: HomeComponent,
    },
    {
        path: 'discover',
        component: DiscoverComponent,
    },
    {
        path: 'v/success',
        component: VoucherSuccessComponent,
    },
    {
        path: 'v/error',
        component: VoucherErrorComponent,
    },
    {
        path: 'v/:voucherId',
        component: ScanVoucherComponent,
    },
    {
        path: 'account',
        loadChildren: () => import('./components/profile-page/profile-page.module').then((m) => m.ProfilePageModule),
        canActivate: [AuthGuard],
    },
    {
        path: 'scan',
        component: ScanComponent,
    },
    {
        path: 'campaignPage/:campaignId',
        component: CampaignPageComponent,
    },
    {
        path: 'all-active-campaigns',
        component: AllActiveCampaignsComponent,
    },
    {
        path: 'all-campaigns',
        component: AllCampaignsComponent,
    },
    {
        path: 'all-vouchers',
        component: AllVouchersComponent,
    },
    {
        path: 'brand/:brandId',
        component: BrandPageComponent,
    },
    {
        path: 'voucher/:voucherId',
        component: VoucherPageComponent,
    },
    {
        path: 'preview/:productId',
        component: PreviewProductComponent,
    },
    {
        path: 'preview-template/:productTemplateId',
        component: PreviewProductTemplateComponent,
    },
    {
        path: 'preview-promotion/:promotionId',
        component: PreviewPromotionComponent,
    },
    {
        path: 'promotions/:promotionId',
        component: ViewPromotionComponent,
    },
    {
        path: 'promotion-list/:productId',
        component: PromotionListComponent,
    },
    {
        path: 'invalid-code/:brandAlias',
        component: InvalidCodePageComponent,
    },
    {
        path: 'register',
        component: RegisterComponent,
        canActivate: [AuthRedirectGuard],
    },
    {
        path: 'login',
        component: LoginComponent,
        canActivate: [AuthRedirectGuard],
    },
    {
        path: 'changePassword',
        component: ChangePasswordComponent,
    },
    {
        path: 'setup-account/:token',
        component: SetupAccountComponent,
        canActivate: [AuthRedirectGuard],
    },
    {
        path: 'setup-account',
        component: SetupAccountComponent,
        canActivate: [AuthRedirectGuard],
    },
    {
        path: 'forgot-password',
        component: ForgotPasswordComponent,
        canActivate: [AuthRedirectGuard],
    },
    {
        path: 'reset-password/:token',
        component: ResetPasswordComponent,
        canActivate: [AuthRedirectGuard],
    },
    {
        path: ':brandAlias/:productId',
        component: ViewProductComponent,
    },
    {
        path: ':brandAlias',
        component: ViewProductComponent,
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {})],
    exports: [RouterModule],
})
export class AppRoutingModule {}
