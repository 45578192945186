<ul id="progress">
    <ng-container *ngFor="let prize of promotion?.prizes; let first=first">
        <div class="position-relative">
            <ng-container *ngIf="!first">
                <ng-container *ngIf="prize.prizeStatus !== PrizeStatus.notStarted;else notStarted">
                    <li><div class="divider pink"></div></li>
                </ng-container>
                <ng-template #notStarted>
                    <li><div class="divider grey"></div></li>
                </ng-template>
            </ng-container>
            <ng-container *ngIf="prize.prizeStatus === PrizeStatus.notStarted">
                <li><div class="node greyBorder-white"></div>
                    <div class="ms-3">
                        <app-prize-entry-list
                                class="prize-entry-list"
                                [currentPoints]="currentPoints"
                                [prize]="prize"
                        ></app-prize-entry-list>
                    </div>
                </li>
            </ng-container>
            <ng-container *ngIf="prize.prizeStatus === PrizeStatus.inProgress">
                <li><div class="node pinkBorder-white"></div>
                    <div class="ms-3">
                        <app-prize-entry-list
                                class="prize-entry-list"
                                [currentPoints]="currentPoints"
                                [prize]="prize"
                        ></app-prize-entry-list>
                    </div>
                </li>
            </ng-container>
            <ng-container *ngIf="prize.prizeStatus === PrizeStatus.completed">
                <li><div class="node pink"></div>
                    <div class="ms-3">
                        <app-prize-entry-list
                                class="prize-entry-list"
                                [currentPoints]="currentPoints"
                                [prize]="prize"
                        ></app-prize-entry-list>
                    </div>
                </li>
            </ng-container>

        </div>
    </ng-container>
</ul>
