export const en = {
    common: {
        days: 'days',
        hours: 'hours',
        minutes: 'minutes',
        or: 'Or',
        register: 'Register',
        login: 'Log in',
        password: 'Password',
        emailAddress: 'Email address',
        buyTheProductYouScanned: 'Buy the product you scanned',
        openBottleAndLookForTheSeal: 'Open the bottle and look for the seal inside',
        errorMsg: {
            passwordRequired: 'Password is required',
            emailRequired: 'Email is required',
        },
        toastMsg: {
            addedSuccessfullyLoyaltyPoints:
                '{{loyaltyPointsCount}} loyalty points were successfully added to your account',
            alreadyAddedLoyaltyPoints: 'Loyalty points were already added to your account',
        },
        permissions: {
            allow: 'Allow',
            deny: 'Deny',
        },
        invalidCode: 'Invalid code',
        warningPage: 'Warning',
        clickHereToGoTo: 'Click here to go to',
        homepage: 'Homepage',
        addAppToHomeScreen: 'Add application to home screen',
        addAppToHomeScreenOnIOS:
            "To install this web app on your device tap the Menu button and then 'Add to Home screen' button",
    },
    product: {
        productInfo: 'Product information',
        shareProduct: 'Share product',
        typeYourAnswer: 'Type here your answer',
        submitAnswer: 'Submit answer',
        uploadImage: 'Upload image',
        productCover: 'Product cover',
        answerSubmitted: 'Your answer was successfully submitted',
        pleaseLogInToSendYourMessage: 'Please log in to send your message!',
        leaveFirstReview: 'Leave the first review',
        seeReview: 'See review',
        seeReviews: 'See reviews',
        notReviewed: 'not reviewed',
        findOutMore: 'Find out more',
        similarProducts: 'Similar products',
        interestedInProducts: 'You might also be interested in',
        productWithPromotion: 'This product has a marketing campaign',
        findOutMoreAboutPromotion: 'Find out more about the marketing campaign',
        brandControllerInformation: 'Brand controller information',
    },
    promotions: {
        pageTitle: 'Detalii campanie',
        campaignInfo: 'Campaign information',
        bigPrize: 'The big prize in',
        howCanYouParticipate: 'How can you participate?',
        campaignTermsAndConditions: "Campaign's terms and conditions",
        prizes: 'Premii',
        prize: 'Prize',
        participate: 'Participă',
        campaignsInfo: 'Campaigns information',
        registerForThisCampaign: 'Register for this campaign',
        messageOneCampaign: 'You are allowed to register in only one campaign from below',
        seeDetails: 'See details',
        successRegisterToCampaign: 'Campaign registration was successful',
        successRegisterToAllCampaigns: 'Registration for all campaigns was successful',
        noPromotionsForThisProduct: 'No available promotions were found for this product',
        pleaseScanTheCodeAgain: 'Please scan the code again',
        userAlreadyRegisteredForACampaign: 'User already registered for a campaign',
        warningMultipleScan: 'Warning, multiple scan! This code was already scanned',
        alreadyRegisteredForThisCampaign: 'You registered for this campaign',
        youWonAPrize: 'Congratulations!!! You just won a prize: {{prizeName}}!',
        prizeMessage: 'Try again and enter other codes, you can win a prize!',
        termsAndConditionsMessage: 'Prin apăsarea butonului “Participă”, confirmi că ai citit și ești de acord cu',
        termsAndConditions: 'Termenii și Condițiile',
        errorDefault: 'Something went wrong. Please try again later.',
    },
    campaigns: {
        activeCampaigns: 'Active campaigns',
        allCampaigns: 'All campaigns',
        expiresIn: 'Expires in',
        days: 'days',
        expiresToday: 'Expires today',
        expired: 'Expired',
    },
    vouchers: {
        activeVouchers: 'Active vouchers',
        allVouchers: 'All vouchers',
        applySuccess: 'Voucher-ul a fost aplicat cu succes',
        applyError: 'Ne pare rău, acest voucher a expirat',
        backButtonMainPage: 'Înapoi pe pagina principală',
    },
    discover: {
        seeAll: 'See all',
    },
    register: {
        title: 'Register',
        continue: 'Continue',
        createAccount: 'Create account',
        firstName: 'First name',
        lastName: 'Last name',
        phone: 'Phone number',
        placeholder: {
            password: 'Choose password',
            firstName: 'Enter your first name',
            lastName: 'Enter your last name',
            phone: 'Enter your phone number',
        },
        atLeast6Characters: 'At least 6 characters',
        agreeWith: 'I agree with the',
        termsAndConditions: 'terms and conditions',
        alreadyHaveAnAccount: 'Already have an account?',
        logInHere: 'Log in here',
        headlineMessage: 'To take part into promotions you need to register first.',
        errorMsg: {
            firstNameRequired: 'First name is required',
            firstNameMaxlength: 'First name is too long',
            lastNameRequired: 'Last name is required',
            lastNameMaxlength: 'Last name is too long',
            passwordMinLength: 'Password must be at least 6 characters long',
            emailInvalid: 'Email is invalid',
        },
        toastMsg: {
            confirmEmail: 'Please confirm your email!',
        },
        firstPage: {
            checks: [
                'Discover new campaigns',
                'Access your vouchers easily, on the go',
                'Keep track of your active campaigns',
                'See all your prizes',
            ],
            title: 'One Engage™️ account, unlimited vouchers and campaigns',
            subtitle:
                'With an Engage account you can easily keep track of your campaigns and discover more from your favorite brands.',
        },
    },
    setupAccount: {
        title: 'Confirm email',
        resendConfirmationEmail: 'Resend Confirmation Email',
        failed: 'Failed to confirm your email',
        emailConfirmed: 'Your email was successfully confirmed',
        sendSuccessfullyPleaseCheckEmail: 'Resend successfully. Please check your email.',
        pleaseWait: 'Please wait',
    },
    login: {
        title: 'Login',
        headlineMessage: 'You can enter your credentials here to authenticate.',
        dontHaveAccount: "Don't you have an account?",
        registerHere: 'Register here',
        forgotPassword: 'Forgot your password?',
        resendConfirmationEmail: 'Resend confirmation email',
    },
    forgotPassword: {
        title: 'Forgot password',
        headlineMessage: 'Please enter your email.',
        sendEmail: 'Send email',
    },
    resetPassword: {
        title: 'Reset password',
        goToLogin: 'Go to login',
        newPassword: 'New password',
        confirmPassword: 'Confirm password',
        errorMsg: {
            passwordPattern: "Passwords don't match",
        },
        toastMsg: {
            emailSent: 'Please check your email for further instructions',
        },
    },
    scan: {
        title: 'Scan',
        headlineMessage: 'Position the barcode or data matrix within the scan',
        allowAccessToCamera: 'Do you allow access to the camera?',
        allowCameraForAppToFunction: 'In order to use the application it is necessary to allow access to the camera.',
    },
    home: {
        scan: 'Scan',
    },
    errorMessages: {
        scannedProductNotFound: 'The product is not available anymore.',
        brandOrProductNotFound: 'The requested Brand/Product was not found.',
        brandNotFound: 'The requested Brand was not found.',
        errorCamera: 'An error occurred will opening the camera. Please reload the page',
        expiredSession: 'Your session has expired',
    },
    toolbar: {
        discover: 'Discover',
        account: 'Account',
        promotions: 'Campaigns',
    },
    profile: {
        profile: 'My profile',
        accountDetails: 'Account details',
        help: 'Help',
        legalInformation: 'Legal information',
        logout: 'Log out',
        firstName: 'First name',
        lastName: 'Last name',
        email: 'Email',
        birthday: 'Birthday',
        changePassword: 'Change password',
        deleteAccount: 'Delete account',
        phone: 'Phone',
    },
    account: {
        account: 'Account',
        myReviews: 'My reviews',
        message: 'Authenticate in the app to have access to all functionalities.',
        accountSettings: 'Account profile',
        edit: 'Edit',
        changePhoto: 'Change photo',
        emailAndProfile: 'Email and profile',
        nameAndSurname: 'Name and surname',
        emailAddress: 'Email address',
        phoneNumber: 'Phone number',
        birthday: 'Birthday',
        password: 'Password',
        notifications: 'Notifications',
        notificationsSettings: 'Notifications profile',
        others: 'Others',
        privacyPolicy: 'Privacy policy',
        termsAndConditions: 'Terms and conditions',
        logout: 'Logout',
        fullNamePlaceholder: 'No name and surname',
        emailPlaceholder: 'No email address',
        phoneNumberPlaceholder: 'No phone number',
        birthdayPlaceholder: 'No birthday',
        changeName: 'Change name',
        changePhoneNumber: 'Change phone number',
        changeBirthday: 'Change birthday',
        save: 'Save',
        editAccount: 'Edit account',
        errorMsg: {
            birthdayInvalid: 'Birthday is invalid',
            phoneNumberInvalid: 'Phone number is invalid',
            newPasswordRequired: 'New password is required',
            oldPasswordRequired: 'Old password is required',
            repeatPasswordRequired: 'Please repeat the new password',
            newPasswordMinLength: 'New password must be at least 6 characters long',
            pleaseConfirmNewPassword: 'Please confirm your new password',
        },
        toastMsg: {
            accountEditedSuccessfully: 'Account was edited successfully',
            passwordChangedSuccessfully: 'Password changed successfully',
        },
    },
    changePassword: {
        title: 'Change password',
        currentPassword: 'Current password',
        newPassword: 'New password',
        firstStepMessage: 'Enter current password.',
        secondStepMessage: 'Enter new password. It should contain at least 6 characters.',
    },
};
