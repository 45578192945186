export const ro = {
    common: {
        days: 'zile',
        hours: 'ore',
        minutes: 'minute',
        or: 'Sau',
        register: 'Înregistrare',
        login: 'Logare',
        password: 'Parola',
        emailAddress: 'Adresa de email',
        errorMsg: {
            passwordRequired: 'Parola este obligatorie',
            emailRequired: 'Emailul este obligatoriu',
        },
        buyTheProductYouScanned: 'Cumpară produsul pe care l-ai scanat',
        openBottleAndLookForTheSeal: 'Desfă sticla și uită-te după sigiliul din interior',
        permissions: {
            allow: 'Permite',
            deny: 'Refuză',
        },
        toastMsg: {
            addedSuccessfullyLoyaltyPoints:
                '{{loyaltyPointsCount}} puncte de loialitate au fost adăugate cu succes în contul dumneavoastră.',
            alreadyAddedLoyaltyPoints: 'Punctele de loialitate au fost deja adăugate în contul dumneavoastră.',
        },
        invalidCode: 'Cod invalid',
        warningPage: 'Avertizare',
        clickHereToGoTo: 'Click aici pentru a merge spre ',
        homepage: 'Pagina de acasă',
        addAppToHomeScreen: 'Instalați aplicația pe ecranul de pornire',
        addAppToHomeScreenOnIOS:
            'Pentru a instala această aplicație web apăsați pe butonul de Meniu și apoi pe butonul „Adăugați aplicație pe ecranul de pornire”',
    },
    product: {
        productInfo: 'Informații produs',
        shareProduct: 'Distribuie produsul',
        typeYourAnswer: 'Introduceți răspunsul aici',
        submitAnswer: 'Salvați răspunsul',
        uploadImage: 'Adaugă imagine',
        productCover: 'Imagine fundal produs',
        answerSubmitted: 'Răspunsul a fost înregistrat cu succes',
        pleaseLogInToSendYourMessage: 'Vă rugăm să vă autentificați pentru a vă trimite răspunsul!',
        leaveFirstReview: 'Lasă prima recenzie',
        seeReview: 'Vezi recenzia',
        seeReviews: 'Vezi recenziile',
        notReviewed: 'încă nerevizuit',
        findOutMore: 'Află mai multe',
        similarProducts: 'Produse similare',
        interestedInProducts: 'Te-ar mai putea interesa și',
        productWithPromotion: 'Acest produs este supus unei promoții',
        findOutMoreAboutPromotion: 'Află detalii despre promoție',
        brandControllerInformation: 'Informații revizor de brand',
    },
    promotions: {
        pageTitle: 'Detalii campanie',
        campaignInfo: 'Informații campanie',
        bigPrize: 'Marea extragere în',
        howCanYouParticipate: 'Cum poți participa?',
        campaignTermsAndConditions: 'Reguli și condiții campanie',
        prizes: 'Premii',
        prize: 'Premiu',
        participate: 'Participă',
        campaignsInfo: 'Informații campanii',
        registerForThisCampaign: 'Înregistrează-te la această  campanie',
        messageOneCampaign: 'Aveți voie să vă înregistrați la o singură campanie din cele de mai jos',
        seeDetails: 'Vezi detalii',
        noPromotionsForThisProduct: 'Nu sunt promoții active pentru acest produs',
        successRegisterToCampaign: 'Participarea la campanie a avut loc cu succes',
        successRegisterToAllCampaigns: 'Participarea la toate campaniile a avut loc cu succes',
        pleaseScanTheCodeAgain: 'Vă rugăm să scanați codul încă o dată',
        userAlreadyRegisteredForACampaign: 'Utilizatorul este deja înregistrat într-o campanie',
        warningMultipleScan: 'Atenție, scanare multiplă! Acest cod a fost deja scanat',
        alreadyRegisteredForThisCampaign: 'V-ați înregistrat la această promoție',
        youWonAPrize: 'Felicitări!!! Tocmai ai câștigat un premiu: {{prizeName}}!',
        prizeMessage: 'Mai înscrie și alte coduri, poți câștiga un premiu!',
        termsAndConditionsMessage: 'Prin apăsarea butonului “Participă”, confirmi că ai citit și ești de acord cu',
        termsAndConditions: 'Termenii și Condițiile',
        errorDefault: 'A apărut o eroare. Vă rugăm să încercați din nou.',
    },
    vouchers: {
        activeVouchers: 'Vouchere active',
        allVouchers: 'Toate vouchere',
        applySuccess: 'Voucher-ul a fost aplicat cu succes',
        applyError: 'Ne pare rău, acest voucher a expirat',
        backButtonMainPage: 'Înapoi pe pagina principală',
    },
    campaigns: {
        activeCampaigns: 'Campanii active',
        allCampaigns: 'Toate campaniile',
        expiresIn: 'Expiră în',
        days: 'zile',
        expiresToday: 'Expiră astăzi',
        expired: 'Expirat',
    },
    discover: {
        seeAll: 'Vezi tot',
    },
    register: {
        title: 'Înregistrare',
        continue: 'Continuă',
        createAccount: 'Creează cont',
        firstName: 'Prenume',
        lastName: 'Nume',
        phone: 'Număr de telefon',
        placeholder: {
            password: 'Alege parola',
            firstName: 'Introduceți prenumele dumneavoastră',
            lastName: 'Introduceți numele dumneavoastră',
            phone: 'Introduceți numărul dumneavoastră de telefon',
        },
        atLeast6Characters: 'Cel puțin 6 caractere',
        agreeWith: 'Sunt de acord cu',
        termsAndConditions: 'termenii și condițiile',
        alreadyHaveAnAccount: 'Ai deja un cont?',
        logInHere: 'Loghează-te aici',
        headlineMessage: 'Pentru a participa la promoții, trebuie să te înregistrezi, mai întâi.',
        errorMsg: {
            firstNameRequired: 'Prenumele este obligatoriu',
            firstNameMaxlength: 'Prenumele este prea lung',
            lastNameRequired: 'Numele este obligatoriu',
            lastNameMaxlength: 'Numele este prea lung',
            passwordMinLength: 'Parola trebuie să aibă cel puțin 6 caractere',
            emailInvalid: 'Emailul este invalid',
        },
        toastMsg: {
            confirmEmail: 'Vă rugăm să vă confirmați emailul',
        },
        firstPage: {
            checks: [
                'Discover new campaigns',
                'Access your vouchers easily, on the go',
                'Keep track of your active campaigns',
                'See all your prizes',
            ],
            title: 'One Engage™️ account, unlimited vouchers and campaigns',
            subtitle:
                'With an Engage account you can easily keep track of your campaigns and discover more from your favorite brands.',
        },
    },
    setupAccount: {
        title: 'Confirmați emailul',
        sendSuccessfullyPleaseCheckEmail: 'Email-ul a fost retrimis. Vă rugăm să vă verificați emailul.',
        resendConfirmationEmail: 'Retrimite email de confirmare',
        failed: 'Email nu a fost confirmat, codul e invalid sau lipsește',
        emailConfirmed: 'Emailul a fost confirmat cu succes',
        pleaseWait: 'Vă rugăm așteptați',
    },
    login: {
        title: 'Logare',
        headlineMessage: 'Aici vă puteți introduce datele pentru a accesa aplicația.',
        dontHaveAccount: 'Nu ai un cont?',
        registerHere: 'Înregistrează-te aici.',
        forgotPassword: 'Ai uitat parola?',
        resendConfirmationEmail: 'Retrimite email de confirmare',
    },
    forgotPassword: {
        title: '',
        headlineMessage: 'Please enter your email.',
        sendEmail: 'Send email',
    },
    resetPassword: {
        title: 'Resetează parola',
        goToLogin: 'Spre pagina de logare',
        newPassword: 'Noua parolă',
        confirmPassword: 'Confirmați parola',
        errorMsg: {
            passwordPattern: 'Parolele nu se potrivesc',
        },
        toastMsg: {
            emailSent: 'Vă rugăm să verificați adresa de e-mail pentru instrucțiuni',
        },
    },
    scan: {
        title: 'Scanare',
        headlineMessage: 'Poziționează codul de bare sau matricea de date în cadrul pentru scanare',
        allowAccessToCamera: 'Permiti accesul la camera?',
        allowCameraForAppToFunction:
            'Pentru a putea folosi aplicatia e necesar sa permiti accesul la camera de fotografiat.',
    },
    home: {
        scan: 'Scanează',
    },
    errorMessages: {
        scannedProductNotFound: 'Produsul nu mai este disponibil.',
        brandOrProductNotFound: 'Produsul/ Brandul cerut nu a fost găsit.',
        brandNotFound: 'Brandul cerut nu a fost găsit.',
        errorCamera: 'A apărut o eroare la deschiderea camerei. Vă rugăm reîncărcați pagina',
        expiredSession: 'Sesiunea dumneavoastră a expirat',
    },
    toolbar: {
        discover: 'Descoperă',
        account: 'Cont',
        promotions: 'Promoții',
    },
    profile: {
        profile: 'Profilul meu',
        accountDetails: 'Detalii cont',
        help: 'Ajutor',
        legalInformation: 'Informații legale',
        logout: 'Deconectează-te',
        firstName: 'Prenume',
        lastName: 'Nume',
        email: 'Email',
        birthday: 'Data nașterii',
        changePassword: 'Schimbă parola',
        deleteAccount: 'Șterge contul',
        phone: 'Telefon',
    },
    account: {
        account: 'Cont',
        myReviews: 'Recenziile mele',
        message: 'Autentifică-te în aplicație pentru a avea acces la toate funcțiile.',
        accountSettings: 'Setări cont',
        changePhoto: 'Schimbă fotografia',
        edit: 'Editează',
        emailAndProfile: 'Email și profil',
        nameAndSurname: 'Nume și prenume',
        emailAddress: 'Adresa de email',
        phoneNumber: 'Număr de telefon',
        birthday: 'Data de naștere',
        password: 'Parolă',
        changePassword: 'Schimbă parola',
        notifications: 'Notificări',
        notificationsSettings: 'Setări notificări',
        others: 'Altele',
        privacyPolicy: 'Politica de confidențialitate',
        termsAndConditions: 'Termeni și condiții',
        logout: 'Delogare',
        fullNamePlaceholder: 'Niciun nume sau prenume',
        emailPlaceholder: 'Nicio adresă de email',
        phoneNumberPlaceholder: 'Niciun număr de telefon',
        birthdayPlaceholder: 'Nicio dată de naștere',
        changeName: 'Schimbă numele',
        changePhoneNumber: 'Schimbă numărul de telefon',
        changeBirthday: 'Schimbă data nașterii',
        save: 'Salvează',
        errorMsg: {
            birthdayInvalid: 'Data nașterii este invalidă',
            phoneNumberInvalid: 'Numărul de telefon e invalid',
            newPasswordRequired: 'Noua parolă este obligatorie',
            oldPasswordRequired: 'Vechea parolă este obligatorie',
            repeatPasswordRequired: 'Vă rugăm, să rescrieți noua parolă',
            newPasswordMinLength: 'Noua parolă trebuie să conțină cel puțin 6 caractere',
            pleaseConfirmNewPassword: 'Vă rugăm să confirmați noua parolă',
        },
        toastMsg: {
            accountEditedSuccessfully: 'Contul a fost schimbat cu success',
            passwordChangedSuccessfully: 'Parola a fost schimbată cu success',
        },
    },
    userCampaignVouchers: {
        availableUntil: 'Valabil pana la',
    },
    changePassword: {
        title: 'Schimbă parola',
        currentPassword: 'Parola curentă',
        newPassword: 'Parola nouă',
        firstStepMessage: 'Introdu parola curentă.',
        secondStepMessage: 'Introdu parola nouă. Aceasta trebuie să conțină cel puțin 6 caractere.',
    },
};
