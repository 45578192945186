import { AfterViewInit, Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ZXingScannerComponent } from '@zxing/ngx-scanner';
import { BarcodeFormat, Result } from '@zxing/library';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ProductService } from '../../shared/services/product.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastService } from '../../shared/services/toast.service';
import { validateEngageURL, validateURL } from '../../shared/utils/utils';
import { TranslateService } from '@ngx-translate/core';
import { DOCUMENT } from '@angular/common';
import { BehaviorSubject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@UntilDestroy()
@Component({
    selector: 'app-scan',
    templateUrl: './scan.component.html',
    styleUrls: ['./scan.component.scss'],
})
export class ScanComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild(ZXingScannerComponent) scanner: ZXingScannerComponent;
    error: any = null;
    formats: BarcodeFormat[] = [BarcodeFormat.EAN_13, BarcodeFormat.QR_CODE];
    hasDevices: boolean;
    hasPermission: boolean;
    qrResultSubject: BehaviorSubject<Result> = new BehaviorSubject<Result>(null);
    mediaStream: MediaStream;
    availableDevices: MediaDeviceInfo[];
    currentDevice: MediaDeviceInfo;
    settings: PhotoSettings;
    tabOpened = false;
    locale: string;

    // for image capture
    mediaStreamConstraints: MediaStreamConstraints = {
        video: {
            facingMode: ['back', 'rear', 'environment'],
            focusMode: 'continuous',
        },
    };
    noPhotos = 0;
    capabilities: PhotoCapabilities;
    imagePromise: Promise<any> = null;

    // imageCapture: ImageCapture; Doesn't work in iOS, raises an error

    constructor(
        private productService: ProductService,
        private router: Router,
        private route: ActivatedRoute,
        private toastService: ToastService,
        @Inject(DOCUMENT) private document: Document,
        private translateService: TranslateService
    ) {}

    ngOnInit(): void {
        this.locale = this.route.snapshot.queryParamMap.get('locale');
        this.qrResultSubject.pipe(debounceTime(100), untilDestroyed(this)).subscribe(async (qrResult: Result) => {
            if (!qrResult) {
                return;
            }
            switch (qrResult.getBarcodeFormat()) {
                case BarcodeFormat.EAN_13:
                    await this.routeToProductDetails(qrResult.getText().slice(0, qrResult.getText().length - 1));
                    break;
                case BarcodeFormat.QR_CODE:
                    // this.takePhoto().then();
                    await this.scanVisibleOrHiddenCode(qrResult.getText());
                    break;
                default:
                    console.log(`${qrResult.getBarcodeFormat()} is not supported`);
            }
        });
    }

    ngOnDestroy(): void {
        if (!this.mediaStream) {
            return;
        }
        const tracks = this.mediaStream.getTracks();
        for (const track of tracks) {
            track.stop();
        }
        const video = document.getElementsByTagName('video')[0] || null;
        if (video) {
            video.srcObject = null;
        }
    }

    ngAfterViewInit(): void {
        this.scanner.camerasFound.pipe(untilDestroyed(this)).subscribe(async (devices: MediaDeviceInfo[]) => {
            this.hasDevices = true;
            this.availableDevices = devices;
            for (const device of this.availableDevices) {
                if (/back|rear|environment/gi.test(device.label)) {
                    this.currentDevice = device;
                    break;
                }
            }

            // for image capture with the scanned code

            // if (ImageCapture) {
            //     if (navigator.mediaDevices?.getUserMedia) {
            //         this.mediaStream = await navigator.mediaDevices.getUserMedia(this.mediaStreamConstraints);
            //     } else {
            //         const getUserMedia = navigator.webkitGetUserMedia || navigator.mozGetUserMedia;
            //         await getUserMedia(this.mediaStreamConstraints, (stream) => {
            //             this.mediaStream = stream;
            //         }, (err) => {
            //             this.toastService.showError(err);
            //         });
            //     }
            // }
        });
        this.scanner.camerasNotFound.pipe(untilDestroyed(this)).subscribe(() => (this.hasDevices = false));
        this.scanner.permissionResponse
            .pipe(untilDestroyed(this))
            .subscribe((perm: boolean) => (this.hasPermission = perm));
    }

    async scanCompleteHandler(event): Promise<void> {
        if (!event) {
            return;
        }
        this.qrResultSubject.next(event);
    }

    async scanVisibleOrHiddenCode(textScanned: string): Promise<void> {
        if (validateEngageURL(textScanned)) {
            // engage URL
            await this.processData(async () => {
                const { redirectUrl, appRelativeUrl } = await this.productService.scanVisibleOrHiddenCode(textScanned);
                if (redirectUrl) {
                    this.document.location.href = redirectUrl;
                } else {
                    await this.router.navigate([appRelativeUrl], { queryParams: { locale: this.locale } });
                }
            }, textScanned);
        } else {
            if (validateURL(textScanned) && this.tabOpened === false) {
                this.tabOpened = true;
                window?.open(textScanned, '_blank')?.focus();
            } else {
                this.toastService.showInfo(textScanned);
            }
        }
    }

    async routeToProductDetails(retailCode: string): Promise<void> {
        try {
            if (retailCode) {
                const product = await this.productService.scanRetailCode(retailCode);
                await this.router.navigate([`${product.brand.alias}/${product._id}`], {
                    queryParams: { locale: this.locale },
                });
            }
        } catch (e) {
            if (e.code === 'NotFound' && e.status === 404) {
                this.toastService.showError(this.translateService.instant('errorMessages.scannedProductNotFound'));
            } else {
                this.toastService.showError(e);
            }
        }
    }

    // async takePhoto(): Promise<void> {
    //     if (!this.mediaStream) {
    //         return ;
    //     }
    //     if (ImageCapture) {
    //         const mediaStreamTrack = this.mediaStream.getVideoTracks()[0];
    //
    //         this.imageCapture = new ImageCapture(mediaStreamTrack);
    //         this.capabilities = await this.imageCapture.getPhotoCapabilities();
    //         this.settings = await this.imageCapture.getPhotoSettings();
    //
    //         const track = this.imageCapture.track;
    //         if (this.noPhotos < 2 && !this.imagePromise &&
    //             !(track.readyState !== 'live' || !track.enabled || track.muted)) {
    //
    //             const imagePromise = this.imageCapture.takePhoto({
    //                 imageWidth: this.capabilities.imageWidth.max,
    //                 imageHeight: this.capabilities.imageHeight.max
    //             }).then((blob) => {
    //                 this.downloadImage(blob);
    //             }).then(() => {
    //                 this.imagePromise = null;
    //                 this.noPhotos++;
    //             }).catch((err) => {
    //                 this.toastService.showError(err);
    //                 this.imagePromise = null;
    //             });
    //             this.imagePromise = imagePromise;
    //         }
    //     } else {
    //         const video = document.createElement('video');
    //         const canvas = document.createElement('canvas');
    //         const context = canvas.getContext('2d');
    //
    //         video.srcObject = this.mediaStream;
    //
    //         video.addEventListener('loadeddata', async () => {
    //             const {videoWidth, videoHeight} = video;
    //             canvas.width = videoWidth;
    //             canvas.height = videoHeight;
    //
    //             try {
    //                 await video.play();
    //                 context.drawImage(video, 0, 0, videoWidth, videoHeight);
    //                 canvas.toBlob((blob) => {
    //                     this.downloadImage(blob);
    //                 }, 'image/png');
    //             } catch (error) {
    //                 this.toastService.showError(error);
    //             }
    //         });
    //     }
    // }

    private downloadImage(blob: Blob): void {
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.setAttribute('href', url);
        link.setAttribute('download', `qr-code-${new Date().getTime().toString()}.jpg`);
        link.innerText = 'click';
        link.click();
    }

    displayError(error): void {
        console.error(error);
    }

    async processData(callback: () => Promise<any>, engageUrl: string): Promise<void> {
        try {
            await callback();
        } catch (e) {
            const { url, queryParams } = this.productService.handleScanError(e, engageUrl);
            if (url && queryParams) {
                await this.router.navigate([url], { queryParams });
            } else {
                this.error = e;
            }
        }
    }
}
