import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {ProductTemplate} from '../../models/product-template';

@Injectable({
    providedIn: 'root'
})
export class AdminProductTemplateService {
    endpoint: string;

    constructor(private http: HttpClient) {
        this.endpoint = `${environment.apiBaseUrlForAdmin}/product-templates`;
    }

    async getProductTemplate(id: string, authToken: string, brandId: string, locale: string): Promise<ProductTemplate> {
        const productTemplate = await this.http.get<ProductTemplate>(`${this.endpoint}/${id}`,
            {
                params: {
                    brandId,
                    locale
                },
                headers: new HttpHeaders({
                    Authorization: `Bearer ${authToken}`,
                    'X-Locale': locale
                })
            }).toPromise();
        return productTemplate;
    }
}
