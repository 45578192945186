<div class="row m-0 pb-3 card-feedback" *ngIf="card.cardType === CardType.feedback">
    <div class="col-12" *ngIf="isLoggedIn || isAdmin">
        <div class="row">{{card.feedback.question}}</div>
        <div class="row mt-3">
            <textarea class="w-100 textarea" *ngIf="!isAdmin" [(ngModel)]="card.feedback.answer"
                      placeholder="{{'product.typeYourAnswer' | translate}}"
                      [readOnly]="card.feedback.alreadyAnswered"
                      rows="4"></textarea>
            <textarea class="w-100 textarea" *ngIf="isAdmin"
                      placeholder="{{'product.typeYourAnswer' | translate}}"
                      [readOnly]="true"
                      rows="4"></textarea>
        </div>
        <div class="row mt-3 justify-content-center"
             *ngIf="!isAdmin && card.feedback.alreadyAnswered && card.feedback.uploadImage && card.feedback.imageUrl">
            <img class="feedback-image w-auto h-100" [src]="card.feedback.imageUrl"
                 alt="Feedback image">
        </div>
        <div class="row mt-3 justify-content-center position-relative"
             *ngIf="(!card.feedback.alreadyAnswered || isAdmin)&& card.feedback.uploadImage">
            <div class="col-12">
                <div class="row justify-content-center">
                    <img *ngIf="card.feedback.picture"
                         class="feedback-image w-auto h-100"
                         [src]="sanitizer.bypassSecurityTrustUrl(card.feedback.picture)"
                         alt="Feedback image">
                    <img src="assets/images/core/close_black.png"
                         class="feedback-image-close position-absolute"
                         alt="close" *ngIf="card.feedback.picture"
                         (click)="card.feedback.picture = null; card.feedback.file=null">
                </div>
                <div class="row justify-content-center">
                    <button type="button" class="white-btn px-3 py-1 mt-2 d-flex-center"
                            [disabled]="isAdmin || isLoading" (click)="fileInput.click()">
                        <img src="assets/images/preview/upload-loading-arrow.png">
                        {{'product.uploadImage' | translate}}
                        <input #fileInput type="file" accept="image/*"
                               style="display: none"
                               (change)="uploadFromFile($event)"/>
                    </button>
                </div>
            </div>
        </div>
        <div class="row mt-3 justify-content-center"
             *ngIf="(!card.feedback.alreadyAnswered || isAdmin)">
            <div class="col-12 d-flex-center">
                <button type="button" class="red-btn color-blue px-3 py-1"
                        (click)="submitAnswer(card)" [buttonSpinner]="isLoading"
                        [disabled]="isAdmin || (!isAdmin && (!card.feedback.answer  || card.feedback.answer === ''))">
                    {{'product.submitAnswer' | translate}}
                </button>
            </div>
        </div>
    </div>
    <div class="col-12" *ngIf="!isLoggedIn && !isAdmin">
        <div class="row">{{'product.pleaseLogInToSendYourMessage' | translate}}</div>
        <div class="row mt-3 justify-content-center">
            <button type="button" class="red-btn color-blue px-3 py-1"
                    routerLink="/login" [queryParams]="{returnUrl: router.url}">
                {{'common.login' | translate}}
            </button>
        </div>
    </div>
</div>