import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { UserPromotionVoucher, UserPromotionVoucherStatus } from '../../models/user-promotion-voucher';
import { CrudService } from './crud.service';

@Injectable({
    providedIn: 'root',
})
export class UserPromotionVoucherService extends CrudService<UserPromotionVoucher> {
    endpoint: string;

    constructor(http: HttpClient) {
        super(http, `${environment.apiBaseUrlForConsumer}/user-promotion-vouchers`);
    }

    async getUserPromotionVoucherById(userPromotionVoucherId: string): Promise<any | null> {
        return this.http.get(`${this.endpoint}/${userPromotionVoucherId}`).toPromise();
    }

    async getAll(status?: UserPromotionVoucherStatus): Promise<UserPromotionVoucher[]> {
        const vouchers = (
            await this.http
                .get<{ vouchers: UserPromotionVoucher[] }>(`${this.endpoint}`, {
                    params: { ...(status && { status }) },
                })
                .toPromise()
        ).vouchers;
        vouchers.map((voucher) => {
            voucher.isExpired = new Date(voucher?.endDate).getTime() < new Date().getTime();
        });

        vouchers.sort((a, b) => {
            if (a.isUsed === b.isUsed) {
                if (a.endDate < b.endDate) return 1;
                if (a.endDate > b.endDate) return -1;
                return 0;
            } else {
                return a.isUsed ? 1 : -1;
            }
        });
        return vouchers;
    }

    // async updatePromotionVoucher(userPromotionVoucherId: string, userPromotionVoucher: Partial<UserPromotionVoucher>) {
    //     return this.http.put(`${this.endpoint}/${userPromotionVoucherId}`, userPromotionVoucher).toPromise();
    // }
}
