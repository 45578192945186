import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import * as Sentry from '@sentry/angular-ivy';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PreviewProductComponent } from './components/preview-product/preview-product.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { ProductComponent } from './components/product/product.component';
import { ViewProductComponent } from './components/view-product/view-product.component';
import { SharedModule } from './shared/shared.module';
import { PromotionComponent } from './components/promotion/promotion.component';
import { TranslateModule } from '@ngx-translate/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { Locale } from './models/locale';
import { RegisterComponent } from './core/auth/register/register.component';
import { LoginComponent } from './core/auth/login/login.component';
import { Router } from '@angular/router';
import { QrScanComponent } from './components/qr-scan/qr-scan.component';
import { WebcamModule } from 'ngx-webcam';
import { ScanComponent } from './components/scan/scan.component';
import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { HomeComponent } from './components/home/home.component';
import { JwtInterceptor } from './shared/services/jwt.interceptor';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { QuillModule } from 'ngx-quill';
import { ForgotPasswordComponent } from './core/auth/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './core/auth/reset-password/reset-password.component';
import { SetupAccountComponent } from './core/auth/setup-account/setup-account.component';
import { PreviewProductTemplateComponent } from './components/preview-product-template/preview-product-template.component';
import { PromotionListComponent } from './components/promotion-list/promotion-list.component';
import { ViewPromotionComponent } from './components/view-promotion/view-promotion.component';
import { PreviewPromotionComponent } from './components/preview-promotion/preview-promotion.component';
import { CampaignPageComponent } from './components/campaign-page/campaign-page.component';
import { PrizeEntryListComponent } from './components/campaign-page/prize-entry-list/prize-entry-list.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { BrandPageComponent } from './components/brand-page/brand-page.component';
import { CampaignEntryListComponent } from './components/brand-page/campaign-entry-list/campaign-entry-list.component';
import { ProgressBarComponent } from './shared/components/progress-bar/progress-bar.component';
import { DiscoverComponent } from './components/discover/discover.component';
import { VoucherPageComponent } from './components/voucher-page/voucher-page.component';
import { DatePipe } from '@angular/common';
import { QRCodeModule } from 'angularx-qrcode';
import { ScanVoucherComponent } from './components/voucher-page/scan-voucher/scan-voucher.component';
import { VoucherSuccessComponent } from './components/voucher-page/scan-voucher/voucher-success/voucher-success.component';
import { VoucherErrorComponent } from './components/voucher-page/scan-voucher/voucher-error/voucher-error.component';
import { ActiveCampaignComponent } from './components/discover/active-campaign/active-campaign.component';
import { AllActiveCampaignsComponent } from './components/discover/all-active-campaigns/all-active-campaigns.component';
import { CampaignComponent } from './components/discover/campaign/campaign.component';
import { AllCampaignsComponent } from './components/discover/all-campaigns/all-campaigns.component';
import { VoucherComponent } from './components/discover/voucher/voucher.component';
import { AllVouchersComponent } from './components/discover/all-vouchers/all-vouchers.component';
import { MatButtonModule } from '@angular/material/button';
import { PrizesVerticalProgressBarComponent } from './components/campaign-page/prizes-vertical-progress-bar/prizes-vertical-progress-bar.component';

@NgModule({
    declarations: [
        AppComponent,
        PreviewProductComponent,
        PreviewPromotionComponent,
        PreviewProductTemplateComponent,
        ProductComponent,
        ViewProductComponent,
        ViewPromotionComponent,
        PromotionComponent,
        RegisterComponent,
        LoginComponent,
        QrScanComponent,
        ScanComponent,
        HomeComponent,
        ForgotPasswordComponent,
        ResetPasswordComponent,
        SetupAccountComponent,
        PromotionListComponent,
        CampaignPageComponent,
        PrizeEntryListComponent,
        BrandPageComponent,
        CampaignEntryListComponent,
        ProgressBarComponent,
        DiscoverComponent,
        VoucherPageComponent,
        ScanVoucherComponent,
        VoucherSuccessComponent,
        VoucherErrorComponent,
        ActiveCampaignComponent,
        AllActiveCampaignsComponent,
        CampaignComponent,
        AllCampaignsComponent,
        VoucherComponent,
        AllVouchersComponent,
        PrizesVerticalProgressBarComponent,
    ],
    imports: [
        AppRoutingModule,
        CommonModule,
        BrowserAnimationsModule,
        BrowserModule,
        SharedModule,
        WebcamModule,
        MatButtonModule,
        ModalModule.forRoot(),
        QuillModule.forRoot(),
        TranslateModule.forRoot({
            defaultLanguage: Locale.EN,
            useDefaultLang: true,
        }),
        ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
        ZXingScannerModule,
        QRCodeModule,
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: JwtInterceptor,
            multi: true,
        },
        {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler({
                showDialog: environment.sentry.showDialog,
            }),
        },
        {
            provide: Sentry.TraceService,
            deps: [Router],
        },
        {
            provide: APP_INITIALIZER,
            useFactory: () => () => {},
            deps: [Sentry.TraceService],
            multi: true,
        },
        DatePipe,
    ],
    exports: [],
    bootstrap: [AppComponent],
})
export class AppModule {}
