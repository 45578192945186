<div class="p-0 mb-3">
    <div class="border mx-3 rounded-3 bg-white">
        <div class="max-width500 m-3 d-flex position-relative bg-white prize">
            <img class="prize-image" src="{{ prize.imageUrl }}" />
            <ng-container *ngIf="prize.prizeStatus === PrizeStatus.completed">
                <div class="image-shadow"></div>
                <img class="image-check" src="assets/images/voucher/voucher-check.svg">
            </ng-container>
            <div class="d-flex flex-column ps-3 pt-2">
                <div class="points mt-1 pe-3">{{ prize.requiredPoints }} puncte</div>
                <div class="prize-title pe-3">
                    {{ prize.name }}
                </div>
            </div>
        </div>
        <app-progress-bar [progress]="currentPoints / prize.requiredPoints"></app-progress-bar>
        <div class="ms-3 completed-points">
            {{ currentPoints > prize.requiredPoints ? prize.requiredPoints : currentPoints }}/{{
                prize.requiredPoints
            }}
            puncte
        </div>
    </div>
</div>
