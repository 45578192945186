<div class="p-0 mt-3">
    <div class="max-width500 d-flex rounded-3 bg-white campaign">
        <img class="campaign-image" src="{{campaign.imageCoverUrl}}">
        <div class="d-flex flex-column ps-3 pt-2">
            <div class="brand-name mt-1 pe-3">{{ campaign.brand.name }}</div>
            <div class="campaign-title pe-3">
                {{campaign.title}}
            </div>
            <div class="truncate date-interval mt-1 mb-2 pe-3">
                {{campaign.startDate | date}} - {{campaign.endDate | date}}
            </div>
        </div>
    </div>
</div>

