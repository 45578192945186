<section id="scanner-section" *ngIf="!error">
    <div class="container p-0">
        <div class="row m-0 justify-content-center">
            <div class="col-12 col-md-5 col-lg-4 p-0 background-grey">
                <div class="row m-0 mt-4 mb-3 position-relative header">
                    <div class="col-3 text-center">
                        <img class="cursor-pointer" src="assets/images/scan/white_close.svg"
                             alt="Close" appBackButton>
                    </div>
                    <div class="col-6 text-left">
                        {{'scan.title' | translate}}
                    </div>
                    <div class="col-3 text-center">
                        <a>
                            <img class="cursor-pointer" src="assets/images/scan/white_info.png" alt="info">
                        </a>
                    </div>
                </div>

                <div class="row m-0 mt-4 mb-3 position-relative justify-content-center headline">
                    <div class="col-11 text-center">
                        {{'scan.headlineMessage' | translate}}
                    </div>
                </div>

                <div class="row w-100 m-0 position-absolute camera">
                    <div class="col-12 p-0">
                        <zxing-scanner class="vh-100" #scanner [(device)]="currentDevice"
                                       previewFitMode="cover"
                                       [autofocusEnabled]="true" enable="true" [tryHarder]="true"
                                       [formats]="formats"
                                       (scanError)="displayError($event)"
                                       (scanComplete)="scanCompleteHandler($event)" delayBetweenScanSuccess="2000"
                        ></zxing-scanner>
                        <div id="crop-area"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<app-alert-error [error]="error" [routeToHomepage]="true" [showAppLogo]="true"></app-alert-error>
