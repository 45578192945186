import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {filter} from 'rxjs/operators';
import {Locale} from '../../models/locale';
import storageAvailable from 'storage-available';

const ALL_LANGUAGES = [
    {code: Locale.EN, icon: './assets/flags/en.png'},
    {code: Locale.RO, icon: './assets/flags/ro.png'}
];

@Injectable({
    providedIn: 'root'
})
export class LanguageService {
    private languages: { code: string, icon: string }[] = [];
    private currentLanguageSubject: BehaviorSubject<string>;
    public currentLanguage$: Observable<string>;
    private readonly localStorage = null;

    constructor() {
        try {
            this.localStorage = storageAvailable('localStorage') ? localStorage : null;
            const lastLanguage = this.localStorage?.getItem('currentLanguage') || null;
            this.currentLanguageSubject = new BehaviorSubject(lastLanguage || Locale.EN);
            this.currentLanguage$ = this.currentLanguageSubject.asObservable().pipe(filter(lang => !!lang));
            this.setAvailableLanguages();
        } catch (e) {
            console.log(e);
        }
    }

    setAvailableLanguages(): void {
        let currentLanguage = this.localStorage?.getItem('currentLanguage') || null;
        if (!currentLanguage) {
            currentLanguage = Locale.EN;
        }
        this.languages = currentLanguage
            ? ALL_LANGUAGES.filter(lang => lang.code === currentLanguage)
            : ALL_LANGUAGES;

        if (this.currentLanguage && !this.languages.some(lang => lang.code === this.currentLanguage)) {
            // Last language is not valid for this user, set as first available
            this.currentLanguage = this.languages[0].code;
        } else {
            this.localStorage?.setItem('currentLanguage', this.currentLanguage);
        }
    }

    get availableLanguages(): { code: string, icon: string }[] {
        return this.languages;
    }

    get currentLanguage(): string {
        return this.currentLanguageSubject?.getValue();
    }

    set currentLanguage(language: string) {
        this.currentLanguageSubject.next(language);
        this.localStorage?.setItem('currentLanguage', language);
    }

    clearCurrentLanguage(): void {
        this.localStorage?.removeItem('currentLanguage');
    }
}
