import { Injectable } from '@angular/core';
import { CodeType, ImageCarousel, Product, ProductResponse } from '../../models/product';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { RedirectUrl } from '../../models/redirect-url';
import { ProductTemplateType } from '../../models/product-template';
import { getBrandAliasFromEngageURL, getScanCodeDetailsFromUrl } from '../utils/utils';
import { TranslateService } from '@ngx-translate/core';
import { SessionService } from './session.service';
import { ToastService } from './toast.service';
import { Brand, BrandResponse } from '../../models/brand';

@Injectable({
    providedIn: 'root',
})
export class BrandService {
    endpoint: string;

    constructor(
        private http: HttpClient,
        private sessionService: SessionService,
        private toastService: ToastService,
        private translateService: TranslateService
    ) {
        this.endpoint = `${environment.apiBaseUrlForConsumer}/brands`;
    }

    async getOne(id: string): Promise<Brand> {
        const productRes = await this.http.get<BrandResponse>(`${this.endpoint}/${id}`).toPromise();
        return productRes.brand;
    }

    async scanVisibleOrHiddenCode(url: string): Promise<{ brand: Brand; type: CodeType }> {
        const { brandAlias, code, visibility } = getScanCodeDetailsFromUrl(url);
        const params: any = visibility === CodeType.visibleCode ? { id: code } : { a: code };
        const result = await this.http
            .get<BrandResponse>(`${environment.apiProductScanner}/scan-codes/brands/${brandAlias}`, { params })
            .toPromise();
        if ((result as BrandResponse).brand && (result as BrandResponse).type === CodeType.hiddenCode) {
            this.sessionService.saveScanBrandCodeDetails({ brandAlias, scannedCode: code });
        }
        return result;
    }
}
