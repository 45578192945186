<section class="full-height background-grey">
    <div class="col justify-content-center main-container container p-0 ">
        <div class="row justify-content-center">
            <div class="bg-white z-index-99 top-bar flex-column text-center">
                <img class="left-arrow cursor-pointer" src="assets/icons/left-arrow.svg" appBackButton />
                <span class="title">{{ "campaigns.activeCampaigns" | translate }}</span>
            </div>
        </div>
        <div class="row justify-content-center ps-0 max-width500 m-auto with-topbar">
            <ng-container *ngFor="let registration of activeCampaignRegistrations">
                <app-active-campaign class="clickable ps-0" routerLink="/campaignPage/{{registration.promotion._id}}" [campaign]="registration.promotion"></app-active-campaign>
            </ng-container>
        </div>
    </div>
</section>
