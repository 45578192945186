import {Component, Input, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {defaultSteps, Promotion, PromotionCard, PromotionCardType, Step} from '../../models/promotion';
import {PromotionService} from '../../shared/services/promotion.service';
import {TranslateService} from '@ngx-translate/core';
import {AuthService} from '../../shared/services/auth.service';
import {ToastService} from '../../shared/services/toast.service';
import {SessionService} from '../../shared/services/session.service';

@Component({
    selector: 'app-promotion',
    templateUrl: './promotion.component.html',
    styleUrls: ['./promotion.component.scss']
})
export class PromotionComponent implements OnInit {
    @Input() promotion: Promotion = null;
    @Input() isAdmin = false;
    PromotionCardType = PromotionCardType;
    steps: Step[] = defaultSteps.map(step => ({...step, text: this.translateService.instant(`common.${step.text}`)}));
    isLoading = false;
    error: any;

    constructor(private router: Router,
                private route: ActivatedRoute,
                private authService: AuthService,
                private toastService: ToastService,
                private sessionService: SessionService,
                private promotionService: PromotionService,
                private translateService: TranslateService) {
    }

    ngOnInit(): void {
    }

    async participate(): Promise<void> {
        await this.processData(async () => {
            if (!this.sessionService.token) {
                await this.router.navigate(['/login'], {queryParams: {returnUrl: this.router.url}});
                return;
            }
            const scanDetails = this.sessionService.scanCodeDetails;
            if (!scanDetails) {
                this.toastService.showError(this.translateService.instant('promotions.pleaseScanTheCodeAgain'));
                return;
            }
            const promotionRegistration = await this.promotionService.registerPromotion(this.promotion._id, scanDetails);
            this.toastService.showSuccess(this.translateService.instant('promotions.successRegisterToCampaign'));
            if (promotionRegistration.prizeWon) {
                const message = promotionRegistration.promotion?.previewWinningMessage ||
                    this.translateService.instant('promotions.youWonAPrize', {prizeName: promotionRegistration.prizeWon.name});
                this.toastService.showSuccess(message);
            } else {
                const message = promotionRegistration.promotion?.previewPrizeMessage ||
                    this.translateService.instant('promotions.prizeMessage');
                this.toastService.showInfo(message);
            }
            this.promotion.disableButton = true;
            this.sessionService.deleteScanProductCodeDetails();
        });
    }

    async playPauseVideo(card: PromotionCard, cardIndex: number): Promise<void> {
        const video = document?.getElementById(`video${cardIndex}`) as HTMLVideoElement;
        if (!video) {
            return;
        }
        const videoOverlayDiv = document?.getElementById(`overlay${cardIndex}`) as HTMLDivElement;
        if (!videoOverlayDiv) {
            return;
        }
        card.multimedia.playVideo = !card.multimedia.playVideo;
        if (card.multimedia.playVideo) {
            videoOverlayDiv.style.opacity = '0.0';
            await video.play();
        } else {
            videoOverlayDiv.style.opacity = '0.5';
            video.pause();
        }
    }

    async processData(callback: () => Promise<any>): Promise<void> {
        try {
            this.error = null;
            this.isLoading = true;
            await callback();
        } catch (e) {
            this.error = e;
            switch (e?.error?.message) {
                case 'Fields scanLocationId should be unique':
                    const errMsgScanAgain = this.translateService.instant('promotions.pleaseScanTheCodeAgain');
                    this.error.error.message = errMsgScanAgain;
                    this.toastService.showError(errMsgScanAgain);
                    break;
                case 'Fields productCodeId should be unique':
                    const errMsgMultipleScans = this.translateService.instant('promotions.warningMultipleScan');
                    this.error.error.message = errMsgMultipleScans;
                    this.toastService.showError(errMsgMultipleScans);
                    break;
            }
        } finally {
            this.isLoading = false;
        }
    }

}
