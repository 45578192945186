import {Component, OnInit} from '@angular/core';
import {ProductService} from '../../shared/services/product.service';
import {CodeVisibility, ImageCarousel, Product} from '../../models/product';
import {ActivatedRoute} from '@angular/router';
import {environment} from '../../../environments/environment';
import {TranslateService} from '@ngx-translate/core';
import {defaultLocale, Locale} from '../../models/locale';
import {AdminProductService} from '../../shared/services/admin-product.service';

@Component({
    selector: 'app-preview-product',
    templateUrl: './preview-product.component.html',
    styleUrls: ['./preview-product.component.scss'],
})
export class PreviewProductComponent implements OnInit {
    id: string;
    product: Product;
    similarProducts: ImageCarousel[];
    interestedInProducts: ImageCarousel[];
    error: any;
    isLoading = false;

    constructor(private adminProductService: AdminProductService,
                private productService: ProductService,
                private route: ActivatedRoute,
                private translateService: TranslateService) {
    }

    ngOnInit(): void {
        this.id = this.route.snapshot.paramMap.get('productId') || environment.productId;
        const {authToken, brandId, visibility} = this.route.snapshot.queryParams;
        const locale = this.route.snapshot.queryParams.locale as Locale || defaultLocale;
        this.translateService.use(locale);
        this.loadData(authToken, brandId, locale, visibility).then();
    }

    private async loadData(authToken: string, brandId: string, locale: string, visibility: CodeVisibility): Promise<void> {
        try {
            this.error = null;
            this.isLoading = true;
            this.product = await this.adminProductService.getProduct(this.id, authToken, brandId, locale, visibility);
            this.similarProducts = await this.productService.getSimilarProducts(this.product);
            this.interestedInProducts = await this.productService.getInterestedInProducts(this.product);
        } catch (e) {
            this.error = e;
            this.error.error.message = this.translateService.instant('errorMessages.expiredSession');
        } finally {
            this.isLoading = false;
        }
    }
}
