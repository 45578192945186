<section id="campaign-page" class="background-grey pb-4">
    <div class="col justify-content-center container p-0 overflow-hidden">
        <div class="row justify-content-center">
            <div class="bg-white top-bar flex-column text-center">
                <img class="left-arrow cursor-pointer" src="assets/icons/left-arrow.svg" appBackButton />
                <span class="title">{{ "promotions.pageTitle" | translate }}</span>
            </div>
        </div>
        <div class="row justify-content-center mt-5">
            <div class="max-width500 main-container overflow-auto">
                <div class="row justify-content-center">
                    <img class="brand-logo mt-4" src="assets/images/cuptorul-moldovencei-profile.png" />
                </div>
                <div class="brand-name pt-3">{{ promotion?.brand.name }}</div>
                <div class="row justify-content-center text-center">
                    <div class="campaign-title pt-4">{{ promotion?.title }}</div>
                </div>
                <div class="row justify-content-center text-center">
                    <div class="date mt-2">
                        {{ promotion?.startDate | date : "d MMM y" }} - {{ promotion?.endDate | date : "d MMM y" }}
                    </div>
                </div>
                <div class="row justify-content-center">
                    <div class="swipe">
                        <ng-container *ngFor="let card of promotion?.cards">
                            <ng-container *ngIf="card.cardType === PromotionCardType.carousel">
                                <ng-container *ngFor="let image of card.carousel.images">
                                    <img class="carrousel-image" [src]="image.imageUrl" />
                                </ng-container>
                            </ng-container>
                        </ng-container>
                    </div>
                </div>
                <div class="ps-3 description">{{ promotion?.description }}</div>
                <ng-container *ngIf="promotion?.prizes && promotion?.prizes.length">
                    <div class="prizes-title mt-4 ps-3 mb-3">{{ "promotions.prizes" | translate }}</div>
                    <app-prizes-vertical-progress-bar [promotion]="promotion" [currentPoints]="currentPoints"></app-prizes-vertical-progress-bar>
                </ng-container>
                <ng-container *ngFor="let card of promotion?.cards">
                    <ng-container *ngIf="card.cardType === PromotionCardType.multimedia">
                        <div class="position-relative mt-4 row justify-content-center">
                            <img class="thumbnail p-0" src="assets/images/Banner.png" />
                            <button class="play-button p-2" (click)="openModal(template)">
                                <img src="assets/images/play-button.svg" />
                            </button>
                        </div>
                        <div class="row justify-content-center">
                            <ng-container *ngIf="card.title">
                                <div class="bg-white video-description p-3 mx-3">
                                    {{ card.title }}
                                </div>
                            </ng-container>
                        </div>
                        <ng-template #template>
                            <div class="modal-body">
                                <video id="myVideo" width="100%" controls autoplay>
                                    <source [src]="card.multimedia.videoUrl" type="video/mp4" />
                                </video>
                            </div>
                        </ng-template>
                    </ng-container>
                </ng-container>
            </div>
            <div class="row justify-content-center" *ngIf="!participated">
                <div class="max-width500 bottom-container bg-white row justify-content-center">
                    <button class="pink-button mt-4" (click)="participate()">
                        {{ "promotions.participate" | translate }}
                    </button>
                    <div class="w-auto mt-3 terms-cond mb-3">
                        {{ "promotions.termsAndConditionsMessage" | translate }}
                        <a href="https://engage.link/">{{ "promotions.termsAndConditions" | translate }}</a> .
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
