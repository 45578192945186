import { Component, OnInit } from '@angular/core';
import { ToastService } from '../../../shared/services/toast.service';
import { UserPromotionVoucherService } from '../../../shared/services/user-promotion-voucher.service';

@Component({
    selector: 'app-all-vouchers',
    templateUrl: './all-vouchers.component.html',
    styleUrls: ['./all-vouchers.component.scss'],
})
export class AllVouchersComponent implements OnInit {
    allVouchers = [];

    constructor(private userPromotionVoucherService: UserPromotionVoucherService, private toastService: ToastService) {}

    async ngOnInit() {
        try {
            this.allVouchers = await this.userPromotionVoucherService.getAll();
        } catch (error) {
            this.toastService.showError('Error loading vouchers');
        }
    }
}
