<section id="home-section" class="flex-column background-grey justify-content-center row">
    <div class="col justify-content-center container p-0">
        <div class="row justify-content-center">
            <div class="bg-white top-bar flex-column text-center title pt-3">
            {{brandName}}
            </div>
        </div>
        <div class="main-container d-flex-column-between">
            <div>
                <div class="row mx-auto justify-content-center">
                    <img class="image" src="assets/images/voucher/check-success.svg">
                </div>
                <div class="row justify-content-center mt-4">
                    <div class="message">
                        {{"vouchers.applySuccess" | translate}}
                    </div>
                </div>
            </div>

            <div class="row justify-content-center mb-5">
                <button routerLink="" (click)="clearLocalStorage()" class="pink-button">{{"vouchers.backButtonMainPage" | translate}}</button>
            </div>
        </div>
    </div>
</section>
