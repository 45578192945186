<div class="row main-container m-0 justify-content-center h-100 overflow-auto" *ngIf="brand">
    <div class="col margin-bottom">
        <div class="row justify-content-center mt-5">
            <img class="max-width500 p-0" src="{{ brand.heroImageUrl }}" />
        </div>
        <div class="row justify-content-center">
            <div class="max-width500">
                <img class="mx-3 profile" src="{{ brand.logoUrl }}" />
            </div>
        </div>
        <div class="row justify-content-center p-0">
            <div class="title mt-3 width500 ps-3">
                {{ brand.name }}
            </div>
        </div>
        <div class="row justify-content-center p-0">
            <div class="width500 location ps-3">
                {{ brand.location }}
            </div>
            <div class="row justify-content-center p-0">
                <div class="width500 message mb-4 mt-3 ps-3">
                    {{ brand.description }}
                </div>
            </div>
            <div class="d-flex flex-column row align-items-center p-0 overflow-auto">
                <ng-container *ngFor="let campaign of brand.promotions">
                    <app-campaign-entry-list
                        routerLink="/campaignPage/{{ campaign._id }}"
                        [campaign]="campaign"
                        class="p-0 max-width500 cursor-pointer"
                    ></app-campaign-entry-list>
                </ng-container>
            </div>
        </div>
    </div>
    <div class="row justify-content-center mt-3">
        <div class="max-width500 bottom-container bg-white row justify-content-center">
            <button class="pink-button mt-3" (click)="participateAll()">Participă la toate</button>
            <div routerLink="/all-campaigns" class="w-auto mt-3 all-promo mb-3 clickable">Descoperă toate promoțiile</div>
        </div>
    </div>
</div>
