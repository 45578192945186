import {Component, Input, OnInit} from '@angular/core';
import {ImageCarousel} from '../../../models/product';
import {ActivatedRoute, Router} from '@angular/router';
import {environment} from '../../../../environments/environment';

@Component({
    selector: 'app-carousel',
    templateUrl: './carousel.component.html',
    styleUrls: ['./carousel.component.scss']
})
export class CarouselComponent implements OnInit {
    fromProductId: string;
    @Input() disableRouting = true;
    @Input() carouselImages: ImageCarousel[] = [];

    constructor(private router: Router,
                private route: ActivatedRoute) {
    }

    ngOnInit(): void {
        this.fromProductId = this.getCurrentProductId();
    }

    async navigateToProduct(image: ImageCarousel): Promise<void> {
        if (this.disableRouting) return;
        await this.router.navigate([image.routing], {queryParams: {from: this.fromProductId}});
    }

    private getCurrentProductId(): string {
        return this.route.snapshot.paramMap.get('productId') || environment.productId;
    }
}
