import { Injectable } from '@angular/core';
import { CrudService } from './crud.service';
import { HttpClient } from '@angular/common/http';
import { Promotion, PromotionRegistration } from '../../models/promotion';
import { environment } from '../../../environments/environment';
import { LoyaltyPoints } from '../../models/loyalty-points';

@Injectable({
    providedIn: 'root',
})
export class CampaignService extends CrudService<Promotion> {
    constructor(http: HttpClient) {
        super(http, 'promotions');
    }

    async registerPromotion(
        promotionId: string,
        scannedCode: string,
        brandAlias: string
    ): Promise<{ promotionRegistration: PromotionRegistration; loyaltyPoints: LoyaltyPoints }> {
        const resp = await this.http
            .post<{ promotionRegistration: PromotionRegistration; loyaltyPoints: LoyaltyPoints }>(
                `${environment.apiBaseUrlForConsumer}/promotion-registration/campaign`,
                { promotionId, scannedCode, brandAlias }
            )
            .toPromise();
        return resp;
    }

    async registerToAllCampaignsOfBrand(brandAlias: string, scannedCode: string): Promise<void> {
        await this.http
            .post(`${environment.apiBaseUrlForConsumer}/promotion-registration/all-campaigns`, {
                brandAlias,
                scannedCode,
            })
            .toPromise();
    }
}
