import {Injectable} from '@angular/core';
import {CodeVisibility, Product} from '../../models/product';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class AdminProductService {
    endpoint: string;

    constructor(private http: HttpClient) {
        this.endpoint = `${environment.apiBaseUrlForAdmin}/products`;
    }

    async getProduct(id: string, authToken: string, brandId: string, locale: string, visibility: CodeVisibility): Promise<Product> {
        const product = await this.http.get<Product>(`${this.endpoint}/${id}`,
            {
                headers: new HttpHeaders({
                    Authorization: `Bearer ${authToken}`,
                    'X-Locale': locale
                }),
                params: {brandId, visibility}
            }).toPromise();
        if (product.reviews) {
            product.reviews.rating = product.reviews.count !== 0 ? product.reviews.totalStars / product.reviews.count : 0;
        } else {
            product.reviews = {rating: 0, count: 0, totalStars: 0};
        }
        return product;
    }
}
