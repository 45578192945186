import { Component, OnInit } from '@angular/core';
import { User } from '../../models/user';
import { AuthService } from '../../shared/services/auth.service';
import { UserPromotionVoucher } from '../../models/user-promotion-voucher';
import { UserPromotionVoucherService } from '../../shared/services/user-promotion-voucher.service';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../environments/environment';

@Component({
    selector: 'app-voucher-page',
    templateUrl: './voucher-page.component.html',
    styleUrls: ['./voucher-page.component.scss'],
})
export class VoucherPageComponent implements OnInit {
    currentUser: User = null;
    voucherId: string;
    voucher: UserPromotionVoucher = null;
    error: any;
    isLoading = false;
    voucherUrl: string;

    constructor(
        public authService: AuthService,
        private userPromotionVoucherService: UserPromotionVoucherService,
        private route: ActivatedRoute
    ) {}

    async ngOnInit(): Promise<void> {
        this.currentUser = this.authService.user || null;
        await this.loadVoucherData();
        this.voucherUrl = `${environment.pwaUrl}/v/${this.voucherId}`;
    }

    private async loadVoucherData(): Promise<void> {
        try {
            this.isLoading = true;
            this.voucherId = this.route.snapshot.paramMap.get('voucherId');
            const result = await this.userPromotionVoucherService.getUserPromotionVoucherById(this.voucherId);
            this.voucher = result.userPromotionVoucher;
        } catch (e) {
            this.error = e;
        } finally {
            this.isLoading = false;
        }
    }
}
