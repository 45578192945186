<section class="full-height background-grey">
    <div class="col justify-content-center main-container container p-0 ">
        <div class="row justify-content-center">
            <div class="bg-white z-index-99 top-bar flex-column text-center">
                <img class="left-arrow cursor-pointer" src="assets/icons/left-arrow.svg" appBackButton />
                <span class="title">{{"vouchers.allVouchers" | translate}}</span>
            </div>
        </div>
        <div class="row justify-content-center ps-0 max-width500 mx-auto mb-5 with-topbar">
            <ng-container *ngFor="let voucher of allVouchers">
                <app-voucher class="clickable ps-0" routerLink="/voucher/{{voucher._id}}" [voucher]="voucher"></app-voucher>
            </ng-container>
        </div>
    </div>
</section>

