import {Injectable} from '@angular/core';
import {CodeType, ImageCarousel, Product, ProductResponse} from '../../models/product';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {RedirectUrl} from '../../models/redirect-url';
import {ProductTemplateType} from '../../models/product-template';
import {getBrandAliasFromEngageURL, getScanCodeDetailsFromUrl} from '../utils/utils';
import {TranslateService} from '@ngx-translate/core';
import {SessionService} from './session.service';
import {ToastService} from './toast.service';

@Injectable({
    providedIn: 'root'
})
export class ProductService {
    endpoint: string;

    constructor(private http: HttpClient,
                private sessionService: SessionService,
                private toastService: ToastService,
                private translateService: TranslateService) {
        this.endpoint = `${environment.apiBaseUrlForConsumer}/products`;
    }

    async getOne(id: string, locale?: string): Promise<Product> {

        const productRes = await this.http.get<ProductResponse>(`${this.endpoint}/${id}`, {
            params: {...locale && {locale}}
        }).toPromise();
        const product = productRes.product;
        if (product.reviews) {
            product.reviews.rating = product.reviews.count !== 0 ? product.reviews.totalStars / product.reviews.count : 0;
        } else {
            product.reviews = {rating: 0, count: 0, totalStars: 0};
        }
        return product;
    }

    async scanRetailCode(retailCode: string): Promise<Product> {
        const {product} = await this.http.get<ProductResponse>(`${environment.apiProductScanner}/products/retail-code/${retailCode}`).toPromise();
        return product;
    }

    async scanVisibleOrHiddenCode(url: string): Promise<{ redirectUrl: string, appRelativeUrl: string, product?: Product, type: CodeType }> {
        const {brandAlias, code, visibility} = getScanCodeDetailsFromUrl(url);
        const params: any = visibility === CodeType.visibleCode ? {id: code} : {a: code};
        let result = await this.http.get<ProductResponse | RedirectUrl>(`${environment.apiProductScanner}/scan-codes/${brandAlias}`, {params}).toPromise();
        if ((result as ProductResponse).product && (result as ProductResponse).type === CodeType.hiddenCode) {
            this.sessionService.saveScanProductCodeDetails({brandAlias, scannedCode: code});
        }
        this.handleLoyaltyPointsInformation(result);
        const {redirectUrl} = result as RedirectUrl;
        let appRelativeUrl = null, product = null, type = null;
        if (!redirectUrl) {
            result = result as ProductResponse;
            product = result.product;
            type = result.type;
            if ((product && type === CodeType.visibleCode) || (product.promotions.length === 0)) {
                // route to product-details page for visible codes or if there aren't any active promotions
                appRelativeUrl = `${product.brand.alias}/${product._id}`;
            } else if (product.promotions.length === 1) {
                // 1 active promotion route to promotion-details
                appRelativeUrl = `promotions/${product.promotions[0]._id}`;
            } else {
                // more than 1 active promotions route to page with multiple promotions
                appRelativeUrl = `promotion-list/${product._id}`;
            }
        }
        return {redirectUrl, appRelativeUrl, product, type};
    }

    private handleLoyaltyPointsInformation(result: ProductResponse | RedirectUrl): void {
        const {successfullyAddedLoyaltyPointsCount, alreadyAddedLoyaltyPoints} = result;
        if (alreadyAddedLoyaltyPoints) {
            this.toastService.showWarning(this.translateService.instant('common.toastMsg.alreadyAddedLoyaltyPoints'));
        }
        if (successfullyAddedLoyaltyPointsCount > 0 && !alreadyAddedLoyaltyPoints) {
            this.toastService.showSuccess(this.translateService.instant('common.toastMsg.addedSuccessfullyLoyaltyPoints',
                {loyaltyPointsCount: successfullyAddedLoyaltyPointsCount}));
        }
    }

    async getSimilarProducts(product: Product): Promise<ImageCarousel[]> {
        const productsRes = await this.http.get<{ products: Product[] }>
        (`${this.endpoint}/similar-products`,
            {params: {productId: product._id, categoryId: product.categoryId, brandId: product.brandId}}).toPromise();
        return this.mapProductstoImageCarousel(productsRes.products);
    }

    async getInterestedInProducts(product: Product): Promise<ImageCarousel[]> {
        const productsRes = await this.http.get<{ products: Product[] }>
        (`${this.endpoint}/interested-in-products`, {
            params: {
                categoryId: product.categoryId,
                brandId: product.brandId
            }
        }).toPromise();
        return this.mapProductstoImageCarousel(productsRes.products);
    }

    public handleScanError(error: any, engageUrl: string): { url: string, queryParams: { type: ProductTemplateType } } {
        const brandAlias = getBrandAliasFromEngageURL(engageUrl);
        let type = null;
        switch (error.error.code) {
            case 'CodeRetired':
                type = ProductTemplateType.codeRetired;
                break;
            case 'NotFound':
                if (error.error.message.indexOf('Code') >= 0) {
                    type = ProductTemplateType.codeNotFound;
                } else if (error.error.message.indexOf('Product') >= 0) {
                    error.error.message = this.translateService.instant('errorMessages.scannedProductNotFound');
                }
                break;
            case 'MultipleScans':
                type = ProductTemplateType.multipleScans;
                break;
        }
        return {url: type ? `/invalid-code/${brandAlias}` : null, queryParams: type ? {type} : null};
    }

    private mapProductstoImageCarousel(products: Product[]): ImageCarousel[] {
        return products.map((prod) => {
            return {
                title: prod.title,
                image: prod.image,
                imageUrl: prod.imageUrl,
                routing: `/${prod.brand?.alias}/${prod._id}`
            };
        });
    }
}
