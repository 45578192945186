import { Component, Input, OnInit } from '@angular/core';
import { Promotion, PromotionCardType } from '../../../models/promotion';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-active-campaign',
    templateUrl: './active-campaign.component.html',
    styleUrls: ['./active-campaign.component.scss'],
})
export class ActiveCampaignComponent implements OnInit {
    @Input() campaign: Promotion;
    PromotionCardType = PromotionCardType;
    expirationMessage: string;

    constructor(private translateService: TranslateService) {}

    ngOnInit() {
        this.expirationMessage = this.getExpirationMessage(this.campaign?.endDate);
    }

    getExpirationMessage(endDateString: any): string {
        const endDate = new Date(endDateString);
        const currentDate = new Date();
        const remainingTime = endDate.getTime() - currentDate.getTime();
        const remainingDays = Math.floor(remainingTime / (1000 * 60 * 60 * 24));

        if (remainingDays > 0) {
            return (
                this.translateService.instant('campaigns.expiresIn') +
                ' ' +
                remainingDays +
                ' ' +
                this.translateService.instant('days')
            );
        } else if (remainingDays === 0) {
            return this.translateService.instant('campaigns.expiresToday');
        } else {
            return this.translateService.instant('campaigns.expired');
        }
    }
}
