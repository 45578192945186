import { Component, OnInit } from '@angular/core';
import { UserPromotionVoucher } from '../../../../models/user-promotion-voucher';
import { UserPromotionVoucherService } from '../../../../shared/services/user-promotion-voucher.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-voucher-error',
    templateUrl: './voucher-error.component.html',
    styleUrls: ['./voucher-error.component.scss'],
})
export class VoucherErrorComponent implements OnInit {
    brandName: string;
    error: any;
    isLoading = false;

    async ngOnInit(): Promise<void> {
        await this.loadVoucherData();
    }

    private async loadVoucherData(): Promise<void> {
        try {
            this.isLoading = true;
            this.brandName = localStorage.getItem('brandName');
        } catch (e) {
            this.error = e;
        } finally {
            this.isLoading = false;
        }
    }

    clearLocalStorage() {
        localStorage.removeItem('brandName');
    }
}
