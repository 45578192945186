import { Promotion } from './promotion';
import { User } from './user';
import { Brand } from './brand';

export interface UserPromotionVoucher {
    _id: string;
    title: string;
    description: string;
    promotion: Promotion;
    user: User;
    qrCode: string;
    isUsed: boolean;
    brand: Brand;
    startDate: Date;
    endDate: Date;
    isExpired?: boolean;
}

export enum UserPromotionVoucherStatus {
    active = 'active',
    expired = 'expired',
    used = 'used',
}
